import React, { useContext, useEffect } from 'react'
import './EditorScreen.css'
import NavigationBar from '../../components/navigation_bar/NavigationBar';
import Editor from '../../components/editor_components/editor/Editor';
import AppContainer from '../../apps/app_container/AppContainer'
import SaveTemplatePopup from '../../components/admin_components/save_template_popup/SaveTemplatePopup';
import CreateDataContext from '../../store/CreateDataProvider';

const EditorScreen = () => {
    const context = useContext(CreateDataContext)

    useEffect(() => {
        context.getUserData()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <React.Fragment>
            <NavigationBar />
            <div className='editor-screen-container'>
                <Editor />
                <AppContainer />
            </div>
            {context.templatePopupActive ? <SaveTemplatePopup /> : null}
        </React.Fragment>
    );
}

export default EditorScreen;