import React, { useContext } from 'react'
import "./RightDashboard.css"
import remove from "../../../assets/right_sidebar_assets/delete.svg"
import edit from "../../../assets/right_sidebar_assets/edit.svg"
import CreateDataContext from '../../../store/CreateDataProvider'
import ButtonContext from '../../../store/ButtonProvider'
import { useNavigate } from 'react-router-dom'
import RightDraftLoading from '../../../card_components/right_draft_loading/RightDraftLoading'


const RightDashboard = () => {
    const context = useContext(CreateDataContext)
    const buttonContext = useContext(ButtonContext)
    const navigate = useNavigate();


    const changeScreens = (leftButton, centerButton, rightButton) => {
        buttonContext.changeLeftButton(leftButton)
        buttonContext.changeCenterButton(centerButton)
        buttonContext.changeRightButton(rightButton)
    }

    const loadingArray = [1, 2, 3, 4, 5, 6]
    return (
        <div className='right-dashboard-container'>
            <div className='top-info'>
                <span>Looking for a little assistance?</span>
                <a href='https://tawk.to/chat/615fde58157d100a41ab5e5d/1fhf7p2ht' target='_blank' rel='noreferrer'>Live chat support</a>
                <a href='https://youtu.be/eS5tpAUEuzA' target='_blank' rel='noreferrer'>Video Tutorial</a>
                <a href='https://www.facebook.com/groups/creatosaurus/' target='_blank' rel='noreferrer'>Join our Facebook group</a>
            </div>

            <div className='saved-container'>
                <div className='header'>
                    <span>Saved</span>
                    <span onClick={() => changeScreens(2, "saved", "right-dashboard")}>See all</span>
                </div>

                <div className={context.draftLoading === false && context.drafts.length === 0 ? null : "saved-scroll-container"}>
                    {context.draftLoading ? loadingArray.map((data, index) => {
                        return <RightDraftLoading key={index} />
                    }) : context.drafts.length > 0 ? context.drafts.map((data, index) => {
                        return <div className="post-card" key={index}>
                            <div className="top-side">
                                <h3>{data.metaData.title.slice(0, 35)}...</h3>
                            </div>

                            <div className="bottom-side">
                                <span>{data.info.createdOn}</span>
                                <div className="options">
                                    <img src={edit} alt="edit icon" onClick={() => {
                                        context.handleSavedPost(data)
                                        context.handlePostTitle(data.metaData.title)
                                        context.handlePostDescription(data.metaData.description)
                                        context.handlePostCoverImage(data.metaData.coverImage)
                                        navigate(`/editor/post/${data._id}`)
                                    }} />
                                    <img src={remove} alt="delete icon" onClick={() => {
                                        context.deleteDraft(data._id)
                                    }} />
                                </div>
                            </div>
                        </div>
                    }) : <div className="not-saved">
                        <span>You haven't saved anything.</span>
                    </div>}

                </div>
            </div>
        </div>
    );
}

export default RightDashboard;
