import React, { useState, useEffect, useRef } from 'react';
import './Uploads.css';
import Axios from 'axios';
import AppHeadingCard from '../app_heading_card/AppHeadingCard';
import Masonry from "react-responsive-masonry";
import ImagesLoader from '../images_loader/ImagesLoader';
import jwtDecode from 'jwt-decode';

const GiphyGifs = ({ changeActiveApp }) => {
    const inputContainer = useRef(null)
    const [images, setImages] = useState([]);
    const [loading, setLoading] = useState(true);
    const [uploadingImageURL, setuploadingImageURL] = useState("")
    const [uploadLoading, setuploadLoading] = useState(false)


    // load initial images
    useEffect(() => {
        getTheImages();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps


    // get initial user upload images
    const getTheImages = async () => {
        try {
            setLoading(true);
            const workspaceId = localStorage.getItem('organizationId');
            const response = await Axios.get(
                'https://vlz6y4dxq7.execute-api.ap-south-1.amazonaws.com/latest/muse/uploads/' +
                workspaceId
            );
            setImages([...images, ...response.data]);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };


    const uploadImage = async (acceptedFiles) => {
        try {
            console.log(acceptedFiles)
            const file = acceptedFiles;
            const decoded = jwtDecode(localStorage.getItem('token'));
            const organizationId = localStorage.getItem('organizationId');

            const objectURL = URL.createObjectURL(file);
            setuploadingImageURL(objectURL);
            setuploadLoading(true)

            const res = await Axios.get("https://vlz6y4dxq7.execute-api.ap-south-1.amazonaws.com/latest/muse/uploads/generate/url");

            // upload the file to s3 bucket
            await Axios.request({
                method: "PUT",
                headers: {
                    "Content-Type": file.type
                },
                url: res.data.uploadURL,
                data: file,
            });

            const imageURL = res.data.uploadURL.split('?')[0];
            const fileNameInS3Bucket = res.data.imageName;
            const data = await Axios.post("https://vlz6y4dxq7.execute-api.ap-south-1.amazonaws.com/latest/muse/uploads", {
                organizationId: organizationId,
                userId: decoded.id,
                url: imageURL,
                fileName: fileNameInS3Bucket,
                type: file.type.substring(0, 5),
                fileSize: file.size,
                mimetype: file.type,
                delete: false
            });

            setImages((prev) => [data.data, ...prev])
            setuploadLoading(false)
        } catch (error) {
            setuploadLoading(false)
        }
    }

    return (
        <div className='rightUploads'>
            <AppHeadingCard title="Uploads" changeActiveApp={changeActiveApp} />

            <input type="file" name="myImage" accept="image/*"
                ref={inputContainer}
                style={{ opacity: 0, height: 0, width: 0 }}
                onChange={(e) => uploadImage(e.target.files[0])} />

            <button className="upload-button" onClick={() => uploadLoading ? null : inputContainer.current.click()}>
                {uploadLoading ? <img src={uploadingImageURL} alt="" /> : null}
                <span>{uploadLoading ? "Uploading ..." : "Upload Media"}</span>
                <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 13.0703L8.5 16.5703M12 22.0703V13.0703V22.0703ZM12 13.0703L15.5 16.5703L12 13.0703Z" stroke="#FFFFFF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M20 17.6776C21.4937 17.0924 23 15.7592 23 13.0703C23 9.07031 19.6667 8.07031 18 8.07031C18 6.07031 18 2.07031 12 2.07031C6 2.07031 6 6.07031 6 8.07031C4.33333 8.07031 1 9.07031 1 13.0703C1 15.7592 2.50628 17.0924 4 17.6776" stroke="#FFFFFF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
            </button>


            <div className='scroll-container-image' style={loading ? { overFlow: 'hidden' } : null} >
                <Masonry columnsCount={2} gutter={10}>
                    {
                        images.map((url, index) => {
                            return <div key={index} className='image-container'>
                                <img
                                    src={`https://d1vxvcbndiq6tb.cloudfront.net/fit-in/300x0/${url.fileName}`}
                                    alt='muse'
                                />
                            </div>
                        })
                    }
                </Masonry>

                {
                    loading ? <ImagesLoader /> : images.length === 0 ? <span style={{
                        display: "flex",
                        justifyContent: "center"
                    }}>You don't have any uploads.</span> : null
                }
            </div>
        </div>
    );
};

export default GiphyGifs;