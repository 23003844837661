import axios from 'axios';
import jwt_decode from "jwt-decode"


class Outline {
    static get toolbox() {
        return {
            title: 'Outline',
            icon: `<svg width="10" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5 1.03001H13M1 1.03751L2.125 1.0293M1 5.53751L2.125 5.53001M2.125 10.03L1.0075 10.0293M5 5.53001H13M5 10.03H13" stroke="#404040" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      </svg>`
        };
    }

    render() {
        this.node = document.createElement('ol');
        this.node.contentEditable = true;
        this.node.classList.add('cdx-block', 'cdx-list', 'cdx-list--ordered');

        const title = document.getElementById('post-title');

        if (!title || title.textContent.trim() === '') {
            this.error = 'Title cannot be empty';
            this.node.textContent = this.error;
            this.node.style.color = 'red';
            return this.node;
        }
        this.isLoading = true;
        const loader = document.createElement('div');
        loader.style.width = 0
        loader.classList.add('cdx-loader');
        this.node.appendChild(loader);

        this.getAIResponse(title.textContent)
            .then((responseText) => {
                const items = responseText.split('\n').filter(Boolean);
                items.forEach((itemText) => {
                    const trimmedText = itemText.trim();
                    if (trimmedText.length > 2 && trimmedText[1] === '.') {
                        const listItem = document.createElement('li');
                        listItem.textContent = trimmedText.slice(2).trim();
                        this.node.appendChild(listItem);
                    }
                });
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                this.isLoading = false;
                this.node.removeChild(loader);
            });



        return this.node;
    }


    async getAIResponse(title) {
        try {
            const token = localStorage.getItem("token");
            const decoded = jwt_decode(token);

            const body = {
                organizationId: localStorage.getItem('organizationId'),
                userId: decoded.id,
                userName: decoded.userName,
                companyName: null,
                companyDescription: title,
                gender: "male",
                copyType: "Blog Outline",
                language: "English"
            };


            const response = await axios.post("https://api.captionator.creatosaurus.io/captionator/blog/outline", body, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            return response.data.data[0].text
        } catch (error) {

        }
    }

    save() {
        if (this.error || this.isLoading) {
            return null; // Return null if there's an error or still loading
        }
        const listItems = Array.from(this.node.querySelectorAll('li'));
        const itemsData = listItems.map((listItem) => ({
            type: 'list-item',
            data: {
                text: listItem.textContent,
            },
        }));

        return {
            type: 'list',
            data: {
                style: 'ordered',
                items: itemsData,
            },
        };
    }

}


export default Outline