import React, { useEffect, useContext } from 'react'
import './Home.css'
import NavigationBar from '../../components/navigation_bar/NavigationBar';
import LeftSidebar from '../../components/left_components/left_sidebar/LeftSidebar';
import CreateDataContext from '../../store/CreateDataProvider';
import Center from '../../components/center_components/center/Center';
import Right from '../../components/right_components/right/Right';



const Home = () => {
    const context = useContext(CreateDataContext)

    useEffect(() => {
        context.getUserData()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps
    return (
        <React.Fragment>
            <NavigationBar />
            <main>
                <LeftSidebar />
                <Center />
                <Right />
            </main>
        </React.Fragment>
    );
}

export default Home;