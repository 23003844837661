import React, { useState, useContext, useEffect } from 'react';
import './OnPageSeo.css';
import AppHeadingCard from '../app_heading_card/AppHeadingCard';
import axios from 'axios';
import HashtagsLoaders from '../hashtags_loader/HashtagsLoaders'
import CreateDataContext from '../../store/CreateDataProvider';
import SemiCircleProgressBar from "react-progressbar-semicircle";
import upRed from '../../assets/seo_assets/upRedArrow.svg'
import upOrange from '../../assets/seo_assets/upOrangeArrow.svg'
import upYellow from '../../assets/seo_assets/upYellowArrow.svg'
import greenTick from '../../assets/seo_assets/greenTick.svg'
import { toast } from 'react-toastify';


const OnPageSeo = ({ changeActiveApp }) => {
    const context = useContext(CreateDataContext)
    const [loading, setLoading] = useState(false)
    const [keyword, setKeyword] = useState('')
    const [seoKeywords, setSeoKeywords] = useState([])
    const [allKeywords, setAllKeywords] = useState([])
    // const [activeTab, setActiveTab] = useState('keywords')
    const [activeSubTab, setActiveSubTab] = useState('seo-check-list')
    const [country, setCountry] = useState({ country: "All Global Locations", code: "GB", flag: "🌏" })
    const [isCountryDropdownActive, setIsCountryDropdownActive] = useState(false)
    const [seoStats, setSeoStats] = useState([])
    const [competitorStats, setCompetitorStats] = useState([
        {
            icon: upYellow,
            title: 'Words',
            stats: 17,
            count: '2199-2345'
        },
        {
            icon: greenTick,
            title: 'Headings',
            stats: 10,
            count: '20-21'
        },
        {
            icon: upRed,
            title: 'Paragraph',
            stats: 20,
            count: '30-45'
        },
        {
            icon: upYellow,
            title: 'Images',
            stats: 5,
            count: '15-20'
        },
    ])
    const [seoCheckList, setSeoCheckList] = useState([])
    const [keywordsToCopy, setKeywordsToCopy] = useState([])
    const [sortBy, setSortBy] = useState({
        order: 'relevance',
        type: 'all'
    })
    const serperApiKey = '98b7acfe5f00514594499dce5b6bed6d9b2ae334'
    const OPENAI_API_KEY = 'sk-wyUXdlbTKvjB7HhwTc0rU4hHsiLO1Wx454X3Lpei';
    const dataForSeoKey = 'bWFsYXZAY3JlYXRvc2F1cnVzLmlvOmY4MTQ1NTk4ODljMWEyOTQ='

    useEffect(() => {
        checkBlogSeo()
    }, [])

    const extractSiteLinks = (searchResults) => {
        let siteLinks = [];
        searchResults.forEach(result => {
            if (result.sitelinks) {
                result.sitelinks.forEach(sitelink => {
                    siteLinks.push(sitelink.link);
                });
            }
        });
        return siteLinks;
    }

    const extractKeywords = (text, type) => {
        const data = text.split("\n");
        const keywords = [];
        const numberPattern = /^\d+\./;

        data.forEach(line => {
            const keyword = line.split('.')[1]?.trim().toLowerCase().replace(/"/g, '');
            if (numberPattern.test(line) && keyword) {
                keywords.push({
                    type: type,
                    keyword: keyword
                });
            }
        });

        return keywords;
    };


    const fetchAllData = async (dataForSeoLinksPromises) => {
        try {
            const results = await Promise.all(dataForSeoLinksPromises);
            let allHTags = [];
            results.forEach(result => {
                if (result && result.tasks) {
                    result.tasks.forEach(task => {
                        const items = task.result[0].items;
                        if (items) {
                            items.forEach(item => {
                                if (item.meta) {
                                    const htagsMeta = item.meta.htags || {};
                                    ['h1', 'h2', 'h3', 'h4'].forEach(tag => {
                                        if (htagsMeta[tag]) {
                                            allHTags = allHTags.concat(htagsMeta[tag]);
                                        }
                                    });
                                }
                            });
                        }
                    });
                }
            });

            const allHTagsString = allHTags.join(', ');
            return allHTagsString;
        } catch (error) {
            console.error("Error processing data:", error);
            return null;
        }
    };

    const ranges = [
        { min: 1, max: 5, label: "1 to 5" },
        { min: 6, max: 10, label: "6 to 10" },
        { min: 11, max: 20, label: "11 to 20" },
        { min: 21, max: 50, label: "21 to 50" },
        { min: 51, max: 100, label: "51 to 100" },
        { min: 101, max: 200, label: "101 to 200" },
    ];

    const getRangeLabel = (count, ranges) => {
        for (let range of ranges) {
            if (count >= range.min && count <= range.max) {
                return range.label;
            }
        }
        return count > ranges[ranges.length - 1].max ? `>${ranges[ranges.length - 1].max}` : "0";
    }

    const searchKeyword = async () => {
        try {
            if (context.userData.workspaceOwnerFeatureFactoryData.planId.planName !== "agency") return toast.info('Upgrade your plans to use on page seo.')
            setLoading(true)
            let data = JSON.stringify({
                "q": keyword
            });
            const serperRes = await axios.post('https://google.serper.dev/search', data, {
                headers: {
                    'X-API-KEY': serperApiKey,
                    'Content-Type': 'application/json'
                },
            })

            const links = extractSiteLinks(serperRes.data.organic)

            const dataForSeoConfig = {
                headers: {
                    'Authorization': `Basic ${dataForSeoKey}`,
                    'Content-Type': 'application/json'
                }
            };


            const dataForSeoLinksPromises = links.map(async (link) => {
                const data = [
                    {
                        url: link,
                        check_spell: false,
                        disable_cookie_popup: false,
                        return_despite_timeout: false,
                        load_resources: false,
                        enable_javascript: false,
                        enable_browser_rendering: false
                    }
                ];
                try {
                    const response = await axios.post('https://api.dataforseo.com/v3/on_page/instant_pages', data, dataForSeoConfig)
                    return response.data;
                } catch (error) {
                    console.error("Error fetching data:", error);
                    return null;
                }
            })
            let allText = ''
            const urlInfo = await fetchAllData(dataForSeoLinksPromises)
            allText = urlInfo
            const linkPromises = links.map(async link => {
                let data = JSON.stringify({
                    "url": link
                });
                try {
                    const response = await axios.post('https://scrape.serper.dev', data, {
                        headers: {
                            'X-API-KEY': serperApiKey,
                            'Content-Type': 'application/json'
                        },
                    });

                    return {
                        text: response.data.text
                    };
                } catch (error) {
                    console.error("Error fetching data:", error);
                    return null;
                }
            });

            const linksInfo = await Promise.all(linkPromises);

            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${OPENAI_API_KEY}`
                }
            };

            const body = {
                model: 'gpt-3.5-turbo',
                messages: [{
                    role: 'system', content: 'Your a seo keyword extractor you will extract keyword from the given text'
                }, {
                    role: 'user', content: `Extract seo keywords related to ${keyword} from the following table of content ${urlInfo}`
                }]
            };

            const competitorKeywordsResponse = await axios.post('https://api.openai.com/v1/chat/completions', body, config);
            const commonKeywordsData = extractKeywords(competitorKeywordsResponse.data.choices[0].message.content, "Keyword")

            let keywords = []
            const keywordsPromise = linksInfo.map(async (data) => {
                allText += data.text
                const body = {
                    model: 'gpt-3.5-turbo',
                    messages: [{
                        role: 'system', content: 'You are a seo keyword extractor you will extract keyword from the given text'
                    }, {
                        role: 'user', content: `Extract seo keywords related to "${keyword}" from the following blog ${data.text} and keep duplicates`
                    }]
                };

                const response = await axios.post('https://api.openai.com/v1/chat/completions', body, config);
                const keywordsData = extractKeywords(response.data.choices[0].message.content, "Term")
                keywords = [...keywords, ...keywordsData]
            })

            await Promise.all(keywordsPromise);
            const keywordForRange = [...keywords, ...commonKeywordsData]
            const keywordsWithRanges = keywordForRange.map(keyword => {
                let count = 0;
                const regex = new RegExp(`\\b${keyword.keyword.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')}\\b`, 'gi');

                linksInfo.forEach(obj => {
                    if (obj.text.match(regex)) {
                        count += (obj.text.match(regex) || []).length;
                    }
                });

                return {
                    keyword: keyword,
                    count: count,
                    range: getRangeLabel(count, ranges)
                };
            });
            const editorText = extractTextFromEditorData(context.savedPost.blocks)
            const formattedKeywords = countKeywords(allText, keywordsWithRanges, editorText)
            setAllKeywords(formattedKeywords)
            setSeoKeywords(formattedKeywords)
            setLoading(false)
        } catch (error) {
            setLoading(false)
            console.log(error)
        }
    }

    const countKeywords = (text, keywords, editorText) => {
        let scrappedText = text.toLocaleLowerCase()
        let editorScrappedText = editorText.toLocaleLowerCase()
        const formattedData = keywords.map(data => {
            const regex = new RegExp(data.keyword.keyword, 'g');
            const count = (scrappedText.match(regex) || []).length;
            const editorCount = (editorScrappedText.match(regex) || []).length;
            return {
                ...data,
                count: count,
                editorCount: editorCount
            };
        });
        return formattedData
    }


    const extractTextFromEditorData = (editorData) => {
        return editorData.map(block => {
            switch (block.type) {
                case 'paragraph':
                    return block.data.text;
                case 'header':
                    return block.data.text;
                case 'list':
                    return block.data.items.join(' ');
                case 'table':
                    return block.data.content.flat().join(' ');
                default:
                    return '';
            }
        }).join(' ');
    }

    const calculatePercentage = (data) => {
        const percentage = (data.count > 0) ? (data.editorCount / data.count) * 100 : 0;
        return isNaN(percentage) ? 0 : percentage;
    };

    const getIndicatiorImage = (data) => {
        const percentage = calculatePercentage(data);
        let indicator;
        if (percentage < 30) {
            indicator = upRed
        } else if (percentage >= 30 && percentage < 70) {
            indicator = upYellow
        } else if (percentage > 70) {
            indicator = greenTick
        }

        return indicator;
    }

    const handleChecklist = (value) => {
        const modifiedChecklist = seoCheckList.map((data) => {
            let obj = {
                ...data,
                isOpen: data.title === value ? !data.isOpen : data.isOpen
            }
            return obj
        })
        setSeoCheckList(modifiedChecklist)
    }

    const addKeywordsToCopy = (value) => {
        if (keywordsToCopy.includes(value)) {
            const removedKeywords = keywordsToCopy.filter((data) => data !== value)
            setKeywordsToCopy(removedKeywords)
        } else {
            setKeywordsToCopy([...keywordsToCopy, value])
        }
    }

    const copyKeywords = () => {
        navigator.clipboard.writeText(keywordsToCopy.join(','))
        toast.success('Keywords copied to clipboard!')
    }

    const sortKeywordsBySearchVolume = (order, type) => {
        let filteredKeywords = seoKeywords;

        // Filter by type if the type is not "all"
        if (type !== "all") {
            filteredKeywords = seoKeywords.filter(keyword => keyword.keyword.type === type);
        }

        // Sort by order
        let sortedKeywords;
        if (order === "relevance") {
            sortedKeywords = [...filteredKeywords];
        } else {
            sortedKeywords = filteredKeywords.slice().sort((a, b) => {
                if (order === 'low to high') {
                    return a.count - b.count;
                } else if (order === 'high to low') {
                    return b.count - a.count;
                } else {
                    throw new Error('Invalid sort order specified. Use "low to high", "high to low", or "relevance".');
                }
            });
        }

        setAllKeywords(sortedKeywords);
    };

    const checkBlogSeo = () => {
        let seoCriticals = [];
        let seoPassed = [];
        const blocks = context.savedPost.blocks;
        console.log(context.savedPost)
        const h1Data = blocks.filter(block => block.type === "header" && block.data.level === 1);
        const h2Data = blocks.filter(block => block.type === "header" && block.data.level === 2);
        const h3Data = blocks.filter(block => block.type === "header" && block.data.level === 3);
        const imageData = blocks.filter(block => block.type === "image" && block.data.caption === "");

        // NOTE: Header
        if (h2Data.length > 0) {
            seoPassed.push({
                type: 'passed',
                info: 'H2 tags exist on the page',
                icon: greenTick
            });

            if (h2Data.length >= 3) {
                seoPassed.push({
                    type: 'passed',
                    info: 'At least three H2 tags are present',
                    icon: greenTick
                });
            }
        }

        if (h3Data.length > 0) {
            seoPassed.push({
                type: 'passed',
                info: 'H3 tags exist on the page',
                icon: greenTick
            });

            if (h3Data.length >= 3) {
                seoPassed.push({
                    type: 'passed',
                    info: 'At least three H3 tags are present',
                    icon: greenTick
                });
            }
        }

        if (h1Data.length > 0) {
            seoPassed.push({
                type: 'passed',
                info: 'H1 tags exist on the page',
                icon: greenTick
            });

            if (h1Data.length >= 3) {
                seoPassed.push({
                    type: 'passed',
                    info: 'At least three H1 tags are present',
                    icon: greenTick
                });
            }
            const rangeOfChar = h1Data.filter((data) => data.data.text.length < 40 || data.data.text.length > 60)

            if (rangeOfChar.length === 0) {
                seoPassed.push({
                    type: 'passed',
                    info: 'H1 length is ideal',
                    icon: greenTick
                });
            } else {
                seoCriticals.push({
                    type: 'critical',
                    info: 'H1 length is not ideal; aim for 40-60 characters',
                    icon: upRed
                });
            }
        }

        if (h2Data.length === 0) {
            seoCriticals.push({
                type: 'critical',
                info: 'No H2 tags exist on the page',
                icon: upRed
            });
        }

        if (h3Data.length === 0) {
            seoCriticals.push({
                type: 'critical',
                info: 'No H3 tags exist on the page',
                icon: upRed
            });
        }

        if (h1Data.length === 0) {
            seoCriticals.push({
                type: 'critical',
                info: 'No H1 tags exist on the page',
                icon: upRed
            });
        }

        // NOTE: Image
        if (imageData.length === 0) {
            seoPassed.push({
                type: 'passed',
                info: 'All images on the page have `alt` attribute',
                icon: greenTick
            });
        } else {
            seoCriticals.push({
                type: 'critical',
                info: 'All images on the page donot have `alt` attribute',
                icon: upRed
            });
        }

        // NOTE: Blog Title

        if (context.savedPost.metaData.title === "") {
            seoCriticals.push({
                type: 'critical',
                info: 'Add meta title to the page',
                icon: upRed
            });
        } else {
            if (context.savedPost.metaData.title.length < 40 || context.savedPost.metaData.title.length > 60) {
                seoCriticals.push({
                    type: 'critical',
                    info: 'Adjust page meta title in 40 to 60 characters range',
                    icon: upRed
                });
            } else {
                seoPassed.push({
                    type: 'passed',
                    info: 'Page meta title is in 40 to 60 characters range',
                    icon: greenTick
                });
            }
        }

        if (context.savedPost.metaData.description === "") {
            seoCriticals.push({
                type: 'critical',
                info: 'There is no meta description, please add one',
                icon: upRed
            });
        } else {
            if (context.savedPost.metaData.description.length < 120 || context.savedPost.metaData.description.length > 160) {
                seoCriticals.push({
                    type: 'critical',
                    info: 'Meta description length is outside 120-160 characters range, please adjust',
                    icon: upRed
                });
            } else {
                seoPassed.push({
                    type: 'passed',
                    info: 'Meta description length is in 120-160 characters range',
                    icon: greenTick
                });
            }
        }

        let seoChecks = [
            {
                title: 'Passed',
                stats: seoPassed.length,
                isOpen: false,
                data: seoPassed
            },
            {
                title: 'Critical',
                stats: seoCriticals.length,
                isOpen: false,
                data: seoCriticals
            }
        ]

        setSeoStats([
            {
                icon: upRed,
                title: 'Critical',
                stats: seoCriticals.length
            },
            {
                icon: greenTick,
                title: 'Passed',
                stats: seoPassed.length
            },
        ])

        setSeoCheckList(seoChecks)
    };


    return (
        <div className="onpage-container">
            <AppHeadingCard title="On Page Seo" changeActiveApp={changeActiveApp} />
            <div className="onpage-app-container">
                {/* <div className="tabs-container">
                    <button onClick={() => {
                        setActiveTab('keywords')
                    }} className={activeTab === 'keywords' ? 'active' : null}>Keyword</button>
                    
                    <button className={activeTab === 'url' ? 'active' : null} onClick={() => {
                        setActiveTab('url')
                    }}>URL</button>
                </div> */}


                <div className="input-container">
                    <div className="drop-down" onClick={() => {
                        setIsCountryDropdownActive(!isCountryDropdownActive)
                    }}>
                        <span>{country.flag}</span>
                        <span>{country.code}</span>
                        {isCountryDropdownActive ? <svg width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7 4L4 1L1 4" stroke="black" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round" />
                        </svg> : <svg xmlns="http://www.w3.org/2000/svg" width="8" height="5" viewBox="0 0 8 5" fill="none">
                            <path d="M7 1L4 4L1 1" stroke="black" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        }
                    </div>
                    <input type="search" placeholder='Search keyword' onChange={(e) => {
                        setKeyword(e.target.value)
                    }} value={keyword} onKeyDown={(e) => {
                        if (e.key === "Enter") {
                            searchKeyword()
                        }
                    }} />
                </div>

                <div className="tabs-container">
                    <button onClick={() => {
                        setActiveSubTab('seo-check-list')
                    }} className={activeSubTab === 'seo-check-list' ? 'active' : null}>On Page SEO Checklist</button>
                    <button className={activeSubTab === 'Competitor' ? 'active' : null} onClick={() => {
                        setActiveSubTab('Competitor')
                    }}>Competitor</button>
                </div>

                {/* {activeSubTab === "seo-check-list" ? <div className="progress-container">
                    <SemiCircleProgressBar percentage={34} stroke="#FFD75F" strokeWidth="5" background="#F8F8F9" diameter="150" />
                    <div className="percentage-container">
                        <h1>34<span>/100</span></h1>
                        <h2>{activeSubTab === "seo-check-list" ? "Checklist Score" : "Competitor Score"}</h2>
                        <span>Suggested 65+</span>
                    </div>
                </div> : null} */}

                {/* {activeSubTab === "seo-check-list" ? <div className="border-bottom" /> : null} */}

                <div className="stats-container">
                    {activeSubTab === "seo-check-list" ? seoStats.map((data, index) => {
                        return (
                            <div className="stats" key={index}>
                                <img src={data.icon} alt="stats" />
                                <h1>{data.title}</h1>
                                <h2>{data.stats}</h2>
                            </div>
                        )
                    }) : competitorStats.map((data, index) => {
                        return (
                            <div className="stats" key={index}>
                                <img src={data.icon} alt="stats" />
                                <h1>{data.title}</h1>
                                <h2>{data.stats}</h2>
                                <span>{data.count}</span>
                            </div>
                        )
                    })}
                </div>

                <div className="border-bottom" />
                {activeSubTab === "seo-check-list" ? <div className="seo-checklist-container">
                    {seoCheckList.map((data, index) => {
                        return (
                            <div className="check-list" key={index}>
                                <div className="heading" onClick={() => {
                                    handleChecklist(data.title)
                                }}>
                                    <h1>{data.title} - {data.stats}</h1>
                                    {data.isOpen ? <svg width="10" height="7" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7 4L4 1L1 4" stroke="#808080" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                        : <svg xmlns="http://www.w3.org/2000/svg" width="10" height="7" viewBox="0 0 10 7" fill="none">
                                            <path d="M9 1.5L5 5.5L1 1.5" stroke="#808080" strokeWidth="1.75" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>}
                                </div>
                                {data.isOpen ? <div className="check-list-item-container">
                                    {data.data.map((item, i) => {
                                        return (
                                            <div className="item-container" key={i} style={item.type === "critical" ? { background: "rgba(255, 67, 89, 0.05)" } : item.type === "warning" ? { background: "rgba(255, 138, 36, 0.42)" } : item.type === "suggested" ? { background: "rgba(255, 215, 97, 0.5)" } : { background: "rgba(158, 255, 232, 1)" }}>
                                                <img src={item.icon} alt="status" />
                                                <p>{item.info}</p>
                                            </div>
                                        )
                                    })}
                                </div> : null}
                            </div>
                        )
                    })}
                </div> : <div className="competitor-container">
                    <div className="dropdown-container">
                        <select value={sortBy.type} onChange={(e) => {
                            setSortBy({
                                ...sortBy,
                                type: e.target.value
                            })
                            sortKeywordsBySearchVolume(sortBy.order, e.target.value)
                        }}>
                            <option value="all">Type - All</option>
                            <option value="Keyword">Type - Keyword</option>
                            <option value="Term">Type - Term</option>
                        </select>
                        <select value={sortBy.order} onChange={(e) => {
                            setSortBy({
                                ...sortBy,
                                order: e.target.value
                            })
                            sortKeywordsBySearchVolume(e.target.value, sortBy.type)
                        }}>
                            <option value="relevance">Sort by - Relevance</option>
                            <option value="high to low">Sort by - High To Low</option>
                            <option value="low to high">Sort by - Low To High</option>
                        </select>
                    </div>
                    <div className="keywords-container">
                        {loading ? <HashtagsLoaders /> : allKeywords.map((data, index) => {
                            return (
                                <div className="keyword" key={index}>
                                    <div className="left-side">
                                        <div className="check-box-container">
                                            <input type="checkbox" onChange={() => {
                                                addKeywordsToCopy(data.keyword.keyword)
                                            }} checked={keywordsToCopy.includes(data.keyword.keyword)} />
                                        </div>
                                        <div className="info-container">
                                            <span>{data.keyword.keyword}</span>
                                            <div className="bottom-container">
                                                <span>Type - {data.keyword.type}</span>
                                                <span>Count - {data.range}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="right-side">
                                        <img src={getIndicatiorImage(data)} alt="indicator" />
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>}

                {keywordsToCopy.length > 0 ? <div className="copy-container">
                    <div className="left-side">
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                            <path d="M2.8125 7.5625L5.0625 9.8125L10.6875 4.1875" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        <span>{keywordsToCopy.length} Keywords Selected</span>
                    </div>
                    <div className="right-side" onClick={() => {
                        copyKeywords()
                    }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                            <path d="M11.3167 11.6667H5.6C5.4067 11.6667 5.25 11.51 5.25 11.3167V5.6C5.25 5.4067 5.4067 5.25 5.6 5.25H11.3167C11.51 5.25 11.6667 5.4067 11.6667 5.6V11.3167C11.6667 11.51 11.51 11.6667 11.3167 11.6667Z" stroke="#0078FF" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M8.74992 5.25001V2.68334C8.74992 2.49004 8.59323 2.33334 8.39992 2.33334H2.68325C2.48995 2.33334 2.33325 2.49004 2.33325 2.68334V8.40001C2.33325 8.59333 2.48995 8.75001 2.68325 8.75001H5.24992" stroke="#0078FF" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        <span>Copy All</span>
                    </div>
                </div> : null}
            </div>
        </div >
    );
};

export default OnPageSeo;
