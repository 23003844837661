import React, { useEffect, useState } from 'react';
import './QrCode.css';
import QRCode from 'qrcode';
import SVG from 'react-inlinesvg';
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import AppHeadingCard from '../app_heading_card/AppHeadingCard';
import { toast } from "react-toastify";

const QrCode = ({ changeActiveApp }) => {
    const [images, setimages] = useState('');
    const [text, setText] = useState('');
    const [name, setName] = useState('');
    const [color, setcolor] = useState('#43da86');
    const [selectdTab, setselectdTab] = useState('Saved');
    const [QRCodeData, setQRCodeData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [deleteIndex, setDeleteIndex] = useState(null);
    const loadingArray = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

    // generate qrcode function
    const generateQR = (qrcodeText) => {
        try {
            var opts = {
                type: 'svg',
                color: {
                    dark: color,
                },
            };
            if (qrcodeText !== "" && qrcodeText !== null) {
                QRCode.toString(qrcodeText, opts, function (err, string) {
                    if (err) throw err;

                    setimages(string);
                });
            } else {
                alert("enter QRcode content");
            }
        } catch (err) { }
    };

    // save the generated qrcode
    const saveQRCode = async () => {
        setLoading(true);
        const jwtToken = localStorage.getItem('token');

        const decoded = jwt_decode(jwtToken);

        const res = await axios.post('https://6cgnepgh03.execute-api.ap-south-1.amazonaws.com/latest/qrcode/createQrcode', {
            "userId": decoded.id,
            "title": text,
            "qrcodeContent": name,
            "color": color,
            "qrcode": images

        });
        if (res.status === 201) {
            toast.success("Qrcode saved successfully");
            setLoading(false);
            setselectdTab("Saved");
            getAllSaveQRCode();
        }
    };


    // get all saved qrcode
    const getAllSaveQRCode = async () => {
        setLoading(true);
        const jwtToken = localStorage.getItem('token');

        const decoded = jwt_decode(jwtToken);

        const res = await axios.post('https://6cgnepgh03.execute-api.ap-south-1.amazonaws.com/latest/qrcode/getAllQrcode', {
            "userId": decoded.id,
        });
        if (res.status === 200) {
            setQRCodeData(res.data.data);
            setLoading(false);
        }
    };

    // delete qrcode
    const deleteQRCode = async (id) => {
        setDeleteLoading(true);
        const res = await axios.delete('https://6cgnepgh03.execute-api.ap-south-1.amazonaws.com/latest/qrcode/deleteQRcode', {
            data: { "id": id }, headers: { "Authorization": "***" }
        });
        if (res.data.message === "deleted") {
            toast.success("QR Code deleted successfully");
            setDeleteLoading(false);
            getAllSaveQRCode();
        }
    };

    useEffect(() => {
        getAllSaveQRCode();
    }, []);


    useEffect(() => { }, [images]);

    return (
        <div className="rightQrcode">
            <AppHeadingCard title="QR Code" changeActiveApp={changeActiveApp} />
            <div className='btn'>
                <span style={selectdTab === 'Saved' ? { borderBottomStyle: 'solid', borderBottomWidth: 1.5, borderBottomColor: '#0078FF' } : { color: '#C4C4C4' }} onClick={() => {
                    setselectdTab("Saved");
                }}>Saved</span>
                <span style={selectdTab === 'Generate' ? { borderBottomStyle: 'solid', borderBottomWidth: 1.5, borderBottomColor: '#0078FF' } : { color: '#C4C4C4' }} onClick={() => {
                    setselectdTab("Generate");
                    setName(''); setText('');
                    setcolor('#000000'); setimages("");
                }}>Generate</span>
            </div>
            {selectdTab === "Generate" ? (
                <div className='input-container'>
                    <div className="title-container">
                        <input onChange={(e) => { setText(e.target.value); }} value={text} placeholder="Enter title" />
                        <span >{text.length <= 20 ? parseInt(20) - parseInt(text.length) : 0}</span>

                    </div>
                    <div className="qr-code-content-input-container">
                        <textarea onChange={(e) => { setName(e.target.value); }} value={name} placeholder="Enter QR Code content like, text or code..." ></textarea>
                        <span>{name.length <= 50 ? parseInt(50) - parseInt(name.length) : 0}</span>

                    </div>
                    <br />
                    <div className="color-picker-container">
                        <input
                            type="color"
                            id="color"
                            onChange={(e) => setcolor(e.target.value)}
                            value={color}
                        />
                        <span >Select color</span>
                    </div>
                    <div style={images !== '' ? { background: '#0078FF', color: '#ffffff' } : { background: 'rgba(0, 120, 255, 0.05)', color: '#0078FF' }} className="generate-qr-code-button" onClick={() => { images !== '' ? saveQRCode() : generateQR(text); }}>
                        {images !== '' ? loading ? "Processing" : "Save" : "Generate"}
                    </div>
                    {images !== '' ?
                        <div className='qr-code-display-container' >
                            {images !== '' ? <SVG className='qrcode-svg' src={images} /> : <div></div>}
                        </div> : null
                    }
                </div>
            ) :
                QRCodeData.length ?
                    <>
                        {QRCodeData.map((data, index) => {
                            return (
                                <div className='saved-qrcode-list' key={index}>
                                    <div className='qrcode-details-container' >
                                        <span>{data.title}</span>
                                        <button onClick={() => {
                                            deleteQRCode(data._id);
                                            setDeleteIndex(index);
                                        }} >{deleteLoading && deleteIndex === index ? "Processing" : "Delete"}</button>
                                    </div>
                                    <div className='qrcode-image-container' >
                                        {data.qrcode !== '' ? <SVG className='qrcode-svg' src={data.qrcode} /> : <div></div>}
                                    </div>
                                </div>
                            );
                        })

                        }
                    </>
                    : loading ? loadingArray.map((data) => {
                        return <div className='loader-container' key={data}></div>;
                    }) : <span style={{ marginTop: 14 }} >
                        There are no saved QR Code. Please <span style={{ color: 'blue', textDecorationLine: 'underline', cursor: 'pointer' }} onClick={() => setselectdTab("Generate")} > click here </span> to create a new QR Code
                    </span>

            }
        </div>
    );
};

export default QrCode;
