import React, { useState, useEffect } from 'react';
import './Giphy.css';
import Axios from 'axios';
import AppHeadingCard from '../app_heading_card/AppHeadingCard';
import constant from '../../constant';
import Masonry from "react-responsive-masonry";
import ImagesLoader from '../images_loader/ImagesLoader';

const Giphy = ({ changeActiveApp }) => {
    const [gifs, setGifs] = useState([]);
    const [stickers, setStickers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [offset, setOffset] = useState(0);
    const [search, setSearch] = useState("");
    const [dataFinished, setDataFinished] = useState(false);
    const [active, setActive] = useState("gifs");



    // load initial images
    useEffect(() => {
        getGifs(true);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    // search gifs function
    const getSearchGifs = async (loadMore, searchData) => {
        try {
            if (!loadMore) {
                setGifs([]);
                setDataFinished(false);
                setOffset(0);
            }

            setLoading(true);

            if (constant.searchToken) {
                constant.searchToken.cancel(); // Cancel the previous request before making a new request
            }

            // Create a new CancelToken
            constant.searchToken = Axios.CancelToken.source();
            let query = loadMore === false ? searchData : search;

            if (query === "") {
                setGifs([]);
                setOffset(0);
                setDataFinished(false);
                return getGifs(true);
            }

            const response = await Axios.get(
                `https://api.giphy.com/v1/gifs/search?api_key=faWjrJKmNvqn27ZfC88NZVfV7947rcBc&q=${query}&limit=20&offset=${offset}`, {
                cancelToken: constant.searchToken.token
            }
            );


            // if results is less then 20 setting data finished to true to stop the execution of function 

            if (response.data.data.length < 20) {
                setDataFinished(true);
            }

            if (loadMore === false) {
                setGifs(response.data.data);
                setLoading(false);
            } else {
                setGifs([...gifs, ...response.data.data]);
                setOffset((prev) => prev + 20);
                setLoading(false);
            }

        } catch (error) {
            setLoading(false);
        }
    };

    const getSearchStickers = async (loadMore, searchData) => {
        try {
            if (!loadMore) {
                setStickers([]);
                setDataFinished(false);
                setOffset(0);
            }

            setLoading(true);

            if (constant.searchToken) {
                constant.searchToken.cancel(); // Cancel the previous request before making a new request
            }

            // Create a new CancelToken
            constant.searchToken = Axios.CancelToken.source();
            let query = loadMore === false ? searchData : search;

            if (query === "") {
                setGifs([]);
                setOffset(0);
                setDataFinished(false);
                return getStickers(true);
            }

            const response = await Axios.get(
                `https://api.giphy.com/v1/stickers/search?api_key=faWjrJKmNvqn27ZfC88NZVfV7947rcBc&q=${query}&limit=20&offset=${offset}`, {
                cancelToken: constant.searchToken.token
            }
            );


            // if results is less then 20 setting data finished to true to stop the execution of function 

            if (response.data.data.length < 20) {
                setDataFinished(true);
            }

            if (loadMore === false) {
                setStickers(response.data.data);
                setLoading(false);
            } else {
                setStickers([...stickers, ...response.data.data]);
                setOffset((prev) => prev + 20);
                setLoading(false);
            }

        } catch (error) {
            setLoading(false);
        }
    };



    // get initial gifs 
    const getGifs = async (initial) => {
        try {
            let page = initial ? 0 : offset;
            setLoading(true);
            const response = await Axios.get(
                `https://api.giphy.com/v1/gifs/trending?api_key=faWjrJKmNvqn27ZfC88NZVfV7947rcBc&limit=20&offset=${page}`
            );
            if (initial) {
                setGifs(response.data.data);
                setLoading(false);
            } else {
                setGifs([...gifs, ...response.data.data]);
                setLoading(false);
                setOffset((prev) => prev + 20);
            }


        } catch (error) {
            setLoading(false);
        }
    };


    // get the initial stickers
    const getStickers = async (initial) => {
        let page = initial ? 0 : offset;
        setLoading(true);
        const response = await Axios.get(
            `https://api.giphy.com/v1/stickers/trending?api_key=faWjrJKmNvqn27ZfC88NZVfV7947rcBc&limit=20&offset=${page}`
        );
        if (initial) {
            setStickers(response.data.data);
            setLoading(false);
        } else {
            setStickers([...stickers, ...response.data.data]);
            setOffset((prev) => prev + 20);
            setLoading(false);
        }
    };


    const scrollStarted = (e) => {
        if (e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight - 50) {
            if (loading || dataFinished) return;
            if (search !== "") {
                if (active === "gifs") {
                    getSearchGifs(true);
                } else {
                    getSearchStickers(true);
                }
            } else {
                if (active === "gifs") {
                    getGifs();
                } else {
                    getStickers();
                }
            }
        }
    };


    return (
        <div className='rightGiphy'>
            <AppHeadingCard title="Giphy" changeActiveApp={changeActiveApp} />
            <div className='input-container'>
                <input type="text"
                    value={search}
                    onChange={(e) => {
                        setSearch(e.target.value);
                        if (active === "gifs") {
                            getSearchGifs(false, e.target.value);
                        } else {
                            getSearchStickers(false, e.target.value);
                        }
                    }}
                    placeholder={active === "gifs" ? "Search Gifs from Giphy" : "Search Stickers from Giphy"} />
            </div>
            <div className='btn'>
                <span style={active === 'gifs' ? { borderBottomStyle: 'solid', borderBottomWidth: 1.5, borderBottomColor: '#0078FF' } : { color: '#C4C4C4' }} onClick={() => {
                    setActive("gifs");
                    setOffset(0);
                    getGifs(true);
                    setSearch("");
                }}>Gifs</span>
                <span style={active === 'stickers' ? { borderBottomStyle: 'solid', borderBottomWidth: 1.5, borderBottomColor: '#0078FF' } : { color: '#C4C4C4' }} onClick={() => {
                    setActive("stickers");
                    setOffset(0);
                    getStickers(true);
                    setSearch("");
                }}>Stickers</span>
            </div>
            <div className='scroll-container-image' style={loading ? { overFlow: 'hidden' } : null} onScroll={scrollStarted}>
                {active === "gifs" ?
                    <Masonry columnsCount={2} gutter={10}>
                        {
                            gifs.map((url, index) => {
                                return <div key={index} className='image-container'>
                                    <img
                                        src={url.images.preview_webp?.url}
                                        alt='muse'
                                    />
                                </div>;
                            })
                        }
                    </Masonry>
                    : <Masonry columnsCount={2} gutter={10}>
                        {
                            stickers.map((url, index) => {
                                return <div key={index} className='image-container'>
                                    <img
                                        src={url.images.preview_webp.url}
                                        alt='muse'
                                    />
                                </div>;
                            })
                        }
                    </Masonry>
                }

                {
                    loading ? <ImagesLoader /> : null
                }
            </div>
        </div>
    );
};

export default Giphy;