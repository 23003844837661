import React, { useState, useEffect } from 'react';
import './Tenor.css';
import Axios from 'axios';
import AppHeadingCard from '../app_heading_card/AppHeadingCard';
import constant from '../../constant';
import Masonry from "react-responsive-masonry";
import ImagesLoader from '../images_loader/ImagesLoader';


const Tenor = ({ changeActiveApp }) => {
    const [images, setImages] = useState([]);
    const [loading, setLoading] = useState(true);
    const [offset, setOffset] = useState(0);
    const [search, setSearch] = useState("");
    const [dataFinished, setDataFinished] = useState(false);



    // load initial images
    useEffect(() => {
        getTheImages();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    // search images function
    const getSearchPhotos = async (loadMore, searchData) => {
        try {
            if (!loadMore) {
                setImages([]);
                setDataFinished(false);
                setOffset(0);
            }

            setLoading(true);

            if (constant.searchToken) {
                constant.searchToken.cancel(); // Cancel the previous request before making a new request
            }

            // Create a new CancelToken
            constant.searchToken = Axios.CancelToken.source();
            let query = loadMore === false ? searchData : search;

            if (query === "") {
                setOffset(0);
                return getTheImages(0);
            } else {
                const addOffset = offset > 0 ? `&pos=${offset}` : "";
                const response = await Axios.get(
                    `https://g.tenor.com/v1/search?q=${query}&key=2VOBHKV2KHCM&limit=20${addOffset}`, {
                    cancelToken: constant.searchToken.token
                }
                );

                // if results is less then 20 setting data finished to true to stop the execution of function
                if (response.data.results.length < 20) {
                    setDataFinished(true);
                }

                if (loadMore === false) {
                    setOffset(response.data.next);
                    setImages(response.data.results);
                } else {
                    setOffset(response.data.next);
                    setImages([...images, ...response.data.results]);
                }
                setLoading(false);
            }

        } catch (error) {
            setLoading(false);
        }
    };



    // get initial images and load more when scroll
    const getTheImages = async (noQueryOffset) => {
        try {
            setLoading(true);
            let addOffset;

            if (noQueryOffset === 0) {
                addOffset = noQueryOffset > 0 ? `&pos=${noQueryOffset}` : "";
            } else {
                addOffset = offset > 0 ? `&pos=${offset}` : "";
            }

            const response = await Axios.get(
                `https://g.tenor.com/v1/trending?key=2VOBHKV2KHCM&limit=20${addOffset}`
            );
            if (noQueryOffset === 0) {
                setImages(response.data.results);
                setLoading(false);
            } else {
                setImages([...images, ...response.data.results]);
                setOffset(response.data.next);
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
        }
    };


    const scrollStarted = (e) => {
        if (e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight - 50) {
            if (loading || dataFinished) return;
            if (search !== "") {
                getSearchPhotos(true);
            } else {
                setOffset(0);
                getTheImages();
            }
        }
    };



    return (
        <div className='rightTenor'>
            <AppHeadingCard title="Tenor" changeActiveApp={changeActiveApp} />
            <div className='input-container'>
                <input type="text"
                    value={search}
                    onChange={(e) => {
                        setSearch(e.target.value);
                        getSearchPhotos(false, e.target.value);
                    }}
                    placeholder='Search gifs' />
            </div>
            <div className='scroll-container-image' style={loading ? { overFlow: 'hidden' } : null} onScroll={scrollStarted}>
                <Masonry columnsCount={2} gutter={10}>
                    {
                        images.map((url, index) => {
                            return <div key={index} className='image-container'>
                                <img
                                    src={url.media[0].nanogif.url}
                                    alt='muse'
                                />
                            </div>;
                        })
                    }
                </Masonry>

                {
                    loading ? <ImagesLoader /> : null
                }
            </div>
        </div>
    );
};

export default Tenor;