import React, { useContext, useState, useEffect } from "react";
import CreateDataContext from '../../../store/CreateDataProvider';
import './Settings.css'


const Settings = () => {
    const context = useContext(CreateDataContext)
    const [totalCredits, setTotalCredits] = useState('')


    useEffect(() => {
        context.getUserData()
        let numberOfUsers = context.userData.workspaceOwnerFeatureFactoryData.users === "unlimited" ? 1 : parseInt(context.userData.workspaceOwnerFeatureFactoryData.users)
        setTotalCredits(context.userData.workspaceOwnerFeatureFactoryData.planId.aiCredites * numberOfUsers)
        // eslint-disable-next-line
    }, [])





    // Plans
    const plans = () => {
        return (
            <div className="plans">
                <h1>Free Plans</h1>
                <div className="plansParaTwo">
                    <p>
                        Number of AI credit's reamining in your account
                    </p>
                    <button>{context.userData.workspaceOwnerFeatureFactoryData.aiCredites} / {totalCredits}</button>
                </div>

                <a
                    href="https://www.app.creatosaurus.io/pricing"
                    style={{ cursor: "pointer" }}
                    target="_blank"
                    rel="noreferrer"
                >
                    <button style={{ cursor: "pointer" }} className="upgradeBtn">
                        Upgrade to Increase Limits
                    </button>
                </a>
            </div>
        );
    };

    return (
        <div className="center-settings">
            <div className="filter-container">
                <button className="active">Plans</button>
            </div>
            {plans()}
        </div>
    );
};

export default Settings;
