import React, { useState, useContext } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import './NavigationBar.css'
import axios from 'axios';
import jwt_decode from 'jwt-decode'
import { toast } from 'react-toastify';
import constant from '../../constant'
import CreateDataContext from '../../store/CreateDataProvider';


const NavigationBar = () => {
    const context = useContext(CreateDataContext)
    const navigate = useNavigate();
    const location = useLocation();
    const currentPath = location.pathname;


    const [loading, setLoading] = useState(false)


    const createSlug = (str) => {
        const lowercaseStr = str.toLowerCase();
        const slug = lowercaseStr.replace(/[^\w\s-]/g, '-');
        const trimmedSlug = slug.trim();
        const finalSlug = trimmedSlug.replace(/[\s-]+/g, '-');
        return finalSlug;
    };


    const createPost = async () => {
        try {
            if (context.postTitle === "") return toast.error('Title cannot be empty.')
            if (context.postDescription === "") return toast.error('Description cannot be empty.')
            setLoading(true)
            const token = localStorage.getItem('token');
            const decoded = jwt_decode(token)
            const config = { headers: { Authorization: `Bearer ${token}` } };
            const currentDate = new Date();
            const formattedDate = currentDate.toLocaleDateString('en-US', {
                month: 'long',
                day: 'numeric',
                year: 'numeric'
            });


            const body = {
                workspaceId: localStorage.getItem('organizationId'),
                userId: localStorage.getItem('userId'),
                metaData: {
                    coverImage: context.postCoverImage,
                    title: context.postTitle,
                    description: context.postDescription,
                    slug: createSlug(context.postTitle)
                },
                info: {
                    createdBy: localStorage.getItem('userName'),
                    createdOn: formattedDate,
                    lastEditedOn: '',
                    lastEditedBy: localStorage.getItem('userName'),
                },
                blocks: context.postJson,
                type: 'draft',
                isTemplate: decoded.isAdmin
            }

            const response = await axios.post(`${constant.stenoURL}/post/create`, body, config)
            context.handleSavedPost(response.data.data)
            context.handleSavedDataChanged(false)
            navigate(`/editor/post/${response.data.data._id}`)
            toast.success('Draft saved successfully.')
            setLoading(false)
        } catch (error) {
            setLoading(false)
            toast.error('Failed to create a post.')
        }
    }

    const updatePost = async () => {
        try {
            if (context.postTitle === "") return toast.error('Title cannot be empty.')
            if (context.postDescription === "") return toast.error('Description cannot be empty.')
            setLoading(true)
            const token = localStorage.getItem('token');
            const decoded = jwt_decode(token)
            const config = { headers: { Authorization: `Bearer ${token}` } };
            const currentDate = new Date();
            const formattedDate = currentDate.toLocaleDateString('en-US', {
                month: 'long',
                day: 'numeric',
                year: 'numeric'
            });



            if (context.savedPost?.isTemplate) {
                const body = {
                    workspaceId: localStorage.getItem('organizationId'),
                    userId: localStorage.getItem('userId'),
                    metaData: {
                        coverImage: context.postCoverImage,
                        title: context.postTitle,
                        description: context.postDescription,
                        slug: createSlug(context.postTitle)
                    },
                    info: {
                        createdBy: localStorage.getItem('userName'),
                        createdOn: formattedDate,
                        lastEditedOn: '',
                        lastEditedBy: localStorage.getItem('userName'),
                    },
                    blocks: context.postJson,
                    type: 'draft',
                    isTemplate: decoded.isAdmin
                }

                const response = await axios.post(`${constant.stenoURL}/post/create`, body, config)
                context.handleSavedPost(response.data.data)
                context.handleSavedDataChanged(false)
                toast.success('Draft updated successfully.')
                setLoading(false)
            } else {
                const body = {
                    workspaceId: context.savedPost?.workspaceId,
                    userId: context.savedPost?.userId,
                    metaData: {
                        coverImage: context.postCoverImage,
                        title: context.postTitle,
                        description: context.postDescription,
                        slug: createSlug(context.postTitle)
                    },
                    info: {
                        createdBy: context.savedPost?.info?.createdBy,
                        createdOn: context.savedPost?.info?.createdOn,
                        lastEditedOn: formattedDate,
                        lastEditedBy: localStorage.getItem('userName'),
                    },
                    blocks: context.postJson,
                    type: 'draft',
                    isTemplate: decoded.isAdmin
                }
                const response = await axios.put(`${constant.stenoURL}/post/update/${constant.savedPost._id}`, body, config)
                context.handleSavedPost(response.data.data)
                context.handleSavedDataChanged(false)
                toast.success('Draft updated successfully.')
                setLoading(false)
            }


        } catch (error) {
            setLoading(false)
            toast.error('Failed to update a post.')
        }
    }



    const handleSave = () => {
        if (context.isAdmin) return context.handleTemplateSavePopup(true)
        if (context.savedDataChanged) {
            updatePost()
        } else if (context.savedDataChanged === false && context.savedPost === null) {
            createPost()
        }
    }



    return (
        <nav>
            <div className='logo-container' onClick={() => {
                window.open('https://www.app.creatosaurus.io/')
            }}>
                <svg width="73" height="35" viewBox="0 0 73 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_4973_132)">
                        <path d="M49.0625 35.0007L45.8661 33.0234L45.3594 35.0007H49.0625Z" fill="black" />
                        <path d="M53.7871 32.5093L49.5545 30.5625V32.5855L53.7871 32.5093Z" fill="black" />
                        <path d="M44.952 34.8246L41.1956 31.2968L43.1462 27.9023L45.5234 32.6226L44.952 34.8246Z" fill="black" />
                        <path d="M38.1094 7.93945L29.5146 17.5247L4.59516 17.3419L38.1094 7.93945Z" fill="black" />
                        <path d="M59.4128 17.4258L59.4395 22.0736H54.4792L59.4128 17.4258Z" fill="black" />
                        <path d="M56.5146 24.7022L59.4023 22.5078H56.5908L56.5146 24.7022Z" fill="black" />
                        <path d="M40.9388 30.8731L47.0039 20.3125L39.0035 24.2251L40.9388 30.8731Z" fill="black" />
                        <path d="M9.20117 15.6L4.27518 16.983L0.000652313 14.4609L9.20117 15.6Z" fill="black" />
                        <path d="M68.3273 13.9049L63.1041 12.1791L62.3688 7.68359L69.6035 13.3449L68.3273 13.9049Z" fill="black" />
                        <path d="M49.123 32.5846L44.037 26.3518L46.2467 22.5078H49.123V32.5846Z" fill="black" />
                        <path d="M46.9121 19.8798L38.6107 23.9372L30.0464 17.5749L38.5955 8.04297L44.8663 10.4507L46.9121 19.8798Z" fill="black" />
                        <path d="M72.709 6.05341L64.2285 8.59069L62.3084 7.08584L61.4169 1.83984H70.5527L72.709 6.05341Z" fill="black" />
                        <path d="M60.9818 1.83984L62.6543 12.0994L59.4198 16.8273L53.7052 11.2842L56.2578 6.38104L60.9818 1.83984Z" fill="black" />
                        <path d="M53.85 22.0729H46.4934L47.4611 20.389L45.3429 10.6094L53.0309 11.2266L59.1074 17.1202L53.85 22.0729Z" fill="black" />
                        <path d="M66.0576 1.40579H68.1758L67.0633 0L66.0576 1.40579Z" fill="black" />
                    </g>
                    <defs>
                        <clipPath id="clip0_4973_132">
                            <rect width="72.7087" height="35" fill="white" transform="matrix(-1 0 0 1 72.709 0)" />
                        </clipPath>
                    </defs>
                </svg>
                <h1>Creatosaurus</h1>
                <span>| Steno</span>
            </div>
            {currentPath.startsWith("/editor") ? <div className="save-container">
                <span onClick={() => {
                    handleSave()
                }} style={{
                    cursor: "pointer",
                    marginRight: "20px"
                }}>{loading ? 'Saving' : context.savedDataChanged || context.savedPost === null ? 'Save' : 'Saved'}</span>

                {context.savedPost !== null ? <span style={{
                    cursor: "pointer"
                }} onClick={() => {
                    context.deleteDraft(context.savedPost._id)
                    context.handleSavedPost(null)
                    context.handlePostTitle('')
                    context.handlePostDescription('')
                    context.handlePostCoverImage('')
                    navigate("/")
                }}>Delete</span> : null}
            </div> : null}

            <div className='right-container'>
                {currentPath.startsWith("/editor") ? <span onClick={() => {
                    context.handleSavedPost(null)
                    context.handlePostTitle('')
                    context.handlePostDescription('')
                    context.handlePostCoverImage('')
                    navigate("/")
                }} style={{
                    cursor: "pointer"
                }}>Back To Dashboard</span> : null}
                <span>Workspace: {localStorage.getItem('organizationName')}</span>
                <span onClick={() => window.open("https://www.app.creatosaurus.io/", "_self")}>Creator Studio</span>
            </div>
        </nav>
    )
}

export default NavigationBar