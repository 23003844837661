import React, { useContext } from 'react'
import './Template.css'
import TemplateCard from '../../../card_components/template_card/TemplateCard';
import CreateDataContext from '../../../store/CreateDataProvider'
import TemplateLoading from '../../../card_components/template_loading/TemplateLoading';


const Templates = () => {
    const context = useContext(CreateDataContext)
    const loadingArray = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10,]
    return (
        <div className="center-templates-container">
            <div className="templates-card-grid">
                {context.templateLoading ? loadingArray.map(() => {
                    return <TemplateLoading />
                }) : context.templates.map((data, index) => {
                    return <TemplateCard key={index} data={data} />
                })}
            </div>
        </div>
    );
}

export default Templates;