import React, { useState, useEffect, useContext } from 'react';
import './KeywordSearch.css';
import AppHeadingCard from '../app_heading_card/AppHeadingCard';
import axios from 'axios';
import HashtagsLoaders from '../hashtags_loader/HashtagsLoaders'
import { toast } from 'react-toastify';
import CountryPopup from '../../pop_components/country_popup/CountryPopup';
import CreateDataContext from '../../store/CreateDataProvider';
import upRed from '../../assets/seo_assets/upRedArrow.svg'
import upYellow from '../../assets/seo_assets/upYellowArrow.svg'
import greenTick from '../../assets/seo_assets/greenTick.svg'
import constant from '../../constant';

const KeywordSearch = ({ changeActiveApp }) => {
    const context = useContext(CreateDataContext)
    const [loading, setLoading] = useState(false)
    const [keyword, setKeyword] = useState('')
    const [allKeywords, setAllKeywords] = useState([])
    const [keywords, setKeywords] = useState([])
    const [activeTab, setActiveTab] = useState('keywords')
    const [keywordsToCopy, setKeywordsToCopy] = useState([])
    const [sortBy, setSortBy] = useState('relevance')
    const [relatedTerms, setRelatedTerms] = useState([])
    const [competitorKeywords, setCompetitorKeywords] = useState([])
    const [relatedTermsFilters, setRelatedTermsFilter] = useState({
        type: 'all',
        modifier: 'all'
    })
    const [isDataFetchedAllready, setIsDataFetchedAllready] = useState({
        keywords: false,
        terms: false,
        competitor: false
    })
    const [country, setCountry] = useState({ country: "All Global Locations", code: "GB", flag: "🌏" })
    const [isCountryDropdownActive, setIsCountryDropdownActive] = useState(false)
    const [competitorSortBy, setCompetitorSortBy] = useState('relevance')
    const serperApiKey = '98b7acfe5f00514594499dce5b6bed6d9b2ae334'
    const OPENAI_API_KEY = 'sk-wyUXdlbTKvjB7HhwTc0rU4hHsiLO1Wx454X3Lpei';
    const dataForSeoKey = 'bWFsYXZAY3JlYXRvc2F1cnVzLmlvOmY4MTQ1NTk4ODljMWEyOTQ='

    useEffect(() => {
        if (keyword !== "") {
            searchKeyword()
        }
    }, [activeTab, country])

    const makeAutocompleteRequest = async (keyword, modifiers, apiKey) => {
        const results = [];

        await Promise.all(modifiers.map(async (modifier) => {
            let query = {
                "q": modifier.type === "Related" ? keyword : modifier.type === "Questions" ? `${modifier.value} ${keyword}` : modifier.type === "Prepositions" ? `${keyword} ${modifier.value}` : modifier.type === "Comparisons" ? `${keyword} ${modifier.value}` : modifier.type === "Alphabetical" ? `${keyword} ${modifier.value}` : `${keyword} ${modifier.value}`
            }

            if (country.code !== "GB") {
                query.gl = country.code
            }

            const body = JSON.stringify(query);

            const response = await axios.post('https://google.serper.dev/autocomplete', body, {
                headers: {
                    'X-API-KEY': apiKey,
                    'Content-Type': 'application/json'
                }
            });

            const suggestions = response.data.suggestions.map((word) => ({
                keyword: word.value,
                type: modifier.type,
                modifier: modifier.value
            }));

            results.push(...suggestions);
        }));

        return results;
    };

    const extractSiteLinks = (searchResults) => {
        let siteLinks = [];
        searchResults.forEach(result => {
            if (result.sitelinks) {
                result.sitelinks.forEach(sitelink => {
                    siteLinks.push(sitelink.link);
                });
            }
        });
        return siteLinks;
    }

    const extractKeywords = (text, type) => {
        const data = text.split("\n");
        const keywords = [];
        const numberPattern = /^\d+\./;

        data.forEach(line => {
            const keyword = line.split('.')[1]?.trim().toLowerCase().replace(/"/g, '');
            if (numberPattern.test(line) && keyword) {
                keywords.push({
                    type: type,
                    keyword: keyword
                });
            }
        });

        return keywords;
    };


    const fetchAllData = async (dataForSeoLinksPromises) => {
        try {
            const results = await Promise.all(dataForSeoLinksPromises);
            let allHTags = [];
            results.forEach(result => {
                if (result && result.tasks) {
                    result.tasks.forEach(task => {
                        const items = task.result[0].items;
                        if (items) {
                            items.forEach(item => {
                                if (item.meta) {
                                    const htagsMeta = item.meta.htags || {};
                                    ['h1', 'h2', 'h3', 'h4'].forEach(tag => {
                                        if (htagsMeta[tag]) {
                                            allHTags = allHTags.concat(htagsMeta[tag]);
                                        }
                                    });
                                }
                            });
                        }
                    });
                }
            });

            const allHTagsString = allHTags.join(', ');
            return allHTagsString;
        } catch (error) {
            console.error("Error processing data:", error);
            return null;
        }
    };

    const ranges = [
        { min: 1, max: 5, label: "1 to 5" },
        { min: 6, max: 10, label: "6 to 10" },
        { min: 11, max: 20, label: "11 to 20" },
        { min: 21, max: 50, label: "21 to 50" },
        { min: 51, max: 100, label: "51 to 100" },
        { min: 101, max: 200, label: "101 to 200" },
    ];

    const getRangeLabel = (count, ranges) => {
        for (let range of ranges) {
            if (count >= range.min && count <= range.max) {
                return range.label;
            }
        }
        return count > ranges[ranges.length - 1].max ? `>${ranges[ranges.length - 1].max}` : "0";
    }

    const searchKeyword = async () => {
        try {
            if (context.userData.workspaceOwnerFeatureFactoryData.planId.planName !== "agency") return toast.info('Upgrade your plans to use keyword search.')
            setIsCountryDropdownActive(false)
            setLoading(true)
            if (activeTab === "keywords") {
                if (isDataFetchedAllready.keywords) {
                    constant.allKeywords = []
                    setAllKeywords([])
                    constant.allKeywords = keywords
                    setAllKeywords(keywords)
                } else {
                    const body = [
                        {
                            keywords: [keyword],
                            sort_by: sortBy
                        }
                    ]
                    if (country.code !== "GB") {
                        if (country.location_code) {
                            body[0].location_code = country.location_code
                        }
                    }
                    const response = await axios.post('https://api.dataforseo.com/v3/keywords_data/google_ads/keywords_for_keywords/live', body, {
                        headers: {
                            Authorization: 'Basic bWFsYXZAY3JlYXRvc2F1cnVzLmlvOmY4MTQ1NTk4ODljMWEyOTQ=',
                            'Content-Type': 'application/json'
                        }
                    })
                    constant.allKeywords = response.data.tasks[0].result || []
                    setAllKeywords(response.data.tasks[0].result || [])
                    setKeywords(response.data.tasks[0].result || [])
                    setIsDataFetchedAllready({ ...isDataFetchedAllready, keywords: true })
                }
            } else if (activeTab === "Related terms") {
                if (isDataFetchedAllready.terms) {
                    constant.allKeywords = []
                    setAllKeywords([])
                    constant.allKeywords = relatedTerms
                    setAllKeywords(relatedTerms)
                } else {
                    const alphabetArray = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'].map(value => ({
                        type: 'Alphabetical',
                        value
                    }));

                    const questionsArray = ['when', 'why', 'how', 'can', 'where'].map(value => ({
                        type: 'Questions',
                        value
                    }));

                    const comparisonArray = ['vs', 'versus'].map(value => ({
                        type: 'Comparisons',
                        value
                    }));

                    const prepositionArray = ['for', 'is', 'near', 'with'].map(value => ({
                        type: 'Prepositions',
                        value
                    }));

                    const numberArray = [1, 2].map(value => ({
                        type: 'Number',
                        value
                    }));



                    let filteredTerms = [];

                    // Related terms
                    const relatedTermsData = await makeAutocompleteRequest(keyword, [{ type: "Related", value: "All" }], serperApiKey);
                    filteredTerms.push(...relatedTermsData);

                    // Questions
                    const questionData = await makeAutocompleteRequest(keyword, questionsArray, serperApiKey);
                    filteredTerms.push(...questionData);

                    // Prepositions
                    const prepositionData = await makeAutocompleteRequest(keyword, prepositionArray, serperApiKey);
                    filteredTerms.push(...prepositionData);

                    // Comparisons
                    const comparisonsData = await makeAutocompleteRequest(keyword, comparisonArray, serperApiKey);
                    filteredTerms.push(...comparisonsData);

                    // Alphabetical
                    const alphabeticalData = await makeAutocompleteRequest(keyword, alphabetArray, serperApiKey);
                    filteredTerms.push(...alphabeticalData);

                    // Number
                    const numberData = await makeAutocompleteRequest(keyword, numberArray, serperApiKey);
                    filteredTerms.push(...numberData);
                    constant.allKeywords = filteredTerms
                    setAllKeywords(filteredTerms)
                    setRelatedTerms(filteredTerms)
                    setIsDataFetchedAllready({ ...isDataFetchedAllready, terms: true })
                }
            } else if (activeTab === "Competitor") {
                if (isDataFetchedAllready.competitor) {
                    constant.allKeywords = []
                    setAllKeywords([])
                    constant.allKeywords = competitorKeywords
                    setAllKeywords(competitorKeywords)
                } else {
                    let data = JSON.stringify({
                        "q": keyword
                    });
                    const serperRes = await axios.post('https://google.serper.dev/search', data, {
                        headers: {
                            'X-API-KEY': serperApiKey,
                            'Content-Type': 'application/json'
                        },
                    })

                    const links = extractSiteLinks(serperRes.data.organic)

                    const dataForSeoConfig = {
                        headers: {
                            'Authorization': `Basic ${dataForSeoKey}`,
                            'Content-Type': 'application/json'
                        }
                    };


                    const dataForSeoLinksPromises = links.map(async (link) => {
                        const data = [
                            {
                                url: link,
                                check_spell: false,
                                disable_cookie_popup: false,
                                return_despite_timeout: false,
                                load_resources: false,
                                enable_javascript: false,
                                enable_browser_rendering: false
                            }
                        ];
                        try {
                            const response = await axios.post('https://api.dataforseo.com/v3/on_page/instant_pages', data, dataForSeoConfig)
                            return response.data;
                        } catch (error) {
                            console.error("Error fetching data:", error);
                            return null;
                        }
                    })
                    let allText = ''
                    const urlInfo = await fetchAllData(dataForSeoLinksPromises)
                    allText = urlInfo
                    const linkPromises = links.map(async link => {
                        let data = JSON.stringify({
                            "url": link
                        });
                        try {
                            const response = await axios.post('https://scrape.serper.dev', data, {
                                headers: {
                                    'X-API-KEY': serperApiKey,
                                    'Content-Type': 'application/json'
                                },
                            });

                            return {
                                text: response.data.text
                            };
                        } catch (error) {
                            console.error("Error fetching data:", error);
                            return null;
                        }
                    });

                    const linksInfo = await Promise.all(linkPromises);

                    const config = {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${OPENAI_API_KEY}`
                        }
                    };

                    const body = {
                        model: 'gpt-3.5-turbo',
                        messages: [{
                            role: 'system', content: 'Your a seo keyword extractor you will extract keyword from the given text'
                        }, {
                            role: 'user', content: `Extract seo keywords related to ${keyword} from the following table of content ${urlInfo}`
                        }]
                    };

                    const competitorKeywordsResponse = await axios.post('https://api.openai.com/v1/chat/completions', body, config);
                    const commonKeywordsData = extractKeywords(competitorKeywordsResponse.data.choices[0].message.content, "Keyword")

                    let keywords = []
                    const keywordsPromise = linksInfo.map(async (data) => {
                        allText += data.text
                        const body = {
                            model: 'gpt-3.5-turbo',
                            messages: [{
                                role: 'system', content: 'You are a seo keyword extractor you will extract keyword from the given text'
                            }, {
                                role: 'user', content: `Extract seo keywords related to "${keyword}" from the following blog ${data.text} and keep duplicates`
                            }]
                        };

                        const response = await axios.post('https://api.openai.com/v1/chat/completions', body, config);
                        const keywordsData = extractKeywords(response.data.choices[0].message.content, "Term")
                        keywords = [...keywords, ...keywordsData]
                    })

                    await Promise.all(keywordsPromise);
                    const keywordForRange = [...keywords, ...commonKeywordsData]
                    const keywordsWithRanges = keywordForRange.map(keyword => {
                        let count = 0;
                        const regex = new RegExp(`\\b${keyword.keyword.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')}\\b`, 'gi');

                        linksInfo.forEach(obj => {
                            if (obj.text.match(regex)) {
                                count += (obj.text.match(regex) || []).length;
                            }
                        });

                        return {
                            keyword: keyword,
                            count: count,
                            range: getRangeLabel(count, ranges)
                        };
                    });
                    const editorText = extractTextFromEditorData(context.savedPost.blocks)
                    const formattedKeywords = countKeywords(allText, keywordsWithRanges, editorText)
                    constant.allKeywords = formattedKeywords
                    setAllKeywords(formattedKeywords)
                    setCompetitorKeywords(formattedKeywords)
                    setIsDataFetchedAllready({ ...isDataFetchedAllready, competitor: true })
                }
            }
            setLoading(false)
        } catch (error) {
            setLoading(false)
            console.log(error)
        }
    }

    const addKeywordsToCopy = (value) => {
        if (keywordsToCopy.includes(value)) {
            const removedKeywords = keywordsToCopy.filter((data) => data !== value)
            setKeywordsToCopy(removedKeywords)
        } else {
            setKeywordsToCopy([...keywordsToCopy, value])
        }
    }

    const copyKeywords = () => {
        navigator.clipboard.writeText(keywordsToCopy.join(','))
        toast.success('Keywords copied to clipboard!')
    }

    const countKeywords = (text, keywords, editorText) => {
        let scrappedText = text.toLocaleLowerCase()
        let editorScrappedText = editorText.toLocaleLowerCase()
        const formattedData = keywords.map(data => {
            const regex = new RegExp(data.keyword.keyword, 'g');
            const count = (scrappedText.match(regex) || []).length;
            const editorCount = (editorScrappedText.match(regex) || []).length;
            return {
                ...data,
                count: count,
                editorCount: editorCount
            };
        });
        return formattedData
    }


    const extractTextFromEditorData = (editorData) => {
        return editorData.map(block => {
            switch (block.type) {
                case 'paragraph':
                    return block.data.text;
                case 'header':
                    return block.data.text;
                case 'list':
                    return block.data.items.join(' ');
                case 'table':
                    return block.data.content.flat().join(' ');
                default:
                    return '';
            }
        }).join(' ');
    }

    const calculatePercentage = (data) => {
        const percentage = (data.count > 0) ? (data.editorCount / data.count) * 100 : 0;
        return isNaN(percentage) ? 0 : percentage;
    };

    const getIndicatiorImage = (data) => {
        const percentage = calculatePercentage(data);
        let indicator;
        if (percentage < 30) {
            indicator = upRed
        } else if (percentage >= 30 && percentage < 70) {
            indicator = upYellow
        } else if (percentage > 70) {
            indicator = greenTick
        }

        return indicator;
    }

    useEffect(() => {
        filterRelatedTermsData()
    }, [relatedTermsFilters])


    const filterRelatedTermsData = () => {
        let filteredData = relatedTerms;

        if (relatedTermsFilters.type !== "all") {
            filteredData = filteredData.filter((data) => data.type.toLowerCase() === relatedTermsFilters.type);
        }

        if (relatedTermsFilters.modifier !== "all") {
            filteredData = filteredData.filter((data) => data.modifier === relatedTermsFilters.modifier);
        }
        constant.allKeywords = filteredData;
        setAllKeywords(filteredData);
    }

    const sortKeywordsBySearchVolume = (order) => {
        let sortedKeywords;

        if (order === "relevance") {
            sortedKeywords = [...keywords]; // Assuming relevance means the original order
        } else {
            sortedKeywords = keywords.slice().sort((a, b) => {
                if (order === 'low to high') {
                    return a.search_volume - b.search_volume;
                } else if (order === 'high to low') {
                    return b.search_volume - a.search_volume;
                } else {
                    throw new Error('Invalid sort order specified. Use "low to high", "high to low", or "relevance".');
                }
            });
        }
        constant.allKeywords = sortedKeywords;
        setAllKeywords(sortedKeywords);
    };

    const handleCountries = (value) => {
        setCountry(value)
        setIsDataFetchedAllready(false)
    }

    const sortCompetitorData = (order) => {
        let sortedKeywords;
        if (order === "relevance") {
            sortedKeywords = [...competitorKeywords];
        } else {
            sortedKeywords = competitorKeywords.slice().sort((a, b) => {
                if (order === 'low to high') {
                    return a.count - b.count;
                } else if (order === 'high to low') {
                    return b.count - a.count;
                } else {
                    throw new Error('Invalid sort order specified. Use "low to high", "high to low", or "relevance".');
                }
            });
        }
        constant.allKeywords = sortedKeywords;
        setAllKeywords(sortedKeywords);
    }

    return (
        <div className="keyword-search-container">
            <AppHeadingCard title="Keyword Search" changeActiveApp={changeActiveApp} />
            <div className="keyword-search-app-container">
                <div className="tabs-container">
                    <button onClick={() => {
                        constant.allKeywords = []
                        setAllKeywords([])
                        setActiveTab('keywords')
                    }} className={activeTab === 'keywords' ? 'active' : null}>Keywords</button>
                    <button className={activeTab === 'Related terms' ? 'active' : null} onClick={() => {
                        constant.allKeywords = []
                        setAllKeywords([])
                        setActiveTab('Related terms')
                    }}>Related terms</button>
                    <button className={activeTab === 'Competitor' ? 'active' : null} onClick={() => {
                        constant.allKeywords = []
                        setAllKeywords([])
                        setActiveTab('Competitor')
                    }}>Competitor</button>
                </div>

                <div className="input-container">
                    <div className="drop-down" onClick={() => {
                        setIsCountryDropdownActive(!isCountryDropdownActive)
                    }}>
                        <span>{country.flag}</span>
                        <span>{country.code}</span>
                        {isCountryDropdownActive ? <svg width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7 4L4 1L1 4" stroke="black" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round" />
                        </svg> : <svg xmlns="http://www.w3.org/2000/svg" width="8" height="5" viewBox="0 0 8 5" fill="none">
                            <path d="M7 1L4 4L1 1" stroke="black" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        }
                    </div>
                    <input type="search" placeholder='Search keyword' onChange={(e) => {
                        setKeyword(e.target.value)
                        setIsDataFetchedAllready({
                            keywords: false,
                            terms: false
                        })
                    }} onKeyDown={(e) => {
                        if (e.key === "Enter") {
                            searchKeyword()
                        }
                    }} value={keyword} />
                </div>
                {isCountryDropdownActive ? <CountryPopup handleCountries={handleCountries} selectedCountry={country} activeTab={activeTab} /> : null}
                <div className="dropdown-container">
                    {activeTab === "Related terms" ? <React.Fragment>
                        <select className='relatedTermsSelect' onChange={(e) => {
                            setRelatedTermsFilter({
                                ...relatedTermsFilters,
                                type: e.target.value
                            })
                        }}>
                            <option value="all">Type - All</option>
                            <option value="related">Type - Related</option>
                            <option value="questions">Type - Questions</option>
                            <option value="prepositions">Type - Preposition</option>
                            <option value="comparison">Type - Comparison</option>
                            <option value="alphabetical">Type - Alphabetical</option>
                            <option value="number">Type - Number</option>
                        </select>
                        <select className='relatedTermsSelect' onChange={(e) => {
                            setRelatedTermsFilter({
                                ...relatedTermsFilters,
                                modifier: e.target.value
                            })
                        }}>
                            <option value="all">Modifier - All</option>
                            <option value="when">Modifier - When</option>
                            <option value="is">Modifier - Is</option>
                        </select>
                    </React.Fragment> : activeTab === "Competitor" ? <select onChange={(e) => {
                        setCompetitorSortBy(e.target.value)
                        sortCompetitorData(e.target.value)
                    }} value={competitorSortBy}>
                        <option value="relevance">Sort by - Relevance</option>
                        <option value="high to low">Sort by - High To Low</option>
                        <option value="low to high">Sort by - Low To High</option>
                    </select> : <select onChange={(e) => {
                        setSortBy(e.target.value)
                        sortKeywordsBySearchVolume(e.target.value)
                    }} value={sortBy}>
                        <option value="relevance">Sort by - Relevance</option>
                        <option value="high to low">Sort by - High To Low</option>
                        <option value="low to high">Sort by - Low To High</option>
                    </select>}
                </div>

                <div className="keywords-container">
                    {loading ? <HashtagsLoaders /> : constant.allKeywords.map((data, index) => {
                        return (
                            <div className="keyword" key={index}>
                                <div className="left-side">
                                    <div className="check-box-container">
                                        <input type="checkbox" onChange={() => {
                                            if (activeTab === "Competitor") {
                                                addKeywordsToCopy(data.keyword.keyword)
                                            } else {
                                                addKeywordsToCopy(data.keyword)
                                            }
                                        }} checked={activeTab === "Competitor" ? keywordsToCopy.includes(data.keyword.keyword) : keywordsToCopy.includes(data.keyword)} />
                                    </div>
                                    <div className="info-container">
                                        <span>{activeTab === "Competitor" ? data.keyword?.keyword : data.keyword}</span>
                                        {activeTab === 'Related terms' ? <div className="bottom-container">
                                            <span>Type - {data.type}</span>
                                            <span>Modifier - {data.modifier}</span>
                                        </div> : activeTab === "Competitor" ? <div className="bottom-container">
                                            <span>Type - {data.keyword.type}</span>
                                            <span>Count - {data.range}</span>
                                        </div> : <div className="bottom-container">
                                            <span>Volume - {data.search_volume}</span>
                                            <span>Competition - {data.competition}</span>
                                        </div>}
                                    </div>
                                </div>
                                <div className="right-side" style={activeTab === "Competitor" ? { display: "flex", justifyContent: "center", alignItems: "center" } : null}>
                                    {activeTab === "Competitor" ? <img src={getIndicatiorImage(data)} alt="indicator" /> : <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" onClick={() => {
                                        navigator.clipboard.writeText(data.keyword)
                                        toast.success('Keyword copied to clipboard!')
                                    }}>
                                        <path d="M16.1667 16.6667H8C7.72386 16.6667 7.5 16.4428 7.5 16.1667V8C7.5 7.72386 7.72386 7.5 8 7.5H16.1667C16.4428 7.5 16.6667 7.72386 16.6667 8V16.1667C16.6667 16.4428 16.4428 16.6667 16.1667 16.6667Z" stroke="#808080" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M12.4999 7.49999V3.83333C12.4999 3.55719 12.2761 3.33333 11.9999 3.33333H3.83325C3.55711 3.33333 3.33325 3.55719 3.33325 3.83333V12C3.33325 12.2762 3.55711 12.5 3.83325 12.5H7.49992" stroke="#808080" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>}

                                </div>
                            </div>
                        )
                    })}
                </div>

            </div>


            {keywordsToCopy.length > 0 ? <div className="copy-container">
                <div className="left-side">
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                        <path d="M2.8125 7.5625L5.0625 9.8125L10.6875 4.1875" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <span>{keywordsToCopy.length} Keywords Selected</span>
                </div>
                <div className="right-side" onClick={() => {
                    copyKeywords()
                }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                        <path d="M11.3167 11.6667H5.6C5.4067 11.6667 5.25 11.51 5.25 11.3167V5.6C5.25 5.4067 5.4067 5.25 5.6 5.25H11.3167C11.51 5.25 11.6667 5.4067 11.6667 5.6V11.3167C11.6667 11.51 11.51 11.6667 11.3167 11.6667Z" stroke="#0078FF" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M8.74992 5.25001V2.68334C8.74992 2.49004 8.59323 2.33334 8.39992 2.33334H2.68325C2.48995 2.33334 2.33325 2.49004 2.33325 2.68334V8.40001C2.33325 8.59333 2.48995 8.75001 2.68325 8.75001H5.24992" stroke="#0078FF" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <span>Copy All</span>
                </div>
            </div> : null}
        </div>
    );
};

export default KeywordSearch;
