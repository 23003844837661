import React from 'react';
import cancelLogo from '../../assets/cross.svg';
import './MoreOption.css';

const MoreOption = (props) => {
    const openWindow = () => {
        window.open(`${props.appUrl}`);
    };

    const changeApp = (e) => {
        e.preventDefault();
        e.stopPropagation();
        props.changeActiveApp("AppDetails", props.appInfo);
    };

    const openContactUs = () => {
        window.open('https://www.creatosaurus.io/contact');
    };

    return (
        <div className='sub-card1'>
            <div className='head'>
                <span>{props.appInfo.title}</span>
                <img alt="cancellogo" src={cancelLogo} onClick={() => props.close()} />
            </div>
            {props.appInfo.title === "Uploads" ? null : <button onClick={openWindow}>Go to {props.appInfo.title}</button>}
            {props.appInfo.title === "App Store" ? <button style={{
                height: "35px"
            }} onClick={openContactUs}>Request new app integration</button> : <button onClick={changeApp}>App details</button>}
            {props.authenticationRequired ? (<button onClick={() => { props.isUserSignIn ? props.signOut() : props.login(); }} >{props.isUserSignIn ? props.slackWorkspaceName === undefined ? "Signout" : `Signout ${props.slackWorkspaceName}` : "Signin"}</button>) : null}
        </div>
    );
};

export default MoreOption;