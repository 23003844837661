const captionTypes = [
    {
        "title": "Freestyle",
        "subtitle": "Let Captionator AI answer your custom query",
        "image": "content",
        "url": "chat",
        "textInputTitle": false,
        "textAreaTitle": "Ask me anything!",
        "textInputTitlePlaceholder": "ex. Creatosaurus",
        "textAreaTitlePlaceholder": "ex. What is the best way to market your product?",
        "textAreaCharacterLimit": 400
    },
    {
        "title": "Active to Passive Voice",
        "subtitle": "Converts your copy to passive voice",
        "image": "content",
        "url": "active/to/passive",
        "textInputTitle": false,
        "textAreaTitle": "Content Description",
        "textInputTitlePlaceholder": "ex. Creatosaurus",
        "textAreaTitlePlaceholder": "ex. The dog chases the ball.",
        "textAreaCharacterLimit": 400
    },
    {
        "title": "Ad Campaign Ideas",
        "subtitle": "Brainstorm compelling campaign ideas",
        "image": "content",
        "url": "advertisement/campaign",
        "textInputTitle": "Product Name",
        "textAreaTitle": "Product Description",
        "textInputTitlePlaceholder": "ex. Creatosaurus",
        "textAreaTitlePlaceholder": "ex. Transform Your Marketing Strategy with 10x Faster Storytelling. Creatosaurus lets you easily curate ideas, design graphics, write AI content, edit videos, schedule posts, search hashtags, craft articles, manage analytics, generate reports, apps & more—in one place. Try for free.",
        "textAreaCharacterLimit": 400
    },
    {
        "title": "Ad Copy",
        "subtitle": "Writes ad copy for your brand",
        "image": "content",
        "url": "advertisement/copy",
        "textInputTitle": "Product Name",
        "textAreaTitle": "Product Description",
        "textInputTitlePlaceholder": "ex. Creatosaurus",
        "textAreaTitlePlaceholder": "ex. Transform Your Marketing Strategy with 10x Faster Storytelling. Creatosaurus lets you easily curate ideas, design graphics, write AI content, edit videos, schedule posts, search hashtags, craft articles, manage analytics, generate reports, apps & more—in one place. Try for free.",
        "textAreaCharacterLimit": 400
    },
    {
        "title": "Ad Copy Variants",
        "subtitle": "Suggests variation of your ad copy",
        "image": "content",
        "url": "advertisement/copy/variants",
        "textInputTitle": "Product Name",
        "textAreaTitle": "Ad Description",
        "textInputTitlePlaceholder": "ex. Creatosaurus",
        "textAreaTitlePlaceholder": "ex. Creatosaurus is the one stop shop for all your storytelling needs. Connect with audiences faster and better than ever before.",
        "textAreaCharacterLimit": 400
    },
    {
        "title": "Ad Jingles",
        "subtitle": "Creative jingles for your next Ad",
        "image": "content",
        "url": "advertisement/jingle",
        "textInputTitle": "Product Name",
        "textAreaTitle": "Product Description",
        "textInputTitlePlaceholder": "ex. Creatosaurus",
        "textAreaTitlePlaceholder": "ex. Transform Your Marketing Strategy with 10x Faster Storytelling. Creatosaurus lets you easily curate ideas, design graphics, write AI content, edit videos, schedule posts, search hashtags, craft articles, manage analytics, generate reports, apps & more—in one place. Try for free.",
        "textAreaCharacterLimit": 400
    },
    {
        "title": "Adjective Accelerator",
        "subtitle": "Find stronger alternative for the given adjective",
        "image": "content",
        "url": "adjective/accelerator",
        "textInputTitle": false,
        "textAreaTitle": "Describe Your Adjective",
        "textInputTitlePlaceholder": "ex. Creatosaurus",
        "textAreaTitlePlaceholder": "eg. funny",
        "textAreaCharacterLimit": 400
    },
    {
        "title": "AIDA Copywriting Formula",
        "subtitle": "Making you a better writer. Try Attention, Interest, Desire, Action!",
        "image": "content",
        "url": "product/aida",
        "textInputTitle": "Product Name",
        "textAreaTitle": "Product Description",
        "textInputTitlePlaceholder": "ex. Creatosaurus",
        "textAreaTitlePlaceholder": "ex. Transform Your Marketing Strategy with 10x Faster Storytelling. Creatosaurus lets you easily curate ideas, design graphics, write AI content, edit videos, schedule posts, search hashtags, craft articles, manage analytics, generate reports, apps & more—in one place. Try for free.",
        "textAreaCharacterLimit": 400
    },
    {
        "title": "Amazon Product Bullet Points",
        "subtitle": "This summarizes the features into bullet points",
        "image": "amazon",
        "url": "amazon/product/bullet/points",
        "textInputTitle": false,
        "textAreaTitle": "Product Description",
        "textInputTitlePlaceholder": "ex. Creatosaurus",
        "textAreaTitlePlaceholder": "ex. Transform Your Marketing Strategy with 10x Faster Storytelling. Creatosaurus lets you easily curate ideas, design graphics, write AI content, edit videos, schedule posts, search hashtags, craft articles, manage analytics, generate reports, apps & more—in one place. Try for free.",
        "textAreaCharacterLimit": 400
    },
    {
        "title": "Amazon Product Description",
        "subtitle": "Writes description for you Amazon product",
        "image": "amazon",
        "url": "product/amazon/description",
        "textInputTitle": "Product Name",
        "textAreaTitle": "Product Description",
        "textInputTitlePlaceholder": "ex. Creatosaurus",
        "textAreaTitlePlaceholder": "ex. Transform Your Marketing Strategy with 10x Faster Storytelling. Creatosaurus lets you easily curate ideas, design graphics, write AI content, edit videos, schedule posts, search hashtags, craft articles, manage analytics, generate reports, apps & more—in one place. Try for free.",
        "textAreaCharacterLimit": 400
    },
    {
        "title": "Analogy Generator",
        "subtitle": "Provides an analogy for your content",
        "image": "content",
        "url": "anology",
        "textInputTitle": false,
        "textAreaTitle": "Topic Description",
        "textInputTitlePlaceholder": "ex. Creatosaurus",
        "textAreaTitlePlaceholder": "ex. Transform Your Marketing Strategy with 10x Faster Storytelling.",
        "textAreaCharacterLimit": 400
    },
    {
        "title": "BAB Copywriting Formula",
        "subtitle": "Making you a better writer. Try Before, After, Bridge!",
        "image": "content",
        "url": "bab",
        "textInputTitle": false,
        "textAreaTitle": "Product Description",
        "textInputTitlePlaceholder": "ex. Creatosaurus",
        "textAreaTitlePlaceholder": "ex. Transform Your Marketing Strategy with 10x Faster Storytelling. Creatosaurus lets you easily curate ideas, design graphics, write AI content, edit videos, schedule posts, search hashtags, craft articles, manage analytics, generate reports, apps & more—in one place. Try for free.",
        "textAreaCharacterLimit": 400
    },
    {
        "title": "Blog Conclusion",
        "subtitle": "Keep your audience on their toes",
        "image": "blog",
        "url": "blog/conclusion",
        "textInputTitle": false,
        "textAreaTitle": "Blog Title Description",
        "textInputTitlePlaceholder": "ex. Creatosaurus",
        "textAreaTitlePlaceholder": "ex. 10 ways to scale your marketing strategy & storytelling using Creatosaurus.",
        "textAreaCharacterLimit": 400
    },
    {
        "title": "Blog Ideas",
        "subtitle": "Ideas for blog posts for your brand",
        "image": "blog",
        "url": "blog/idea",
        "textInputTitle": "Product Name",
        "textAreaTitle": "Product Description",
        "textInputTitlePlaceholder": "ex. Creatosaurus",
        "textAreaTitlePlaceholder": "ex. Transform Your Marketing Strategy with 10x Faster Storytelling. Creatosaurus lets you easily curate ideas, design graphics, write AI content, edit videos, schedule posts, search hashtags, craft articles, manage analytics, generate reports, apps & more—in one place. Try for free.",
        "textAreaCharacterLimit": 400
    },
    {
        "title": "Blog Intro",
        "subtitle": "Write introduction to your blog.",
        "image": "blog",
        "url": "blog/intro",
        "textInputTitle": false,
        "textAreaTitle": "Blog Description",
        "textInputTitlePlaceholder": "ex. Creatosaurus",
        "textAreaTitlePlaceholder": "ex. 10 ways to scale your marketing strategy & storytelling using Creatosaurus.",
        "textAreaCharacterLimit": 400
    },
    {
        "title": "Blog Listicle Ideas",
        "subtitle": "Ideas for listicle blog posts for your brand",
        "image": "blog",
        "url": "blog/listicle",
        "textInputTitle": false,
        "textAreaTitle": "Product Description",
        "textInputTitlePlaceholder": "ex. Creatosaurus",
        "textAreaTitlePlaceholder": "ex. Transform Your Marketing Strategy with 10x Faster Storytelling. Creatosaurus lets you easily curate ideas, design graphics, write AI content, edit videos, schedule posts, search hashtags, craft articles, manage analytics, generate reports, apps & more—in one place. Try for free.",
        "textAreaCharacterLimit": 400
    },
    {
        "title": "Blog Meta Descriptions",
        "subtitle": "SEO for your webpage so that you get the maximum visibility",
        "image": "content",
        "url": "blog/description",
        "textInputTitle": false,
        "textAreaTitle": "Blog Description",
        "textInputTitlePlaceholder": "ex. Creatosaurus",
        "textAreaTitlePlaceholder": "ex. 10 ways to scale your marketing strategy & storytelling using Creatosaurus.",
        "textAreaCharacterLimit": 400
    },
    {
        "title": "Blog Meta Title",
        "subtitle": "SEO for your webpage so that you get the maximum visibility",
        "image": "content",
        "url": "blog/meta",
        "textInputTitle": false,
        "textAreaTitle": "Blog Description",
        "textInputTitlePlaceholder": "ex. Creatosaurus",
        "textAreaTitlePlaceholder": "ex. 10 ways to scale your marketing strategy & storytelling using Creatosaurus.",
        "textAreaCharacterLimit": 400
    },
    {
        "title": "Blog Outline",
        "subtitle": "Bullet points for your next blog",
        "image": "blog",
        "url": "blog/outline",
        "textInputTitle": false,
        "textAreaTitle": "Blog Description",
        "textInputTitlePlaceholder": "ex. Creatosaurus",
        "textAreaTitlePlaceholder": "ex. 10 ways to scale your marketing strategy & storytelling using Creatosaurus.",
        "textAreaCharacterLimit": 400
    },
    {
        "title": "Blog Paragraphs",
        "subtitle": "Writes a paragraph for your blog section",
        "image": "blog",
        "url": "blog/heading/expander",
        "textInputTitle": false,
        "textAreaTitle": "Product Description",
        "textInputTitlePlaceholder": "ex. Creatosaurus",
        "textAreaTitlePlaceholder": "ex. It helps the creator & startup by their sass product",
        "textAreaCharacterLimit": 400
    },
    {
        "title": "Book Description",
        "subtitle": "Writes a description for your book",
        "image": "content",
        "url": "tagline/book/description",
        "textInputTitle": false,
        "textAreaTitle": "Book Brief",
        "textInputTitlePlaceholder": "ex. Creatosaurus",
        "textAreaTitlePlaceholder": "ex. 10 ways to scale marketing strategy with Creatosaurus",
        "textAreaCharacterLimit": 400
    },
    {
        "title": "Book Title",
        "subtitle": "Recommends amazing title for your next book",
        "image": "content",
        "url": "tagline/book/title",
        "textInputTitle": false,
        "textAreaTitle": "Book Description",
        "textInputTitlePlaceholder": "ex. Creatosaurus",
        "textAreaTitlePlaceholder": "ex. Creatosaurus helps marketing teams to scale their storytelling in half the time.",
        "textAreaCharacterLimit": 400
    },
    {
        "title": "Brand Voice",
        "subtitle": "Words that will characterize your brand",
        "image": "content",
        "url": "advertisement/brand/voice",
        "textInputTitle": "Product Name",
        "textAreaTitle": "Product Description",
        "textInputTitlePlaceholder": "ex. Creatosaurus",
        "textAreaTitlePlaceholder": "ex. Transform Your Marketing Strategy with 10x Faster Storytelling. Creatosaurus lets you easily curate ideas, design graphics, write AI content, edit videos, schedule posts, search hashtags, craft articles, manage analytics, generate reports, apps & more—in one place. Try for free.",
        "textAreaCharacterLimit": 400
    },
    {
        "title": "Cancellation Email",
        "subtitle": "Write a perfect cancellation email",
        "image": "content",
        "url": "email/cancellation",
        "textInputTitle": "Product Name",
        "textAreaTitle": "Product Description",
        "textInputTitlePlaceholder": "ex. Creatosaurus",
        "textAreaTitlePlaceholder": "ex. Transform Your Marketing Strategy with 10x Faster Storytelling. Creatosaurus lets you easily curate ideas, design graphics, write AI content, edit videos, schedule posts, search hashtags, craft articles, manage analytics, generate reports, apps & more—in one place. Try for free.",
        "textAreaCharacterLimit": 400
    },
    {
        "title": "Carousel Post",
        "subtitle": "Grow your social media with amazing carousels",
        "image": "content",
        "url": "product/carousel",
        "textInputTitle": false,
        "textAreaTitle": "Post Description",
        "textInputTitlePlaceholder": "ex. Creatosaurus",
        "textAreaTitlePlaceholder": "ex. Introducing new product named Creatosaurus, an all in one creative and marketing platform. along with bonus content of 10 ways to scale marketing strategy with Creatosaurus.",
        "textAreaCharacterLimit": 400
    },
    {
        "title": "Cliffhanger",
        "subtitle": "Adds a cliffhanger at the end of your paragraph",
        "image": "content",
        "url": "cliffhanger",
        "textInputTitle": false,
        "textAreaTitle": "Paragraph Description",
        "textInputTitlePlaceholder": "ex. Creatosaurus",
        "textAreaTitlePlaceholder": "ex. Sun rises in the east...",
        "textAreaCharacterLimit": 400
    },
    {
        "title": "Cold Email",
        "subtitle": "Write email that converts",
        "image": "content",
        "url": "email/coldemail",
        "textInputTitle": "Product Name",
        "textAreaTitle": "Product Description",
        "textInputTitlePlaceholder": "ex. Creatosaurus",
        "textAreaTitlePlaceholder": "ex. Transform Your Marketing Strategy with 10x Faster Storytelling. Creatosaurus lets you easily curate ideas, design graphics, write AI content, edit videos, schedule posts, search hashtags, craft articles, manage analytics, generate reports, apps & more—in one place. Try for free.",
        "textAreaCharacterLimit": 400
    },
    {
        "title": "Compelling Bullet Points",
        "subtitle": "Bullet points for your website, email, presentations & more",
        "image": "content",
        "url": "product/bullet/points",
        "textInputTitle": false,
        "textAreaTitle": "Content",
        "textInputTitlePlaceholder": "ex. Creatosaurus",
        "textAreaTitlePlaceholder": "ex. Transform Your Marketing Strategy with 10x Faster Storytelling. Creatosaurus lets you easily curate ideas, design graphics, write AI content, edit videos, schedule posts, search hashtags, craft articles, manage analytics, generate reports, apps & more—in one place. Try for free.",
        "textAreaCharacterLimit": 400
    },
    {
        "title": "Confirmation Email",
        "subtitle": "Customise confirmation email for your customers",
        "image": "content",
        "url": "email/confirmation",
        "textInputTitle": "Product Name",
        "textAreaTitle": "Product Description",
        "textInputTitlePlaceholder": "ex. Creatosaurus",
        "textAreaTitlePlaceholder": "ex. Transform Your Marketing Strategy with 10x Faster Storytelling. Creatosaurus lets you easily curate ideas, design graphics, write AI content, edit videos, schedule posts, search hashtags, craft articles, manage analytics, generate reports, apps & more—in one place. Try for free.",
        "textAreaCharacterLimit": 400
    },
    {
        "title": "Content Expander (Write more)",
        "subtitle": "It is like a magic wand that improves your content.",
        "image": "content",
        "url": "write/more",
        "textInputTitle": false,
        "textAreaTitle": "Content",
        "textInputTitlePlaceholder": "ex. Creatosaurus",
        "textAreaTitlePlaceholder": "ex. Creatosaurus helps marketing teams to scale their storytelling in half the time.",
        "textAreaCharacterLimit": 400
    },
    {
        "title": "Content Rewriter (Paraphrase)",
        "subtitle": "It is like a magic wand that improves your content.",
        "image": "content",
        "url": "content",
        "textInputTitle": false,
        "textAreaTitle": "Content",
        "textInputTitlePlaceholder": "ex. Creatosaurus",
        "textAreaTitlePlaceholder": "ex. Creatosaurus helps marketing teams to scale their storytelling in half the time.",
        "textAreaCharacterLimit": 400
    },
    {
        "title": "Content Simpilifier",
        "subtitle": "Explain it to a child",
        "image": "content",
        "url": "summarize/2nd/grader",
        "textInputTitle": false,
        "textAreaTitle": "Content Description",
        "textInputTitlePlaceholder": "ex. Creatosaurus",
        "textAreaTitlePlaceholder": "ex. Creatosaurus helps marketing teams to scale their storytelling in half the time.",
        "textAreaCharacterLimit": 400
    },
    {
        "title": "Cover Letter",
        "subtitle": "Magically writes a cover letter for you",
        "image": "content",
        "url": "tagline/cover/letter",
        "textInputTitle": "Job Role",
        "textAreaTitle": "Personal skills & experience",
        "textInputTitlePlaceholder": "ex. software developer",
        "textAreaTitlePlaceholder": "ex. javascript, react js with 5 years of experience",
        "textAreaCharacterLimit": 400
    },
    {
        "title": "Customer Review Response",
        "subtitle": "Instantly reply to your customer review",
        "image": "content",
        "url": "startup/review/response",
        "textInputTitle": "Product Name",
        "textAreaTitle": "Customer Review",
        "textInputTitlePlaceholder": "ex. Creatosaurus",
        "textAreaTitlePlaceholder": "ex. Few of the guys from my creative team are using your product “Creatosaurus” and they are loving it and I'm impressed by the bump up in their productivity. ",
        "textAreaCharacterLimit": 400
    },
    {
        "title": "Descriptive Expression",
        "subtitle": "Describes your content in a more expressive way",
        "image": "content",
        "url": "tagline/descriptive/expression",
        "textInputTitle": false,
        "textAreaTitle": "Content",
        "textInputTitlePlaceholder": "eg. Creatosaurus",
        "textAreaTitlePlaceholder": "ex. Creatosaurus helps marketing teams to scale their storytelling in half the time.",
        "textAreaCharacterLimit": 400
    },
    {
        "title": "Email Subject Lines",
        "subtitle": "Write a perfect email subject line",
        "image": "content",
        "url": "email/subject",
        "textInputTitle": false,
        "textAreaTitle": "Email Description",
        "textInputTitlePlaceholder": "ex. Creatosaurus",
        "textAreaTitlePlaceholder": "ex. Email with list of 10 ways to scale marketing strategy with Creatosaurus including bonus content and offers",
        "textAreaCharacterLimit": 400
    },
    {
        "title": "Emoji Ideas",
        "subtitle": "Provides suggestions for emojis for a short description",
        "image": "content",
        "url": "emoji/idea",
        "textInputTitle": false,
        "textAreaTitle": "Content Description",
        "textInputTitlePlaceholder": "ex. Creatosaurus",
        "textAreaTitlePlaceholder": "ex. Creatosaurus helps marketing teams to scale their storytelling in half the time.",
        "textAreaCharacterLimit": 400
    },
    {
        "title": "Engaging Questions",
        "subtitle": "Questions to boost engagement",
        "image": "content",
        "url": "engaging/questions",
        "textInputTitle": false,
        "textAreaTitle": "Product Description",
        "textInputTitlePlaceholder": "ex. Creatosaurus",
        "textAreaTitlePlaceholder": "ex. Transform Your Marketing Strategy with 10x Faster Storytelling. Creatosaurus lets you easily curate ideas, design graphics, write AI content, edit videos, schedule posts, search hashtags, craft articles, manage analytics, generate reports, apps & more—in one place. Try for free.",
        "textAreaCharacterLimit": 400
    },
    {
        "title": "Engaging Social Posts",
        "subtitle": "Engage your audience with clever one-liners",
        "image": "social-media",
        "url": "engaging/post",
        "textInputTitle": false,
        "textAreaTitle": "Product Description",
        "textInputTitlePlaceholder": "ex. Creatosaurus",
        "textAreaTitlePlaceholder": "ex. Transform Your Marketing Strategy with 10x Faster Storytelling. Creatosaurus lets you easily curate ideas, design graphics, write AI content, edit videos, schedule posts, search hashtags, craft articles, manage analytics, generate reports, apps & more—in one place. Try for free.",
        "textAreaCharacterLimit": 400
    },
    {
        "title": "Essay Intro",
        "subtitle": "Writes intro paragraph to your essay title",
        "image": "content",
        "url": "essay/intro",
        "textInputTitle": false,
        "textAreaTitle": "Essay Description",
        "textInputTitlePlaceholder": "ex. Creatosaurus",
        "textAreaTitlePlaceholder": "ex. Creatosaurus helps marketing teams to scale their storytelling in half the time.",
        "textAreaCharacterLimit": 400
    },
    {
        "title": "Essay Outline",
        "subtitle": "Creates a framework for your essay",
        "image": "content",
        "url": "essay/outline",
        "textInputTitle": false,
        "textAreaTitle": "Essay Description",
        "textInputTitlePlaceholder": "ex. Creatosaurus",
        "textAreaTitlePlaceholder": "ex. Earth - our home in solar system.",
        "textAreaCharacterLimit": 400
    },
    {
        "title": "Essay Paragraphs",
        "subtitle": "Writes a paragraph for your essay section",
        "image": "content",
        "url": "tagline/essay/paragraph",
        "textInputTitle": false,
        "textAreaTitle": "Essay Description",
        "textInputTitlePlaceholder": "ex. Creatosaurus",
        "textAreaTitlePlaceholder": "ex. Creatosaurus helps marketing teams to scale their storytelling in half the time.",
        "textAreaCharacterLimit": 400
    },
    {
        "title": "Event Copy",
        "subtitle": "Promote your event with a great content",
        "image": "content",
        "url": "advertisement/event/copy",
        "textInputTitle": "Event Name",
        "textAreaTitle": "Event Description",
        "textInputTitlePlaceholder": "ex. Marketing Webinar",
        "textAreaTitlePlaceholder": "ex. Creatosaurus helps marketing teams to scale their storytelling in half the time.",
        "textAreaCharacterLimit": 400
    },
    {
        "title": "Explain It Like A Professor",
        "subtitle": "Get a high level explanation for your statements",
        "image": "content",
        "url": "tagline/explain/like/professor",
        "textInputTitle": false,
        "textAreaTitle": "Content",
        "textInputTitlePlaceholder": "ex. Creatosaurus",
        "textAreaTitlePlaceholder": "ex. Creatosaurus helps marketing teams to scale their storytelling in half the time.",
        "textAreaCharacterLimit": 400
    },
    {
        "title": "FAB Copywriting Formula",
        "subtitle": "Making you a better writer. Try Feature, Advantage, Benefits!",
        "image": "content",
        "url": "fab",
        "textInputTitle": false,
        "textAreaTitle": "Product Description",
        "textInputTitlePlaceholder": "ex. Creatosaurus",
        "textAreaTitlePlaceholder": "ex. Transform Your Marketing Strategy with 10x Faster Storytelling. Creatosaurus lets you easily curate ideas, design graphics, write AI content, edit videos, schedule posts, search hashtags, craft articles, manage analytics, generate reports, apps & more—in one place. Try for free.",
        "textAreaCharacterLimit": 400
    },
    {
        "title": "Facebook Ad Copy",
        "subtitle": "Variations of Facebook ad copy",
        "image": "facebook",
        "url": "facebook/ad/copy",
        "textInputTitle": "Product Name",
        "textAreaTitle": "Product Description",
        "textInputTitlePlaceholder": "ex. Creatosaurus",
        "textAreaTitlePlaceholder": "ex. Transform Your Marketing Strategy with 10x Faster Storytelling. Creatosaurus lets you easily curate ideas, design graphics, write AI content, edit videos, schedule posts, search hashtags, craft articles, manage analytics, generate reports, apps & more—in one place. Try for free.",
        "textAreaCharacterLimit": 400
    },
    {
        "title": "FAQs",
        "subtitle": "Suggests questions for your FAQ section",
        "image": "content",
        "url": "tagline/faq",
        "textInputTitle": false,
        "textAreaTitle": "Product Description",
        "textInputTitlePlaceholder": "ex. Creatosaurus",
        "textAreaTitlePlaceholder": "ex. Transform Your Marketing Strategy with 10x Faster Storytelling. Creatosaurus lets you easily curate ideas, design graphics, write AI content, edit videos, schedule posts, search hashtags, craft articles, manage analytics, generate reports, apps & more—in one place. Try for free.",
        "textAreaCharacterLimit": 400
    },
    {
        "title": "Feature to Benefits",
        "subtitle": "Turns feature of your product in the form of benefits",
        "image": "content",
        "url": "feature/benifit",
        "textInputTitle": false,
        "textAreaTitle": "Product Description",
        "textInputTitlePlaceholder": "ex. Creatosaurus",
        "textAreaTitlePlaceholder": "ex. Transform Your Marketing Strategy with 10x Faster Storytelling. Creatosaurus lets you easily curate ideas, design graphics, write AI content, edit videos, schedule posts, search hashtags, craft articles, manage analytics, generate reports, apps & more—in one place. Try for free.",
        "textAreaCharacterLimit": 400
    },
    {
        "title": "First to Third Person Converter",
        "subtitle": "Transforms first-person into third-person perspective",
        "image": "content",
        "url": "third/person",
        "textInputTitle": false,
        "textAreaTitle": "Content",
        "textInputTitlePlaceholder": "ex. Creatosaurus",
        "textAreaTitlePlaceholder": "ex. Creatosaurus helps marketing teams to scale their storytelling in half the time.",
        "textAreaCharacterLimit": 400
    },
    {
        "title": "Follow Up Email",
        "subtitle": "Follow up with confidence",
        "image": "content",
        "url": "email/follow/up",
        "textInputTitle": "Product Name",
        "textAreaTitle": "Product Description",
        "textInputTitlePlaceholder": "ex. Creatosaurus",
        "textAreaTitlePlaceholder": "ex. Transform Your Marketing Strategy with 10x Faster Storytelling. Creatosaurus lets you easily curate ideas, design graphics, write AI content, edit videos, schedule posts, search hashtags, craft articles, manage analytics, generate reports, apps & more—in one place. Try for free.",
        "textAreaCharacterLimit": 400
    },
    {
        "title": "Generic Email",
        "subtitle": "Writes a professional email for your next task",
        "image": "content",
        "url": "email/generic",
        "textInputTitle": "Email To",
        "textAreaTitle": "Email Subject & Brief",
        "textInputTitlePlaceholder": "ex. Bob, Head of Marketing at XYZ ABC Media",
        "textAreaTitlePlaceholder": "ex. Introducing new product named Creatosaurus, an all in one creative and marketing platform. along with bonus content of 10 ways to scale marketing strategy with Creatosaurus.",
        "textAreaCharacterLimit": 400
    },
    {
        "title": "Google Ad Description",
        "subtitle": "Variations of Google ad description",
        "image": "google",
        "url": "google/ad/description",
        "textInputTitle": "Product Name",
        "textAreaTitle": "Product Description",
        "textInputTitlePlaceholder": "ex. Creatosaurus",
        "textAreaTitlePlaceholder": "ex. Transform Your Marketing Strategy with 10x Faster Storytelling. Creatosaurus lets you easily curate ideas, design graphics, write AI content, edit videos, schedule posts, search hashtags, craft articles, manage analytics, generate reports, apps & more—in one place. Try for free.",
        "textAreaCharacterLimit": 400
    },
    {
        "title": "Google Ad Headline",
        "subtitle": "Variations of Google ad headline copy",
        "image": "google",
        "url": "google/ad/headline",
        "textInputTitle": "Product Name",
        "textAreaTitle": "Product Description",
        "textInputTitlePlaceholder": "ex. Creatosaurus",
        "textAreaTitlePlaceholder": "ex. Transform Your Marketing Strategy with 10x Faster Storytelling. Creatosaurus lets you easily curate ideas, design graphics, write AI content, edit videos, schedule posts, search hashtags, craft articles, manage analytics, generate reports, apps & more—in one place. Try for free.",
        "textAreaCharacterLimit": 400
    },
    {
        "title": "Grammar Correction",
        "subtitle": "Get rid of all the grammatical errors",
        "image": "content",
        "url": "grammer/correction",
        "textInputTitle": false,
        "textAreaTitle": "Content",
        "textInputTitlePlaceholder": "ex. Creatosaurus",
        "textAreaTitlePlaceholder": "ex. Creatosaurus helps marketing teams to scale their storytelling in half the time.",
        "textAreaCharacterLimit": 400
    },
    {
        "title": "Greetings",
        "subtitle": "Writes a heartfelt greeting for any occasion",
        "image": "content",
        "url": "tagline/greeting",
        "textInputTitle": false,
        "textAreaTitle": "Occassion Description",
        "textInputTitlePlaceholder": "ex. Creatosaurus",
        "textAreaTitlePlaceholder": "ex. First year product launch anniversary for Creatosaurus, an all in one creative and marketing platform.",
        "textAreaCharacterLimit": 400
    },
    {
        "title": "Growth Ideas",
        "subtitle": "Review strategic plans to grow your product",
        "image": "content",
        "url": "product/growth/ideas",
        "textInputTitle": false,
        "textAreaTitle": "Product Description",
        "textInputTitlePlaceholder": "ex. Creatosaurus",
        "textAreaTitlePlaceholder": "ex. Transform Your Marketing Strategy with 10x Faster Storytelling. Creatosaurus lets you easily curate ideas, design graphics, write AI content, edit videos, schedule posts, search hashtags, craft articles, manage analytics, generate reports, apps & more—in one place. Try for free.",
        "textAreaCharacterLimit": 400
    },
    {
        "title": "Hashtag Suggestion",
        "subtitle": "Suggests best social media hashtag for your post",
        "image": "content",
        "url": "advertisement/hashtag/suggestion",
        "textInputTitle": false,
        "textAreaTitle": "Post Description",
        "textInputTitlePlaceholder": "ex. Creatosaurus",
        "textAreaTitlePlaceholder": "ex. Introducing new product named Creatosaurus, an all in one creative and marketing platform. along with bonus content of 10 ways to scale marketing strategy with Creatosaurus.",
        "textAreaCharacterLimit": 400
    },
    {
        "title": "Hook Generator",
        "subtitle": "Discover a compelling hook for your content",
        "image": "content",
        "url": "product/hook",
        "textInputTitle": false,
        "textAreaTitle": "Content Description",
        "textInputTitlePlaceholder": "ex. Creatosaurus",
        "textAreaTitlePlaceholder": "ex. Transform Your Marketing Strategy with 10x Faster Storytelling. Creatosaurus lets you easily curate ideas, design graphics, write AI content, edit videos, schedule posts, search hashtags, craft articles, manage analytics, generate reports, apps & more—in one place. Try for free.",
        "textAreaCharacterLimit": 400
    },
    {
        "title": "HSO Copywriting Formula",
        "subtitle": "Making you a better writer. Try Hook, Story, Offer!",
        "image": "content",
        "url": "product/hso",
        "textInputTitle": "Product Name",
        "textAreaTitle": "Product Description",
        "textInputTitlePlaceholder": "ex. Creatosaurus",
        "textAreaTitlePlaceholder": "ex. Transform Your Marketing Strategy with 10x Faster Storytelling. Creatosaurus lets you easily curate ideas, design graphics, write AI content, edit videos, schedule posts, search hashtags, craft articles, manage analytics, generate reports, apps & more—in one place. Try for free.",
        "textAreaCharacterLimit": 400
    },
    {
        "title": "Image Captions",
        "subtitle": "Amazing captions for your image posts",
        "image": "content",
        "url": "tagline/image/caption",
        "textInputTitle": false,
        "textAreaTitle": "Image Description",
        "textInputTitlePlaceholder": "ex. Creatosaurus",
        "textAreaTitlePlaceholder": "ex. Introducing new product named Creatosaurus, an all in one creative and marketing platform. along with bonus content of 10 ways to scale marketing strategy with Creatosaurus.",
        "textAreaCharacterLimit": 400
    },
    {
        "title": "Instagram Captions",
        "subtitle": "Best captions for your Instagram post",
        "image": "content",
        "url": "advertisement/instagram/caption",
        "textInputTitle": false,
        "textAreaTitle": "Instagram Post Description",
        "textInputTitlePlaceholder": "ex. Creatosaurus",
        "textAreaTitlePlaceholder": "ex. Introducing new product named Creatosaurus, an all in one creative and marketing platform. along with bonus content of 10 ways to scale marketing strategy with Creatosaurus.",
        "textAreaCharacterLimit": 400
    },
    {
        "title": "Instagram Product Showcase",
        "subtitle": "Showcase your product in style on Instagram",
        "image": "content",
        "url": "advertisement/instagram/product/showcase",
        "textInputTitle": false,
        "textAreaTitle": "Product Description",
        "textInputTitlePlaceholder": "ex. Creatosaurus",
        "textAreaTitlePlaceholder": "ex. Transform Your Marketing Strategy with 10x Faster Storytelling. Creatosaurus lets you easily curate ideas, design graphics, write AI content, edit videos, schedule posts, search hashtags, craft articles, manage analytics, generate reports, apps & more—in one place. Try for free.",
        "textAreaCharacterLimit": 400
    },
    {
        "title": "Interview Questions",
        "subtitle": "Makes a list of questions for you to ask in your next interview",
        "image": "content",
        "url": "interview/question",
        "textInputTitle": false,
        "textAreaTitle": "Topic",
        "textInputTitlePlaceholder": "ex. Creatosaurus",
        "textAreaTitlePlaceholder": "ex. Social Media Marketing and SEO",
        "textAreaCharacterLimit": 400
    },
    {
        "title": "Job Description",
        "subtitle": "Details a job description for you",
        "image": "content",
        "url": "tagline/job/description",
        "textInputTitle": false,
        "textAreaTitle": "Job Brief",
        "textInputTitlePlaceholder": "ex. Creatosaurus",
        "textAreaTitlePlaceholder": "ex. Product Marketing Manager in consumer tech startup",
        "textAreaCharacterLimit": 400
    },
    {
        "title": "Landing Page Copy",
        "subtitle": "Creates headings & subheadings for your website, app, and email listings",
        "image": "content",
        "url": "website/headline",
        "textInputTitle": false,
        "textAreaTitle": "Product Description",
        "textInputTitlePlaceholder": "ex. Creatosaurus",
        "textAreaTitlePlaceholder": "ex. Transform Your Marketing Strategy with 10x Faster Storytelling. Creatosaurus lets you easily curate ideas, design graphics, write AI content, edit videos, schedule posts, search hashtags, craft articles, manage analytics, generate reports, apps & more—in one place. Try for free.",
        "textAreaCharacterLimit": 400
    },
    {
        "title": "Launch Your Product",
        "subtitle": "Announce your product/event launch with a great copy",
        "image": "content",
        "url": "advertisement/instagram/product/showcase",
        "textInputTitle": false,
        "textAreaTitle": "Product Description",
        "textInputTitlePlaceholder": "ex. Creatosaurus",
        "textAreaTitlePlaceholder": "ex. Transform Your Marketing Strategy with 10x Faster Storytelling. Creatosaurus lets you easily curate ideas, design graphics, write AI content, edit videos, schedule posts, search hashtags, craft articles, manage analytics, generate reports, apps & more—in one place. Try for free.",
        "textAreaCharacterLimit": 400
    },
    {
        "title": "Lead Magnet",
        "subtitle": "Getting your visitors to become clients",
        "image": "content",
        "url": "product/lead",
        "textInputTitle": false,
        "textAreaTitle": "Product Description",
        "textInputTitlePlaceholder": "ex. Creatosaurus",
        "textAreaTitlePlaceholder": "ex. Transform Your Marketing Strategy with 10x Faster Storytelling. Creatosaurus lets you easily curate ideas, design graphics, write AI content, edit videos, schedule posts, search hashtags, craft articles, manage analytics, generate reports, apps & more—in one place. Try for free.",
        "textAreaCharacterLimit": 400
    },
    {
        "title": "LinkedIn Ad Copy",
        "subtitle": "Variations of LinkedIn ad copy",
        "image": "linkedin",
        "url": "linkedin/ad/copy",
        "textInputTitle": "Product Name",
        "textAreaTitle": "Product Description",
        "textInputTitlePlaceholder": "ex. Creatosaurus",
        "textAreaTitlePlaceholder": "ex. Transform Your Marketing Strategy with 10x Faster Storytelling. Creatosaurus lets you easily curate ideas, design graphics, write AI content, edit videos, schedule posts, search hashtags, craft articles, manage analytics, generate reports, apps & more—in one place. Try for free.",
        "textAreaCharacterLimit": 400
    },
    {
        "title": "LinkedIn Bio",
        "subtitle": "Supercharge your LinkedIn with amazing bio",
        "image": "content",
        "url": "linkedin/bio",
        "textInputTitle": false,
        "textAreaTitle": "Your Brief Description",
        "textInputTitlePlaceholder": "ex. Creatosaurus",
        "textAreaTitlePlaceholder": "ex. Marketing freelancer for 3 years and expert in facebook ad and having a background in computer with graduation...",
        "textAreaCharacterLimit": 400
    },
    {
        "title": "Listicle",
        "subtitle": "Lists down the sub topics for your content",
        "image": "content",
        "url": "advertisement/listicle",
        "textInputTitle": false,
        "textAreaTitle": "Content Description",
        "textInputTitlePlaceholder": "ex. Creatosaurus",
        "textAreaTitlePlaceholder": "ex. Creatosaurus helps marketing teams to scale their storytelling in half the time.",
        "textAreaCharacterLimit": 400
    },
    {
        "title": "Microcopy",
        "subtitle": "Generate beautiful microcopy to connect with audience",
        "image": "content",
        "url": "startup/micro/copy",
        "textInputTitle": "Product Name",
        "textAreaTitle": "Product Description",
        "textInputTitlePlaceholder": "ex. Creatosaurus",
        "textAreaTitlePlaceholder": "ex. Transform Your Marketing Strategy with 10x Faster Storytelling. Creatosaurus lets you easily curate ideas, design graphics, write AI content, edit videos, schedule posts, search hashtags, craft articles, manage analytics, generate reports, apps & more—in one place. Try for free.",
        "textAreaCharacterLimit": 400
    },
    {
        "title": "Motto Generator",
        "subtitle": "Writes a perfect brand motto",
        "image": "content",
        "url": "advertisement/motto/generator",
        "textInputTitle": "Product Name",
        "textAreaTitle": "Product Description",
        "textInputTitlePlaceholder": "ex. Creatosaurus",
        "textAreaTitlePlaceholder": "ex. Transform Your Marketing Strategy with 10x Faster Storytelling. Creatosaurus lets you easily curate ideas, design graphics, write AI content, edit videos, schedule posts, search hashtags, craft articles, manage analytics, generate reports, apps & more—in one place. Try for free.",
        "textAreaCharacterLimit": 400
    },
    {
        "title": "Name Generator",
        "subtitle": "Name your product, brand or website in a unique way",
        "image": "content",
        "url": "product/name",
        "textInputTitle": false,
        "textAreaTitle": "Product Description",
        "textInputTitlePlaceholder": "ex. Creatosaurus",
        "textAreaTitlePlaceholder": "ex. Transform Your Marketing Strategy with 10x Faster Storytelling. Creatosaurus lets you easily curate ideas, design graphics, write AI content, edit videos, schedule posts, search hashtags, craft articles, manage analytics, generate reports, apps & more—in one place. Try for free.",
        "textAreaCharacterLimit": 400
    },
    {
        "title": "Next Product",
        "subtitle": "Grow your organisation with new product ideas",
        "image": "content",
        "url": "product/next/idea",
        "textInputTitle": false,
        "textAreaTitle": "Product Description",
        "textInputTitlePlaceholder": "ex. Creatosaurus",
        "textAreaTitlePlaceholder": "ex. Creatosaurus helps marketing teams to scale their storytelling in half the time.",
        "textAreaCharacterLimit": 400
    },
    {
        "title": "Offer Ideas",
        "subtitle": "Never run out of offer ideas for your product or brand again",
        "image": "content",
        "url": "offer/idea",
        "textInputTitle": false,
        "textAreaTitle": "Product Description",
        "textInputTitlePlaceholder": "ex. Creatosaurus",
        "textAreaTitlePlaceholder": "ex. Transform Your Marketing Strategy with 10x Faster Storytelling. Creatosaurus lets you easily curate ideas, design graphics, write AI content, edit videos, schedule posts, search hashtags, craft articles, manage analytics, generate reports, apps & more—in one place. Try for free.",
        "textAreaCharacterLimit": 400
    },
    {
        "title": "Paragraph Writer",
        "subtitle": "Informative paragraph for your heading, phrase, keyword",
        "image": "content",
        "url": "blog/paragraph",
        "textInputTitle": false,
        "textAreaTitle": "Product Description",
        "textInputTitlePlaceholder": "ex. Creatosaurus",
        "textAreaTitlePlaceholder": "ex. Creatosaurus helps marketing teams to scale their storytelling in half the time.",
        "textAreaCharacterLimit": 400
    },
    {
        "title": "PAS Copywriting Formula",
        "subtitle": "Making you a better writer. Try Pain, Agitate, Solution!",
        "image": "content",
        "url": "pas",
        "textInputTitle": false,
        "textAreaTitle": "Product Description",
        "textInputTitlePlaceholder": "ex. Creatosaurus",
        "textAreaTitlePlaceholder": "ex. Transform Your Marketing Strategy with 10x Faster Storytelling. Creatosaurus lets you easily curate ideas, design graphics, write AI content, edit videos, schedule posts, search hashtags, craft articles, manage analytics, generate reports, apps & more—in one place. Try for free.",
        "textAreaCharacterLimit": 400
    },
    {
        "title": "Passive to Active Voice",
        "subtitle": "Converts your copy to active voice",
        "image": "content",
        "url": "passive/to/active",
        "textInputTitle": false,
        "textAreaTitle": "Content Description",
        "textInputTitlePlaceholder": "ex. Creatosaurus",
        "textAreaTitlePlaceholder": "ex. The ball is being chased by the dog.",
        "textAreaCharacterLimit": 400
    },
    {
        "title": "Personal Opinion",
        "subtitle": "Generates personal opinion on a topic",
        "image": "content",
        "url": "tagline/personal/opinion",
        "textInputTitle": false,
        "textAreaTitle": "Content Description",
        "textInputTitlePlaceholder": "ex. Creatosaurus",
        "textAreaTitlePlaceholder": "ex. Creatosaurus helps marketing teams to scale their storytelling in half the time.",
        "textAreaCharacterLimit": 400
    },
    {
        "title": "Poems",
        "subtitle": "Writes a poem for your product or brand",
        "image": "content",
        "url": "poem",
        "textInputTitle": false,
        "textAreaTitle": "Topic",
        "textInputTitlePlaceholder": "ex. Creatosaurus",
        "textAreaTitlePlaceholder": "ex. Creatosaurus helps marketing teams to scale their storytelling in half the time.",
        "textAreaCharacterLimit": 400
    },
    {
        "title": "Poll Question & Answers",
        "subtitle": "Fun ways to engage with your audience",
        "image": "content",
        "url": "poll/question/ans",
        "textInputTitle": false,
        "textAreaTitle": "Product Description",
        "textInputTitlePlaceholder": "ex. Creatosaurus",
        "textAreaTitlePlaceholder": "ex. Transform Your Marketing Strategy with 10x Faster Storytelling. Creatosaurus lets you easily curate ideas, design graphics, write AI content, edit videos, schedule posts, search hashtags, craft articles, manage analytics, generate reports, apps & more—in one place. Try for free.",
        "textAreaCharacterLimit": 400
    },
    {
        "title": "PPPP Copywriting Formula",
        "subtitle": "Making you a better writer. Try Picture/Problem, Promise, Proof, Pitch!",
        "image": "content",
        "url": "pppp",
        "textInputTitle": false,
        "textAreaTitle": "Product Description",
        "textInputTitlePlaceholder": "ex. Creatosaurus",
        "textAreaTitlePlaceholder": "ex. Transform Your Marketing Strategy with 10x Faster Storytelling. Creatosaurus lets you easily curate ideas, design graphics, write AI content, edit videos, schedule posts, search hashtags, craft articles, manage analytics, generate reports, apps & more—in one place. Try for free.",
        "textAreaCharacterLimit": 400
    },
    {
        "title": "Press Release",
        "subtitle": "Writes a press release for your occasion",
        "image": "content",
        "url": "tagline/press/release",
        "textInputTitle": "Product Name",
        "textAreaTitle": "Occassion Description",
        "textInputTitlePlaceholder": "ex. Creatosaurus",
        "textAreaTitlePlaceholder": "ex. Creatosaurus product launch to transform Your Marketing Strategy with 10x Faster Storytelling. Creatosaurus lets you easily curate ideas, design graphics, write AI content, edit videos, schedule posts, search hashtags, craft articles, manage analytics, generate reports, apps & more—in one place. Try for free.",
        "textAreaCharacterLimit": 400
    },
    {
        "title": "Product Benefit Statements",
        "subtitle": "Writes a benefit header and paragraph",
        "image": "content",
        "url": "product/benefit",
        "textInputTitle": "Product Feature",
        "textAreaTitle": "Product Description",
        "textInputTitlePlaceholder": "ex. Creatosaurus",
        "textAreaTitlePlaceholder": "ex. Transform Your Marketing Strategy with 10x Faster Storytelling. Creatosaurus lets you easily curate ideas, design graphics, write AI content, edit videos, schedule posts, search hashtags, craft articles, manage analytics, generate reports, apps & more—in one place. Try for free.",
        "textAreaCharacterLimit": 400
    },
    {
        "title": "Product Descriptions",
        "subtitle": "Product description to grow your brand",
        "image": "content",
        "url": "product/description",
        "textInputTitle": "Product Name",
        "textAreaTitle": "Product Description",
        "textInputTitlePlaceholder": "ex. Creatosaurus",
        "textAreaTitlePlaceholder": "ex. Transform Your Marketing Strategy with 10x Faster Storytelling. Creatosaurus lets you easily curate ideas, design graphics, write AI content, edit videos, schedule posts, search hashtags, craft articles, manage analytics, generate reports, apps & more—in one place. Try for free.",
        "textAreaCharacterLimit": 400
    },
    {
        "title": "Product Review",
        "subtitle": "Get creative reviews for your product or brand instantly",
        "image": "content",
        "url": "product/review",
        "textInputTitle": "Product Name",
        "textAreaTitle": "Product Description",
        "textInputTitlePlaceholder": "ex. Creatosaurus",
        "textAreaTitlePlaceholder": "ex. Transform Your Marketing Strategy with 10x Faster Storytelling. Creatosaurus lets you easily curate ideas, design graphics, write AI content, edit videos, schedule posts, search hashtags, craft articles, manage analytics, generate reports, apps & more—in one place. Try for free.",
        "textAreaCharacterLimit": 400
    },
    {
        "title": "Product Story",
        "subtitle": "Writes a compelling backstory for your product",
        "image": "content",
        "url": "product/story",
        "textInputTitle": "Product Name",
        "textAreaTitle": "Product Description",
        "textInputTitlePlaceholder": "ex. Creatosaurus",
        "textAreaTitlePlaceholder": "ex. Transform Your Marketing Strategy with 10x Faster Storytelling. Creatosaurus lets you easily curate ideas, design graphics, write AI content, edit videos, schedule posts, search hashtags, craft articles, manage analytics, generate reports, apps & more—in one place. Try for free.",
        "textAreaCharacterLimit": 400
    },
    {
        "title": "Pros & Cons",
        "subtitle": "Suggests pros and cons for anything",
        "image": "content",
        "url": "product/pros/cons",
        "textInputTitle": false,
        "textAreaTitle": "Content Description",
        "textInputTitlePlaceholder": "ex. Creatosaurus",
        "textAreaTitlePlaceholder": "ex. Creatosaurus helps marketing teams to scale their storytelling in half the time.",
        "textAreaCharacterLimit": 400
    },
    {
        "title": "QUEST Copywriting Formula",
        "subtitle": "Making you a better writer. Try Qualify, Understand, Educate, Stimulate, Transition!",
        "image": "content",
        "url": "advertisement/quest/copywriting/formula",
        "textInputTitle": false,
        "textAreaTitle": "Product Description",
        "textInputTitlePlaceholder": "ex. Creatosaurus",
        "textAreaTitlePlaceholder": "ex. Transform Your Marketing Strategy with 10x Faster Storytelling. Creatosaurus lets you easily curate ideas, design graphics, write AI content, edit videos, schedule posts, search hashtags, craft articles, manage analytics, generate reports, apps & more—in one place. Try for free.",
        "textAreaCharacterLimit": 400
    },
    {
        "title": "Question Generator",
        "subtitle": "Generates questions that your users may have",
        "image": "content",
        "url": "advertisement/question/generator",
        "textInputTitle": false,
        "textAreaTitle": "Product Description",
        "textInputTitlePlaceholder": "ex. Creatosaurus",
        "textAreaTitlePlaceholder": "ex. Transform Your Marketing Strategy with 10x Faster Storytelling. Creatosaurus lets you easily curate ideas, design graphics, write AI content, edit videos, schedule posts, search hashtags, craft articles, manage analytics, generate reports, apps & more—in one place. Try for free.",
        "textAreaCharacterLimit": 400
    },
    {
        "title": "Quora/Short Answers",
        "subtitle": "Comprehensive responses to your questions.",
        "image": "blog",
        "url": "blog/question",
        "textInputTitle": false,
        "textAreaTitle": "Question Description",
        "textInputTitlePlaceholder": "ex. Creatosaurus",
        "textAreaTitlePlaceholder": "ex. How does Creatosaurus helps marketing teams to scale their storytelling in half the time?",
        "textAreaCharacterLimit": 400
    },
    {
        "title": "Quotable Quote",
        "subtitle": "Generate quoteworthy statements for your product",
        "image": "content",
        "url": "product/quotable/quote",
        "textInputTitle": false,
        "textAreaTitle": "Product Description",
        "textInputTitlePlaceholder": "ex. Creatosaurus",
        "textAreaTitlePlaceholder": "ex. Transform Your Marketing Strategy with 10x Faster Storytelling. Creatosaurus lets you easily curate ideas, design graphics, write AI content, edit videos, schedule posts, search hashtags, craft articles, manage analytics, generate reports, apps & more—in one place. Try for free.",
        "textAreaCharacterLimit": 400
    },
    {
        "title": "Relatable Experiences",
        "subtitle": "Choose an experience they can easily connect with.",
        "image": "content",
        "url": "advertisement/relatable/experiences",
        "textInputTitle": false,
        "textAreaTitle": "Product Description",
        "textInputTitlePlaceholder": "ex. Creatosaurus",
        "textAreaTitlePlaceholder": "ex. Transform Your Marketing Strategy with 10x Faster Storytelling. Creatosaurus lets you easily curate ideas, design graphics, write AI content, edit videos, schedule posts, search hashtags, craft articles, manage analytics, generate reports, apps & more—in one place. Try for free.",
        "textAreaCharacterLimit": 400
    },
    {
        "title": "Rewrite with Keywords",
        "subtitle": "Rewrites your content with keywords",
        "image": "content",
        "url": "product/rewrite/keyword",
        "textInputTitle": "Keywords",
        "textAreaTitle": "Content Description",
        "textInputTitlePlaceholder": "ex. marketing strategy, all in one",
        "textAreaTitlePlaceholder": "ex. Creatosaurus lets you easily curate ideas, design graphics, write AI content, edit videos, schedule posts, search hashtags, craft articles, manage analytics, generate reports, apps & more—in one place. Try for free.",
        "textAreaCharacterLimit": 400
    },
    {
        "title": "Sales Page Call-to-action",
        "subtitle": "Writes sales page CTA",
        "image": "content",
        "url": "product/sales/page/call/to/action",
        "textInputTitle": "Product Name",
        "textAreaTitle": "Product Description",
        "textInputTitlePlaceholder": "ex. Creatosaurus",
        "textAreaTitlePlaceholder": "ex. Creatosaurus helps marketing teams to scale their storytelling in half the time.",
        "textAreaCharacterLimit": 400
    },
    {
        "title": "Sales Page Guarantee",
        "subtitle": "Writes a guarantee statement for your product",
        "image": "content",
        "url": "product/sales/page/guarantee",
        "textInputTitle": "Product Name",
        "textAreaTitle": "Product Description",
        "textInputTitlePlaceholder": "ex. Creatosaurus",
        "textAreaTitlePlaceholder": "ex. Creatosaurus helps marketing teams to scale their storytelling in half the time.",
        "textAreaCharacterLimit": 400
    },
    {
        "title": "Sales Page Headlines",
        "subtitle": "Writes sales page headlines & subheadings",
        "image": "content",
        "url": "product/sales/page/headline",
        "textInputTitle": "Product Name",
        "textAreaTitle": "Product Description",
        "textInputTitlePlaceholder": "ex. Creatosaurus",
        "textAreaTitlePlaceholder": "ex. Creatosaurus helps marketing teams to scale their storytelling in half the time.",
        "textAreaCharacterLimit": 400
    },
    {
        "title": "Sales Page Opener",
        "subtitle": "Writes sales page opening paragraph",
        "image": "content",
        "url": "product/sales/page/opener",
        "textInputTitle": "Product Name",
        "textAreaTitle": "Product Description",
        "textInputTitlePlaceholder": "ex. Creatosaurus",
        "textAreaTitlePlaceholder": "ex. Creatosaurus helps marketing teams to scale their storytelling in half the time.",
        "textAreaCharacterLimit": 400
    },
    {
        "title": "Sentence Expander",
        "subtitle": "Write more content with sentence expander",
        "image": "content",
        "url": "content/write/more/sentence",
        "textInputTitle": false,
        "textAreaTitle": "Sentence",
        "textInputTitlePlaceholder": "ex. Creatosaurus",
        "textAreaTitlePlaceholder": "ex. Creatosaurus helps marketing teams to scale their storytelling in half the time.",
        "textAreaCharacterLimit": 400
    },
    {
        "title": "Sentence Rewriter",
        "subtitle": "Magically rewrites your sentence",
        "image": "content",
        "url": "sentence/rewriter",
        "textInputTitle": false,
        "textAreaTitle": "Sentence",
        "textInputTitlePlaceholder": "ex. Creatosaurus",
        "textAreaTitlePlaceholder": "ex. Creatosaurus helps marketing teams to scale their storytelling in half the time.",
        "textAreaCharacterLimit": 400
    },
    {
        "title": "SEO Keyword Generator",
        "subtitle": "Suggests keywords for your content to rank",
        "image": "content",
        "url": "keyword/extractor",
        "textInputTitle": false,
        "textAreaTitle": "Content",
        "textInputTitlePlaceholder": "ex. Creatosaurus",
        "textAreaTitlePlaceholder": "ex. Creatosaurus helps marketing teams to scale their storytelling in half the time.",
        "textAreaCharacterLimit": 400
    },
    {
        "title": "Similar Social Media Post",
        "subtitle": "Write a similar style social media post for your brand",
        "image": "content",
        "url": "advertisement/similar/social/media/post",
        "textInputTitle": "Reference Post",
        "textAreaTitle": "Your Product Description",
        "textInputTitlePlaceholder": "ex. Fun and Sunny day with XYZ ABC Media Team",
        "textAreaTitlePlaceholder": "ex. Transform Your Marketing Strategy with 10x Faster Storytelling. Creatosaurus lets you easily curate ideas, design graphics, write AI content, edit videos, schedule posts, search hashtags, craft articles, manage analytics, generate reports, apps & more—in one place. Try for free.",
        "textAreaCharacterLimit": 400
    },
    {
        "title": "Simplify Sentences",
        "subtitle": "Simplifies the sentences in simple words",
        "image": "content",
        "url": "simplify/sentence",
        "textInputTitle": false,
        "textAreaTitle": "Content Description",
        "textInputTitlePlaceholder": "ex. Creatosaurus",
        "textAreaTitlePlaceholder": "ex. Transform Your Marketing Strategy with 10x Faster Storytelling. Creatosaurus lets you easily curate ideas, design graphics, write AI content, edit videos, schedule posts, search hashtags, craft articles, manage analytics, generate reports, apps & more—in one place. Try for free.",
        "textAreaCharacterLimit": 400
    },
    {
        "title": "Social Media Bio",
        "subtitle": "Supercharge your social media with amazing bio",
        "image": "content",
        "url": "google/bio",
        "textInputTitle": "Product Name",
        "textAreaTitle": "Account Description",
        "textInputTitlePlaceholder": "ex. Creatosaurus",
        "textAreaTitlePlaceholder": "ex. Transform Your Marketing Strategy with 10x Faster Storytelling. Creatosaurus lets you easily curate ideas, design graphics, write AI content, edit videos, schedule posts, search hashtags, craft articles, manage analytics, generate reports, apps & more—in one place. Try for free.",
        "textAreaCharacterLimit": 400
    },
    {
        "title": "Social Media Post Captions",
        "subtitle": "The best captions for your next social media post",
        "image": "social-media",
        "url": "caption",
        "textInputTitle": false,
        "textAreaTitle": "Post Description",
        "textInputTitlePlaceholder": "ex. Creatosaurus",
        "textAreaTitlePlaceholder": "ex. Transform Your Marketing Strategy with 10x Faster Storytelling. Creatosaurus lets you easily curate ideas, design graphics, write AI content, edit videos, schedule posts, search hashtags, craft articles, manage analytics, generate reports, apps & more—in one place. Try for free.",
        "textAreaCharacterLimit": 400
    },
    {
        "title": "Social Media Post Generator",
        "subtitle": "Generate social media post on the go",
        "image": "content",
        "url": "startup/post/generator",
        "textInputTitle": "Product Name",
        "textAreaTitle": "Post Description",
        "textInputTitlePlaceholder": "ex. Creatosaurus",
        "textAreaTitlePlaceholder": "ex. Transform Your Marketing Strategy with 10x Faster Storytelling.",
        "textAreaCharacterLimit": 400
    },
    {
        "title": "Social Media Post Ideas",
        "subtitle": "Engage with your passionate followers",
        "image": "social-media",
        "url": "short/social/post/idea",
        "textInputTitle": false,
        "textAreaTitle": "Product Description",
        "textInputTitlePlaceholder": "ex. Creatosaurus",
        "textAreaTitlePlaceholder": "ex. Transform Your Marketing Strategy with 10x Faster Storytelling. Creatosaurus lets you easily curate ideas, design graphics, write AI content, edit videos, schedule posts, search hashtags, craft articles, manage analytics, generate reports, apps & more—in one place. Try for free.",
        "textAreaCharacterLimit": 400
    },
    {
        "title": "Social Media Post Reply",
        "subtitle": "Reply social media post from your brand perspective",
        "image": "content",
        "url": "advertisement/post/reply",
        "textInputTitle": "Reference Post",
        "textAreaTitle": "Your Product Description",
        "textInputTitlePlaceholder": "ex. Fun and Sunny day with XYZ ABC Media Team",
        "textAreaTitlePlaceholder": "ex. Transform Your Marketing Strategy with 10x Faster Storytelling. Creatosaurus lets you easily curate ideas, design graphics, write AI content, edit videos, schedule posts, search hashtags, craft articles, manage analytics, generate reports, apps & more—in one place. Try for free.",
        "textAreaCharacterLimit": 400
    },
    {
        "title": "Social Proof",
        "subtitle": "Writes social proof for your website",
        "image": "content",
        "url": "advertisement/social/proof",
        "textInputTitle": false,
        "textAreaTitle": "Product Description",
        "textInputTitlePlaceholder": "ex. Creatosaurus",
        "textAreaTitlePlaceholder": "ex. Transform Your Marketing Strategy with 10x Faster Storytelling. Creatosaurus lets you easily curate ideas, design graphics, write AI content, edit videos, schedule posts, search hashtags, craft articles, manage analytics, generate reports, apps & more—in one place. Try for free.",
        "textAreaCharacterLimit": 400
    },
    {
        "title": "Song Lyrics",
        "subtitle": "Writes a song for your product or brand",
        "image": "content",
        "url": "product/song/lyrics",
        "textInputTitle": "Product Name",
        "textAreaTitle": "Product Description",
        "textInputTitlePlaceholder": "ex. Creatosaurus",
        "textAreaTitlePlaceholder": "ex. Transform Your Marketing Strategy with 10x Faster Storytelling. Creatosaurus lets you easily curate ideas, design graphics, write AI content, edit videos, schedule posts, search hashtags, craft articles, manage analytics, generate reports, apps & more—in one place. Try for free.",
        "textAreaCharacterLimit": 400
    },
    {
        "title": "Startup Ideas",
        "subtitle": "Recommends idea for your next startup.",
        "image": "content",
        "url": "startup/idea",
        "textInputTitle": false,
        "textAreaTitle": "Describe your Passion",
        "textInputTitlePlaceholder": "ex. Creatosaurus",
        "textAreaTitlePlaceholder": "ex. All in one creative and marketing platform.",
        "textAreaCharacterLimit": 400
    },
    {
        "title": "Startup Slogan",
        "subtitle": "Let the startup slogan be your guide",
        "image": "content",
        "url": "startup/slogan",
        "textInputTitle": "Product Name",
        "textAreaTitle": "Product Description",
        "textInputTitlePlaceholder": "ex. Creatosaurus",
        "textAreaTitlePlaceholder": "ex. Transform Your Marketing Strategy with 10x Faster Storytelling. Creatosaurus lets you easily curate ideas, design graphics, write AI content, edit videos, schedule posts, search hashtags, craft articles, manage analytics, generate reports, apps & more—in one place. Try for free.",
        "textAreaCharacterLimit": 400
    },
    {
        "title": "Startup Value Proposition",
        "subtitle": "Share your unique value proposition",
        "image": "content",
        "url": "startup/value/proposition",
        "textInputTitle": "Product Name",
        "textAreaTitle": "Product Description",
        "textInputTitlePlaceholder": "ex. Creatosaurus",
        "textAreaTitlePlaceholder": "ex. Transform Your Marketing Strategy with 10x Faster Storytelling. Creatosaurus lets you easily curate ideas, design graphics, write AI content, edit videos, schedule posts, search hashtags, craft articles, manage analytics, generate reports, apps & more—in one place. Try for free.",
        "textAreaCharacterLimit": 400
    },
    {
        "title": "Story Plot",
        "subtitle": "Generate a detailed plot outline out of story ideas.",
        "image": "content",
        "url": "story/plot",
        "textInputTitle": false,
        "textAreaTitle": "Story Brief Description",
        "textInputTitlePlaceholder": "ex. Creatosaurus",
        "textAreaTitlePlaceholder": "eg. Creatosaurus helps marketing teams to scale their storytelling in half the time.",
        "textAreaCharacterLimit": 400
    },
    {
        "title": "Tagline",
        "subtitle": "Make your product or brand stand out with a tagline",
        "image": "content",
        "url": "tagline",
        "textInputTitle": "Product Name",
        "textAreaTitle": "Product Description",
        "textInputTitlePlaceholder": "ex. Creatosaurus",
        "textAreaTitlePlaceholder": "ex. Transform Your Marketing Strategy with 10x Faster Storytelling. Creatosaurus lets you easily curate ideas, design graphics, write AI content, edit videos, schedule posts, search hashtags, craft articles, manage analytics, generate reports, apps & more—in one place. Try for free.",
        "textAreaCharacterLimit": 400
    },
    {
        "title": "Testimonial Rewrite",
        "subtitle": "Rewrite the testimonial to be more engaging",
        "image": "content",
        "url": "advertisement/testimonial/rewriter",
        "textInputTitle": false,
        "textAreaTitle": "Testimonial",
        "textInputTitlePlaceholder": "ex. Creatosaurus",
        "textAreaTitlePlaceholder": "ex. Few of the guys from my creative team are using your product “Creatosaurus” and they are loving it and I'm impressed by the bump up in their productivity.",
        "textAreaCharacterLimit": 400
    },
    {
        "title": "Thank You Note",
        "subtitle": "Show gratitude to your users and customers",
        "image": "content",
        "url": "thank/you/note",
        "textInputTitle": "Product Name",
        "textAreaTitle": "Product Description",
        "textInputTitlePlaceholder": "ex. Creatosaurus",
        "textAreaTitlePlaceholder": "ex. Creatosaurus helps marketing teams to scale their storytelling in half the time.",
        "textAreaCharacterLimit": 400
    },
    {
        "title": "TL;DR summarization",
        "subtitle": "Provides a brief summary of your content",
        "image": "content",
        "url": "summerization/tl/dr",
        "textInputTitle": false,
        "textAreaTitle": "Content",
        "textInputTitlePlaceholder": "ex. Creatosaurus",
        "textAreaTitlePlaceholder": "ex. Transform Your Marketing Strategy with 10x Faster Storytelling. Creatosaurus lets you easily curate ideas, design graphics, write AI content, edit videos, schedule posts, search hashtags, craft articles, manage analytics, generate reports, apps & more—in one place. Try for free.",
        "textAreaCharacterLimit": 400
    },
    {
        "title": "Tone Changer",
        "subtitle": "Changes the tone of your content",
        "image": "content",
        "url": "tone/changer",
        "textInputTitle": false,
        "textAreaTitle": "Content",
        "textInputTitlePlaceholder": "ex. Creatosaurus",
        "textAreaTitlePlaceholder": "ex. Creatosaurus helps marketing teams to scale their storytelling in half the time.",
        "textAreaCharacterLimit": 400
    },
    {
        "title": "Two Sentence Story",
        "subtitle": "Interesting stories in 2 lines",
        "image": "content",
        "url": "story/two/sentence",
        "textInputTitle": false,
        "textAreaTitle": "Topic Description",
        "textInputTitlePlaceholder": "ex. Creatosaurus",
        "textAreaTitlePlaceholder": "ex. Creatosaurus helps marketing teams to scale their storytelling in half the time.",
        "textAreaCharacterLimit": 400
    },
    {
        "title": "Verb Booster",
        "subtitle": "Find stronger alternative for the given verb",
        "image": "content",
        "url": "verb/booster",
        "textInputTitle": false,
        "textAreaTitle": "Describe Your Verb",
        "textInputTitlePlaceholder": "ex. Creatosaurus",
        "textAreaTitlePlaceholder": "ex. Work",
        "textAreaCharacterLimit": 400
    },
    {
        "title": "Viral Ideas",
        "subtitle": "Discuss viral growth ideas for your product",
        "image": "content",
        "url": "product/viral/ideas",
        "textInputTitle": false,
        "textAreaTitle": "Product Description",
        "textInputTitlePlaceholder": "ex. Creatosaurus",
        "textAreaTitlePlaceholder": "ex. Transform Your Marketing Strategy with 10x Faster Storytelling. Creatosaurus lets you easily curate ideas, design graphics, write AI content, edit videos, schedule posts, search hashtags, craft articles, manage analytics, generate reports, apps & more—in one place. Try for free.t",
        "textAreaCharacterLimit": 400
    },
    {
        "title": "Vision & Mission",
        "subtitle": "Share your startup vision and mission",
        "image": "content",
        "url": "startup/vision/mission",
        "textInputTitle": "Product Name",
        "textAreaTitle": "Product Description",
        "textInputTitlePlaceholder": "ex. Creatosaurus",
        "textAreaTitlePlaceholder": "ex. Transform Your Marketing Strategy with 10x Faster Storytelling. Creatosaurus lets you easily curate ideas, design graphics, write AI content, edit videos, schedule posts, search hashtags, craft articles, manage analytics, generate reports, apps & more—in one place. Try for free.",
        "textAreaCharacterLimit": 400
    },
    {
        "title": "Website About Us",
        "subtitle": "Don't procrastinate, we will figure out about us for you",
        "image": "content",
        "url": "website/about/us",
        "textInputTitle": false,
        "textAreaTitle": "Website Description",
        "textInputTitlePlaceholder": "ex. Creatosaurus",
        "textAreaTitlePlaceholder": "ex. Transform Your Marketing Strategy with 10x Faster Storytelling. Creatosaurus lets you easily curate ideas, design graphics, write AI content, edit videos, schedule posts, search hashtags, craft articles, manage analytics, generate reports, apps & more—in one place. Try for free.",
        "textAreaCharacterLimit": 400
    },
    {
        "title": "Website Call To Action",
        "subtitle": "Writes website landing page CTA",
        "image": "content",
        "url": "advertisement/website/call/to/action",
        "textInputTitle": "Product Name",
        "textAreaTitle": "Product Description",
        "textInputTitlePlaceholder": "ex. Creatosaurus",
        "textAreaTitlePlaceholder": "ex. Transform Your Marketing Strategy with 10x Faster Storytelling. Creatosaurus lets you easily curate ideas, design graphics, write AI content, edit videos, schedule posts, search hashtags, craft articles, manage analytics, generate reports, apps & more—in one place. Try for free.",
        "textAreaCharacterLimit": 400
    },
    {
        "title": "Welcome Email",
        "subtitle": "Send amazing welcome email to your audience",
        "image": "content",
        "url": "email/welcome",
        "textInputTitle": "Product Name",
        "textAreaTitle": "Product Description",
        "textInputTitlePlaceholder": "ex. Creatosaurus",
        "textAreaTitlePlaceholder": "ex. Transform Your Marketing Strategy with 10x Faster Storytelling. Creatosaurus lets you easily curate ideas, design graphics, write AI content, edit videos, schedule posts, search hashtags, craft articles, manage analytics, generate reports, apps & more—in one place. Try for free.",
        "textAreaCharacterLimit": 400
    },
    {
        "title": "YouTube Video Description",
        "subtitle": "YouTube description for your content to rank",
        "image": "youtube",
        "url": "youtube/description",
        "textInputTitle": "Product Name",
        "textAreaTitle": "YouTube Video Description",
        "textInputTitlePlaceholder": "ex. Creatosaurus",
        "textAreaTitlePlaceholder": "ex. 10 ways to scale marketing strategy with Creatosaurus",
        "textAreaCharacterLimit": 400
    },
    {
        "title": "YouTube Video Ideas",
        "subtitle": "Ideas your audience will love and rank",
        "image": "youtube",
        "url": "youtube/idea",
        "textInputTitle": false,
        "textAreaTitle": "Product Description",
        "textInputTitlePlaceholder": "ex. Creatosaurus",
        "textAreaTitlePlaceholder": "ex. Transform Your Marketing Strategy with 10x Faster Storytelling. Creatosaurus lets you easily curate ideas, design graphics, write AI content, edit videos, schedule posts, search hashtags, craft articles, manage analytics, generate reports, apps & more—in one place. Try for free.",
        "textAreaCharacterLimit": 400
    },
    {
        "title": "YouTube Video Outline",
        "subtitle": "YouTube video outline for your content to rank",
        "image": "youtube",
        "url": "youtube/outline",
        "textInputTitle": false,
        "textAreaTitle": "YouTube Video Description",
        "textInputTitlePlaceholder": "ex. Creatosaurus",
        "textAreaTitlePlaceholder": "ex. 10 ways to scale marketing strategy with Creatosaurus",
        "textAreaCharacterLimit": 400
    },
    {
        "title": "YouTube Video Titles",
        "subtitle": "Title suggestion for your next YouTube video",
        "image": "youtube",
        "url": "youtube/title",
        "textInputTitle": false,
        "textAreaTitle": "YouTube Video Description",
        "textInputTitlePlaceholder": "ex. Creatosaurus",
        "textAreaTitlePlaceholder": "ex. List of 10 ways to scale marketing strategy with Creatosaurus including bonus content and offers",
        "textAreaCharacterLimit": 400
    },
    {
        "title": "Funny New Year Resolution Ideas",
        "subtitle": "Quirky new year resolutions tailored to your passion ",
        "image": "content",
        "url": "tagline/funny/new/year/resolution",
        "textInputTitle": false,
        "textAreaTitle": "You are passionate about",
        "textInputTitlePlaceholder": "ex. Creatosaurus",
        "textAreaTitlePlaceholder": "ex. Food, travel, cards, etc",
        "textAreaCharacterLimit": 400
    }
]


captionTypes.sort((a, b) => a.title.localeCompare(b.title))
export default captionTypes