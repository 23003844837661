import React, { useState, useContext, useEffect } from 'react';
import AllApps from '../all_apps/AllApps';
import Captionator from '../captionator/Captionator';
import Hashtag from '../hashtag/Hashtag';
import Muse from '../muse/Muse';
import Quotes from '../quotes/Quotes';
import Unsplash from '../unsplash/Unsplash';
import Pexels from "../pexels/Pexels";
import Pixabay from "../pixabay/Pixabay";
import Giphy from '../giphy/Giphy';
import Noice from "../noice/Noice";
import Tenor from '../tenor/Tenor';
import Uploads from "../uploads/Uploads";
import AppDetails from '../app_detail/AppDetail';
import TwitterTrends from '../twitter_trends/TwitterTrends';
import QrCode from '../qr_code/QrCode';
import Clearbit from '../clearbit/Clearbit';
import GoogleDrive from '../google_drive/GoogleDrive';
import GooglePhotos from '../google_photos/GooglePhotos';
import Slack from '../slack/Slack';
import DropboxComponent from "../dropbox/Dropbox";
import ButtonProvider from "../../store/ButtonProvider";
import Bitmoji from '../bitmoji/Bitmoji';
import Steno from '../steno/Steno';
import KeywordSearch from '../keyword_search/KeywordSearch';
import OnPageSeo from '../on_page_seo/OnPageSeo';

const AppContainer = () => {
    const [activeApp, setactiveApp] = useState("allApps");
    const [appInfo, setAppInfo] = useState(null);
    const buttonContext = useContext(ButtonProvider);

    useEffect(() => {
        changeActiveApp(buttonContext.activeAppStoreApp);
    }, [buttonContext.activeAppStoreApp]); // eslint-disable-line react-hooks/exhaustive-deps

    const changeActiveApp = (value, appInfo) => {
        const validApps = [
            "allApps", "Captionator", "#Tags", "Quotes", "Muse",
            "Unsplash", "Pexels", "Pixabay", "Giphy",
            "Noice", "Tenor", "Uploads", "AppDetails", "Twitter Trends",
            "QR Code", "Clearbit", "Google Drive", "Google Photos", "Dropbox",
            "Slack Team", "Bitmoji", "Steno", "Keyword Search", "On Page Seo"
        ];

        if (validApps.includes(value)) {
            setactiveApp(value);
            setAppInfo(appInfo);
            buttonContext.activateAppStoreApp(value);
        }
    };

    return (
        <div>
            {activeApp === "allApps" ? <AllApps changeActiveApp={changeActiveApp} /> : null}
            {activeApp === "Steno" ? <Steno changeActiveApp={changeActiveApp} /> : null}
            {activeApp === "Captionator" ? <Captionator changeActiveApp={changeActiveApp} /> : null}
            {activeApp === "#Tags" ? <Hashtag changeActiveApp={changeActiveApp} /> : null}
            {activeApp === "Quotes" ? <Quotes changeActiveApp={changeActiveApp} /> : null}
            {activeApp === "Muse" ? <Muse changeActiveApp={changeActiveApp} /> : null}
            {activeApp === "Unsplash" ? <Unsplash changeActiveApp={changeActiveApp} /> : null}
            {activeApp === "Pexels" ? <Pexels changeActiveApp={changeActiveApp} /> : null}
            {activeApp === "Pixabay" ? <Pixabay changeActiveApp={changeActiveApp} /> : null}
            {activeApp === "Giphy" ? <Giphy changeActiveApp={changeActiveApp} /> : null}
            {activeApp === "Noice" ? <Noice changeActiveApp={changeActiveApp} /> : null}
            {activeApp === "Tenor" ? <Tenor changeActiveApp={changeActiveApp} /> : null}
            {activeApp === "Uploads" ? <Uploads changeActiveApp={changeActiveApp} /> : null}
            {activeApp === "Twitter Trends" ? <TwitterTrends changeActiveApp={changeActiveApp} /> : null}
            {activeApp === "QR Code" ? <QrCode changeActiveApp={changeActiveApp} /> : null}
            {activeApp === "Clearbit" ? <Clearbit changeActiveApp={changeActiveApp} /> : null}
            {activeApp === "Google Drive" ? <GoogleDrive changeActiveApp={changeActiveApp} /> : null}
            {activeApp === "Google Photos" ? <GooglePhotos changeActiveApp={changeActiveApp} /> : null}
            {activeApp === "Dropbox" ? <DropboxComponent changeActiveApp={changeActiveApp} /> : null}
            {activeApp === "Slack Team" ? <Slack changeActiveApp={changeActiveApp} /> : null}
            {activeApp === "Bitmoji" ? <Bitmoji changeActiveApp={changeActiveApp} /> : null}
            {activeApp === "Keyword Search" ? <KeywordSearch changeActiveApp={changeActiveApp} /> : null}
            {activeApp === "On Page Seo" ? <OnPageSeo changeActiveApp={changeActiveApp} /> : null}
            {activeApp === "AppDetails" ? <AppDetails goBack={() => {
                changeActiveApp(appInfo.title);
            }} data={appInfo} /> : null}

        </div>
    );
};

export default AppContainer;