import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Home from './screens/home/Home';
import ProtectedRoutes from './screens/protected_routes/ProtectedRoutes'
import EditorScreen from './screens/editor/EditorScreen';


const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<ProtectedRoutes><Home /></ProtectedRoutes>} />
        <Route path="/editor" element={<ProtectedRoutes><EditorScreen /></ProtectedRoutes>} />
        <Route path="/editor/post/:id" element={<ProtectedRoutes><EditorScreen /></ProtectedRoutes>} />
        {/* <Route path="/safari-not-supported" element={<NotSupported />} /> */}
      </Routes>
    </BrowserRouter>
  );
}

export default App;