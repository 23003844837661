import tenorLogo from "./assets/app_logo_right/tenor.svg";
import giphyLogo from "./assets/app_logo_right/giphy.svg";
import pixabayLogo from "./assets/app_logo_right/pixabay.svg";
import unsplashLogo from "./assets/app_logo_right/unsplash.svg";
import pexelsLogo from "./assets/app_logo_right/pexels.svg";
import captionatorLogo from "./assets/app_logo_right/captionator.svg";
import quotesLogo from "./assets/app_logo_right/quotes.svg";
import hashtagsLogo from "./assets/app_logo_right/hashtags.svg";
import noiceLogo from "./assets/app_logo_right/noice.svg";
import museLogo from "./assets/app_logo_right/muse.svg";
import uploadsLogo from "./assets/app_logo_right/uploads.svg";
import stenoLogo from "./assets/app_logo_right/steno.svg";
//import twitterLogo from "./assets/app_logo_right/twitter.png";
import driveLogo from "./assets/app_logo_right/drive.svg";
import photosLogo from "./assets/app_logo_right/photos.svg";
import dropboxLogo from "./assets/app_logo_right/dropbox.svg";
import clearbitLogo from "./assets/app_logo_right/clearbit.svg";
import qrcodeLogo from "./assets/app_logo_right/qrcode.svg";
//import slackLogo from "./assets/app_logo_right/slack.svg";
import bitmojiLogo from "./assets/app_logo_right/bitmoji.svg";
import keyword from "./assets/app_logo_right/keyword.svg"
import onpageseo from "./assets/app_logo_right/onpageseo.svg"
// import brandkitLogo from "./assets/app_logo_right/brandkit.svg";
// import openmojiLogo from "./assets/app_logo_right/Openmoji.svg";
// import twemojiLogo from "./assets/app_logo_right/Twemoji.svg";
// import teamsLogo from "./assets/app_logo_right/teams.svg";


const apps = [
    {
        title: 'Keyword Search',
        desc: 'Effortlessly discover the best SEO keywords with Keyword Search.',
        longDesc: 'Unlock your content’s potential with Keyword Search. Discover and analyze high-impact SEO keywords to boost your rankings. Perfect for marketers, bloggers, and SEO enthusiasts, Keyword Search helps you drive more traffic and stay ahead in the digital landscape. Optimize your strategy today!',
        sideBar: 'keywordsearch',
        color: '#00F0FF',
        logo: keyword,
        url: "https://www.bitmoji.com/",
        privacy: "https://snap.com/en-US/privacy/privacy-policy",
        terms: 'https://snap.com/en-US/terms',
    },
    {
        title: 'On Page Seo',
        desc: "Analyze and refine On-Page SEO",
        longDesc: "Optimize your website with our On-Page SEO Checker. Analyze keywords, meta tags, and content to boost your site's ranking.",
        sideBar: 'onpageseo',
        color: '#00F0FF',
        logo: onpageseo,
        url: "https://www.bitmoji.com/",
        privacy: "https://snap.com/en-US/privacy/privacy-policy",
        terms: 'https://snap.com/en-US/terms',
    },
    /*{
        title: 'Bitmoji',
        desc: 'Your own personal emoji, cartoon avatar',
        longDesc: 'Bitmoji is your own personal emoji. Create an expressive cartoon avatar, choose from a growing library of moods and stickers - featuring YOU! Put them into any text message, chat or status update.',
        sideBar: 'bitmoji',
        color: '#06BE6D',
        logo: bitmojiLogo,
        url: "https://www.bitmoji.com/",
        privacy: "https://snap.com/en-US/privacy/privacy-policy",
        terms: 'https://snap.com/en-US/terms',
    },*/
    // {
    //     title: 'Brand kit',
    //     desc: 'Coming soon...',
    //     longDesc: '',
    //     sideBar: 'brandkit',
    //     color: '#FF6543',
    //     logo: brandkitLogo,
    //     url: "https://www.creatosaurus.io/apps",
    //     privacy: "https://www.creatosaurus.io/privacy",
    //     terms: 'https://creatosaurus.io/terms'
    // },
    {
        title: 'Captionator',
        desc: 'AI content generator & writing assistant',
        longDesc: 'Generate content with AI writer. Captionator by Creatosaurus.',
        sideBar: 'captionater',
        color: '#00ECC2',
        logo: captionatorLogo,
        url: "https://www.captionator.creatosaurus.io/",
        privacy: "https://www.creatosaurus.io/privacy",
        terms: 'https://www.creatosaurus.io/terms'
    },
    {
        title: 'Clearbit',
        desc: 'Find Company Logos by Clearbit',
        longDesc: 'The Clearbit Data Activation Platform helps B2B teams understand customers, identify prospects, & personalize interactions with real-time intelligence.',
        sideBar: 'clearbit',
        color: '#273266',
        logo: clearbitLogo,
        privacy: "https://dashboard.clearbit.com/privacy",
        terms: 'https://dashboard.clearbit.com/privacy'
    },
    {
        title: 'Dropbox',
        desc: 'For your content and workflow',
        longDesc: 'Dropbox helps you simplify your workflow. So you can spend more time in your flow',
        sideBar: 'dropbox',
        color: '#007EE5',
        logo: dropboxLogo,
        url: "https://www.dropbox.com/",
        privacy: "https://www.dropbox.com/privacy",
        terms: 'https://www.dropbox.com/terms'
    },
    // {
    //     title: 'Flaticons',
    //     desc: 'Coming soon...',
    //     longDesc: 'Download Free Icons and Stickers for your projects. Resources made by and for designers. PNG, SVG, EPS, PSD and CSS formats',
    //     sideBar: 'flaticons',
    //     color: '#4AD295',
    //     //logo: ''
    //     url: "https://www.flaticon.com/",
    //     privacy: "https://www.freepikcompany.com/privacy",
    //     terms: 'https://support.flaticon.com/s/article/Personal-use-FI?language=en_US'
    // },
    // {
    //     title: 'Flickr',
    //     desc: 'Coming soon...',
    //     longDesc: 'The home for all your photos. Upload, access, organize, edit, and share your photos from any device, from anywhere in the world. Get 1,000GB of photo storage free.',
    //     sideBar: 'flickr',
    //     color: '#06BE6D',
    //     //logo: ''
    //     url: "https://www.flickr.com/",
    //     privacy: "https://www.flickr.com/help/privacy",
    //     terms: 'https://www.flickr.com/help/terms'
    // },
    {
        title: 'Google Drive',
        desc: 'Cloud Storage for Work and Home',
        longDesc: 'Learn about Google Drive’s file sharing platform that provides a personal, secure cloud storage option to share content with other users.',
        sideBar: 'googledrive',
        color: '#E8F0FE',
        logo: driveLogo,
        url: "https://www.google.com/intl/en_in/drive/",
        privacy: "https://policies.google.com/privacy",
        terms: 'https://support.google.com/drive/answer/2450387?hl=en'
    },
    {
        title: 'Google Photos',
        desc: 'Home for all your photos and videos',
        longDesc: 'Google Photos is the home for all your photos and videos, automatically organized and easy to share.',
        sideBar: 'googlephotos',
        color: '#E8F0FE',
        logo: photosLogo,
        url: "https://www.google.com/photos/about/",
        privacy: "https://policies.google.com/privacy",
        terms: 'https://policies.google.com/terms?hl=en-US'
    },
    {
        title: 'Giphy',
        desc: 'Best & newest GIFs & Animated Stickers',
        longDesc: 'GIPHY is your top source for the best & newest GIFs & Animated Stickers online. Find everything from funny GIFs, reaction GIFs, unique GIFs and more.',
        sideBar: 'giphygifs',
        color: '#000000',
        logo: giphyLogo,
        url: "https://giphy.com/",
        privacy: "https://support.giphy.com/hc/en-us/articles/360032872931-GIPHY-Privacy-Policy",
        terms: 'https://support.giphy.com/hc/en-us/articles/360020027752-GIPHY-User-Terms-of-Service'
    },
    {
        title: '#Tags',
        desc: 'Organise your hashtag groups in one place',
        longDesc: '',
        sideBar: 'hashtag',
        color: '#FF43CA',
        logo: hashtagsLogo,
        url: "https://www.hashtags.creatosaurus.io/",
        privacy: "https://www.creatosaurus.io/privacy",
        terms: 'https://creatosaurus.io/terms'
    },
    // {
    //     title: 'Iconscout',
    //     desc: 'Coming soon...',
    //     longDesc: 'Download 4.8 Million+ high-quality free vector Icons, Illustrations, 3D Assets, and Lottie Animations that are available in static & animated formats at IconScout',
    //     sideBar: 'iconscout',
    //     color: '#6563FF',
    //     //logo: '',
    //     url: "https://iconscout.com/",
    //     privacy: "https://iconscout.com/legal/privacy-policy",
    //     terms: 'https://iconscout.com/legal/terms-of-use'
    // },
    // {
    //     title: 'Microsoft Teams',
    //     desc: 'Coming Soon...',
    //     longDesc: 'Working together is easier with Microsoft Teams. Tools and files are always available in one place that designed to help you connect naturally, stay organized and bring ideas to life.',
    //     sideBar: 'muse',
    //     color: '#3E46B0',
    //     logo: teamsLogo,
    //     url: "https://www.muse.creatosaurus.io/",
    //     privacy: "https://docs.microsoft.com/en-us/microsoftteams/teams-privacy",
    //     terms: 'https://www.microsoft.com/en-us/legal/terms-of-use'
    // },
    {
        title: 'Muse',
        desc: 'An easy to use pro graphic design editor',
        longDesc: 'Design beautiful graphics with templates. Muse by Creatosaurus.',
        sideBar: 'muse',
        color: '#FF8A25',
        logo: museLogo,
        url: "https://www.muse.creatosaurus.io/",
        privacy: "https://www.creatosaurus.io/privacy",
        terms: 'https://creatosaurus.io/terms'
    },
    {
        title: 'Noice',
        desc: 'Free asset library that will make your say noice',
        longDesc: '',
        sideBar: 'noice',
        color: '#009B77',
        logo: noiceLogo,
        url: "https://www.noice.creatosaurus.io/",
        privacy: "https://www.creatosaurus.io/privacy",
        terms: 'https://creatosaurus.io/terms'
    },
    // {
    //     title: 'Openmoji',
    //     desc: 'Coming soon...',
    //     longDesc: 'Open source emojis for designers, developers and everyone else!',
    //     sideBar: 'Openmoji',
    //     color: '#3D98BB',
    //     logo: openmojiLogo,
    //     url: "https://unsplash.com/",
    //     privacy: "https://openmoji.org/",
    //     terms: 'https://www.pexels.com/terms-of-service/'
    // },
    {
        title: 'Pexels',
        desc: 'The best free stock photos and videos',
        longDesc: 'Free stock photos & videos you can use everywhere. Browse millions of high-quality royalty free stock images & copyright free pictures. No attribution required.',
        sideBar: 'pexels',
        color: '#05A081',
        logo: pexelsLogo,
        url: "https://www.pexels.com/",
        privacy: "https://www.pexels.com/privacy-policy/",
        terms: 'https://www.pexels.com/terms-of-service/'
    },
    {
        title: 'Pixabay',
        desc: 'Stunning free images & royalty free stock',
        longDesc: 'Find your perfect free image or video to download and use for anything. ✓ Free for commercial use ✓ No attribution required ✓ High quality images.',
        sideBar: 'pixabay',
        color: '#48A947',
        logo: pixabayLogo,
        url: "https://pixabay.com/",
        privacy: "https://pixabay.com/service/privacy/",
        terms: 'https://pixabay.com/service/terms/'
    },
    {
        title: 'Quotes',
        desc: 'Massive library of quotes & dialogue',
        longDesc: 'Search & save quotes, authors and more. Quotes by Creatosaurus.',
        sideBar: 'quotes',
        color: '#F3F802',
        logo: quotesLogo,
        url: "https://www.quotes.creatosaurus.io/",
        privacy: "https://www.creatosaurus.io/privacy",
        terms: 'https://creatosaurus.io/terms'
    },
    {
        title: 'QR Code',
        desc: 'Free customizable QR Code Generator',
        longDesc: '',
        sideBar: 'qrcode',
        color: '#333333',
        logo: qrcodeLogo,
        url: "https://www.creatosaurus.io/apps",
        privacy: "https://www.creatosaurus.io/privacy",
        terms: 'https://creatosaurus.io/terms'
    },
    /*{
        title: 'Slack Team',
        desc: 'Communicate faster and better.',
        longDesc: 'Slack is a new way to communicate with your team. It’s faster, better organised and more secure than email.',
        sideBar: 'slack',
        color: '#611F69',
        logo: slackLogo,
        url: "https://slack.com/",
        privacy: "https://slack.com/intl/en-in/trust/privacy/privacy-policy",
        terms: 'https://slack.com/intl/en-in/terms-of-service'
    },*/
    // {
    //     title: 'Twemoji',
    //     desc: 'Coming soon...',
    //     longDesc: 'Twitter’s open source emoji has you covered for all your projects emoji needs.With support for the latest Unicode emoji specification, featuring 2, 685 emojis, and all for free.',
    //     sideBar: 'Twemoji',
    //     color: '#00A2F5',
    //     logo: twemojiLogo,
    //     url: "https://twemoji.twitter.com/",
    //     privacy: "https://twitter.com/en/privacy",
    //     terms: 'https://twitter.com/en/tos'
    // },
    /*{
        title: 'Twitter Trends',
        desc: 'Trending topics and hashtags on Twitter',
        longDesc: '',
        sideBar: 'twittertrends',
        color: '#00A2F5',
        logo: twitterLogo,
        url: "https://twitter.com/",
        privacy: "https://twitter.com/en/privacy",
        terms: 'https://twitter.com/en/tos'
    },*/
    {

        title: 'Steno',
        desc: 'Easy text editor & AI Blog Writer',
        longDesc: 'Easy text editor and AI Blog Writer simplify content creation with intelligent assistance, enhancing productivity',
        sideBar: 'steno',
        color: '#1410C7',
        logo: stenoLogo,
        url: "https://www.steno.creatosaurus.io/",
        privacy: "https://www.creatosaurus.io/privacy",
        terms: 'https://creatosaurus.io/terms'
    },
    {
        title: 'Tenor',
        desc: 'Find the perfect Animated GIFs',
        longDesc: 'Say more with Tenor. Find the perfect Animated GIFs and videos to convey exactly what you mean in every conversation.',
        sideBar: 'tenor',
        color: '#006CD5',
        logo: tenorLogo,
        url: "https://tenor.com/",
        privacy: "https://tenor.com/legal-terms",
        terms: 'https://tenor.com/legal-terms'
    },
    {
        title: 'Unsplash',
        desc: 'The internet’s source of freely-usable images',
        longDesc: 'Beautiful, free images and photos that you can download and use for any project. Better than any royalty free or stock photos.',
        sideBar: 'unsplash',
        color: '#000000',
        logo: unsplashLogo,
        url: "https://unsplash.com/",
        privacy: "https://unsplash.com/privacy",
        terms: 'https://unsplash.com/terms'
    },
    {
        title: 'Uploads',
        desc: 'Organise & manage your digital files',
        longDesc: '',
        sideBar: 'uploads',
        color: '#42672B',
        logo: uploadsLogo,
        url: "https://www.creatosaurus.io/apps",
        privacy: "https://www.creatosaurus.io/privacy",
        terms: 'https://creatosaurus.io/terms'
    },


];

export default apps;