import React, { useContext, useState } from "react";
import "./SaveTemplatePopup.css";
import CreateDataContext from "../../../store/CreateDataProvider"
import { toast } from 'react-toastify'
import jwt_decode from 'jwt-decode'
import axios from 'axios';
import constant from '../../../constant';
import { useNavigate } from 'react-router-dom';



const SaveTemplatePopup = () => {
    const context = useContext(CreateDataContext)
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate();


    const createPost = async () => {
        try {
            if (context.postTitle === "") return toast.error('Title cannot be empty.')
            if (context.postDescription === "") return toast.error('Description cannot be empty.')
            setLoading(true)
            const token = localStorage.getItem('token');
            const decoded = jwt_decode(token)
            const config = { headers: { Authorization: `Bearer ${token}` } };
            const currentDate = new Date();
            const formattedDate = currentDate.toLocaleDateString('en-US', {
                month: 'long',
                day: 'numeric',
                year: 'numeric'
            });


            const body = {
                workspaceId: localStorage.getItem('organizationId'),
                userId: localStorage.getItem('userId'),
                metaData: {
                    coverImage: context.postCoverImage,
                    title: context.postTitle,
                    description: context.postDescription
                },
                info: {
                    createdBy: localStorage.getItem('userName'),
                    createdOn: formattedDate,
                    lastEditedOn: '',
                    lastEditedBy: localStorage.getItem('userName'),
                },
                blocks: context.postJson,
                type: 'draft',
                isTemplate: decoded.isAdmin
            }

            const response = await axios.post(`${constant.stenoURL}/post/create`, body, config)
            context.handleSavedPost(response.data.data)
            context.handleSavedDataChanged(false)
            context.handleTemplateSavePopup(false)
            toast.success('Template saved successfully.')
            navigate(`/editor/post/${response.data.data._id}`)
            setLoading(false)
        } catch (error) {
            setLoading(false)
            toast.error('Failed to create a post.')
        }
    }

    const updatePost = async () => {
        try {
            if (context.postTitle === "") return toast.error('Title cannot be empty.')
            if (context.postDescription === "") return toast.error('Description cannot be empty.')
            setLoading(true)
            const token = localStorage.getItem('token');
            const decoded = jwt_decode(token)
            const config = { headers: { Authorization: `Bearer ${token}` } };
            const currentDate = new Date();
            const formattedDate = currentDate.toLocaleDateString('en-US', {
                month: 'long',
                day: 'numeric',
                year: 'numeric'
            });


            const body = {
                workspaceId: context.savedPost?.workspaceId,
                userId: context.savedPost?.userId,
                metaData: {
                    coverImage: context.postCoverImage,
                    title: context.postTitle,
                    description: context.postDescription
                },
                info: {
                    createdBy: context.savedPost?.info?.createdBy,
                    createdOn: context.savedPost?.info?.createdOn,
                    lastEditedOn: formattedDate,
                    lastEditedBy: localStorage.getItem('userName'),
                },
                blocks: context.postJson,
                type: 'draft',
                isTemplate: decoded.isAdmin
            }

            const response = await axios.put(`${constant.stenoURL}/post/update/${context.savedPost?._id}`, body, config)
            context.handleSavedPost(response.data.data)
            context.handleSavedDataChanged(false)
            context.handleTemplateSavePopup(false)
            toast.success('Template updated successfully.')
            setLoading(false)
        } catch (error) {
            setLoading(false)
            toast.error('Failed to update a post.')
        }
    }

    const handleSave = () => {
        if (context.savedDataChanged) {
            updatePost()
        } else if (context.savedDataChanged === false && context.savedPost === null) {
            createPost()
        }
    }

    return (
        <div className="admin-confirm-box" id="admin-confirm-box">
            <div className="admin-items-box">
                <h2>
                    {loading && "Loading..."} {loading && <div class="lds-dual-ring"></div>}
                </h2>
                <div className="items">
                    <label>Title</label>
                    <input
                        readOnly
                        value={context.postTitle}
                    />
                </div>
                <div className="items">
                    <label>Description</label>
                    <input
                        readOnly
                        value={context.postDescription}
                    />
                </div>
                <div className="items button">
                    <button onClick={() => {
                        handleSave()
                    }}>Save</button>
                    <button onClick={() => {
                        context.handleTemplateSavePopup(false)
                    }}>Cancel</button>
                </div>
            </div>
        </div>
    );
};

export default SaveTemplatePopup;
