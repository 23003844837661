import React, { useRef, useContext, useEffect, useLayoutEffect } from 'react';
import './Title.css';
import CreateDataContext from '../../../store/CreateDataProvider';

const Title = () => {
    const context = useContext(CreateDataContext);
    const dataRef = useRef();

    const setCaretToEnd = () => {
        const { current: element } = dataRef;
        const range = document.createRange();
        const selection = window.getSelection();
        range.selectNodeContents(element);
        range.collapse(false);
        selection.removeAllRanges();
        selection.addRange(range);
        element.focus();
    };

    const inputHandler = () => {
        context.handlePostTitle(dataRef.current.innerHTML);
        if (context.savedPost !== null) {
            if (context.savedPost.metaData.title !== dataRef.current.innerHTML) {
                context.handleSavedDataChanged(true);
            }
        }
    };

    useLayoutEffect(() => {
        dataRef.current.innerHTML = context.postTitle;
        setCaretToEnd();
    }, [context.postTitle]);

    return (
        <div className="title-container" id="post-title">
            <h1
                ref={dataRef}
                style={{ marginTop: "5px" }}
                onInput={inputHandler}
                onDrop={(e) => e.preventDefault()}
                contentEditable="true"
                suppressContentEditableWarning
                className="head"
                placeholder="New post title here..."
                defaultValue={context.postTitle}
            ></h1>
        </div>
    );
};

export default Title;
