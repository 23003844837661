import React, { useEffect, useRef, useContext, useState } from "react";
import './Editor.css'
import EditorJS from "@editorjs/editorjs";
import editorJsTools from '../../../editor_tools/tools';
import CoverImage from '../cover_image/CoverImage';
import Title from '../title/Title';
import Description from '../description/Description'
import CreateDataContext from '../../../store/CreateDataProvider';
import Undo from 'editorjs-undo';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import constant from '../../../constant';
import _ from 'lodash';
import AiBlogGeneratePopup from '../../../pop_components/ai_blog_generate_popup/AiBlogGeneratePopup';




// const DEFAULT_INITIAL_DATA = {
//     "time": new Date().getTime(),
//     "blocks": [
//         {
//             "type": "header",
//             "data": {
//                 "text": "This is my awesome editor!",
//                 "level": 1
//             }
//         },
//     ]
// }


const Editor = () => {
    const context = useContext(CreateDataContext)
    const editorInstance = useRef(null);
    const [isAiPopupActive, setIsAiPopupActive] = useState(false)

    const { id } = useParams()


    const getPostById = async () => {
        try {
            if (id !== undefined) {
                const token = localStorage.getItem('token');
                const config = { headers: { Authorization: `Bearer ${token}` } };
                const response = await axios.get(`${constant.stenoURL}/post/${id}`, config)
                context.handlePostCoverImage(response.data.data.metaData.coverImage)
                context.handlePostTitle(response.data.data.metaData.title)
                context.handlePostDescription(response.data.data.metaData.description)
                context.handleSavedPost(response.data.data)
                context.handleSavedDataChanged(false);
                return response.data.data.blocks
            }
        } catch (error) {
            console.log(error)
        }
    }

    const hasDocumentChanged = async (initialBlocks, currentBlocks) => {
        return !_.isEqual(initialBlocks, currentBlocks);
    }

    // initialize editor function
    const initEditor = async () => {
        const editor = new EditorJS({
            holder: 'editorjs',
            onReady: () => {
                new Undo({ editor });
                editorInstance.current = editor;
            },
            autofocus: true,
            data: constant.savedPost !== null ? {
                "time": new Date().getDate(),
                "blocks": constant.savedPost.blocks
            } : {
                "time": new Date().getDate(),
                "blocks": await getPostById()
            },
            onChange: async () => {
                let content = await editor.saver.save();
                context.handlePostJson(content.blocks);
                if (constant.savedPost !== null) {
                    const hasChanged = await hasDocumentChanged(constant.savedPost.blocks, content.blocks)
                    if (hasChanged) {
                        context.handleSavedDataChanged(true);
                    } else {
                        context.handleSavedDataChanged(false);
                    }
                }

            },
            tools: editorJsTools,
            logLevel: 'ERROR'
        });
    };



    // This will run only once
    useEffect(() => {
        if (editorInstance.current === null) {
            initEditor();
        }

        return () => {
            editorInstance?.current?.destroy();
            editorInstance.current = null;
        };

    }, []); // eslint-disable-line react-hooks/exhaustive-deps



    const handleAiResults = (data) => {
        const blog = convertHtmlToEditorJsBlocks(data.blog)
        context.handlePostTitle(data.title)
        context.handlePostDescription(data.description)
        editorInstance.current.blocks.render({ blocks: blog.blocks });
    };

    const handleAiPopup = () => {
        setIsAiPopupActive(!isAiPopupActive)
    }


    const convertToEditorJSList = (text) => {
        const lines = text.split('\n').map(line => line.trim()).filter(line => line);
        const items = [];
        let currentItem = null;

        lines.forEach(line => {
            const romanNumeralMatch = line.match(/^[IVXLCDM]+\./); // Matches Roman numerals followed by a dot
            const letterMatch = line.match(/^[A-Z]\./); // Matches uppercase letter followed by a dot

            if (romanNumeralMatch) {
                // Push the current item if it exists
                if (currentItem) {
                    items.push(currentItem);
                }
                currentItem = line.replace(/^[IVXLCDM]+\.\s*/, ''); // Remove Roman numeral and space
            } else if (letterMatch) {
                // Append sub-point with <br> and remove letter and space
                if (currentItem) {
                    currentItem += `<br>${line.replace(/^[A-Z]\.\s*/, '')}`;
                }
            } else if (currentItem) {
                // Append any other lines as sub-points
                currentItem += `<br>${line}`;
            }
        });

        // Push the last item
        if (currentItem) {
            items.push(currentItem);
        }

        return {
            id: "wzBH3tJRTT",
            type: "list",
            data: {
                style: "ordered",
                items: items
            },
            tunes: {
                anyTuneName: {
                    alignment: "left"
                }
            }
        };
    }


    const convertHtmlToEditorJsBlocks = (html) => {
        const blocks = [];
        const parser = new DOMParser();
        const doc = parser.parseFromString(html, 'text/html');

        const parseElement = (element) => {
            switch (element.tagName.toLowerCase()) {
                case 'h1':
                    blocks.push({
                        type: 'header',
                        data: {
                            text: element.innerHTML,
                            level: 1,
                        },
                    });
                    break;
                case 'h2':
                    blocks.push({
                        type: 'header',
                        data: {
                            text: element.innerHTML,
                            level: 2,
                        },
                    });
                    break;
                case 'h3':
                    blocks.push({
                        type: 'header',
                        data: {
                            text: element.innerHTML,
                            level: 3,
                        },
                    });
                    break;
                case 'p':
                    blocks.push({
                        type: 'paragraph',
                        data: {
                            text: element.innerHTML,
                        },
                    });
                    break;
                case 'ul':
                    blocks.push({
                        type: 'list',
                        data: {
                            style: 'unordered',
                            items: Array.from(element.children).map(li => li.innerHTML),
                        },
                    });
                    break;
                case 'ol':
                    blocks.push({
                        type: 'list',
                        data: {
                            style: 'ordered',
                            items: Array.from(element.children).map(li => li.innerHTML),
                        },
                    });
                    break;
                default:
                    break;
            }
        };

        doc.body.childNodes.forEach(node => {
            if (node.nodeType === Node.ELEMENT_NODE) {
                parseElement(node);
            }
        });

        return {
            blocks: blocks,
        };
    }


    const handleAiBlogResults = (blogData) => {
        context.handlePostTitle(blogData.title)
        context.handlePostDescription(blogData.desc)
        const tableOfContent = convertToEditorJSList(blogData.tableOfContent)
        const blog = convertHtmlToEditorJsBlocks(blogData.blog)
        editorInstance.current.blocks.render({
            blocks: [
                {
                    type: 'table',
                    data: {
                        withHeadings: false,
                        content: [
                            ['<b>Topic</b>', blogData.topic],
                            ['<b>Word Count</b>', '2000+'],
                            ['<b>Format</b>', 'Information and How to blog'],
                            ['<b>Primary keyword</b>', blogData.primaryKeyword],
                            ['<b>Secondary keywords</b>', blogData.secondaryKeyword],
                            ['<b>Audience Stage &amp; Search Intent</b>', blogData.audienceStage],
                            ['<b>Meta Title</b>', blogData.metaTitle],
                            ['<b>Meta Description</b>', blogData.metaDesc]
                        ]
                    }
                },
                {
                    type: "header",
                    data: {
                        text: "Table Of Content",
                        level: 3,
                    }
                },
                tableOfContent,
                ...blog.blocks
            ]
        })

    }


    return (
        <div className="editor-main-container">
            {isAiPopupActive ? <AiBlogGeneratePopup handleAiResults={handleAiResults} handleAiPopup={handleAiPopup} handleAiBlogResults={handleAiBlogResults} /> : null}
            <div className="editor-top-container">
                <CoverImage />
                <svg width="24px" height="24px" viewBox="0 0 24 24" strokeWidth="1.5" fill="none" xmlns="http://www.w3.org/2000/svg" color="#000000" onClick={handleAiPopup}><path d="M8 15C12.8747 15 15 12.949 15 8C15 12.949 17.1104 15 22 15C17.1104 15 15 17.1104 15 22C15 17.1104 12.8747 15 8 15Z" stroke="#000000" strokeWidth="1.5" strokeLinejoin="round"></path><path d="M2 6.5C5.13376 6.5 6.5 5.18153 6.5 2C6.5 5.18153 7.85669 6.5 11 6.5C7.85669 6.5 6.5 7.85669 6.5 11C6.5 7.85669 5.13376 6.5 2 6.5Z" stroke="#000000" strokeWidth="1.5" strokeLinejoin="round"></path></svg>
            </div>
            <Title />
            <Description />
            <div id='editorjs'></div>
        </div>
    );
}

export default Editor;