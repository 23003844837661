import { createContext, useState } from 'react';
import constant from '../constant'
import axios from 'axios';
import { toast } from "react-toastify"
import jwtDecode from 'jwt-decode';



const CreateDataContext = createContext({
    userData: [],
    canEdit: false,
    postJson: [],
    postCoverImage: '',
    postTitle: '',
    postDescription: '',
    savedPost: null,
    savedDataChanged: false,
    drafts: [],
    templates: [],
    isAdmin: false,
    templatePopupActive: false,
    templateLoading: false,
    draftLoading: false,


    getUserData: () => { },
    handlePostJson: () => { },
    handlePostCoverImage: () => { },
    handlePostTitle: () => { },
    handlePostDescription: () => { },
    handleSavedPost: () => { },
    handleSavedDataChanged: () => { },
    getAllDrafts: () => { },
    getAllTemplates: () => { },
    deleteDraft: () => { },
})

export const CreateDataProvider = (props) => {
    const [userData, setuserData] = useState([])
    const [canEdit, setcanEdit] = useState(false)
    const [postJson, setPostJson] = useState([])
    const [postCoverImage, setPostCoverImage] = useState('')
    const [postTitle, setPostTitle] = useState('')
    const [postDescription, setPostDescription] = useState('')
    const [savedPost, setSavedPost] = useState(null)
    const [savedDataChanged, setSavedDataChanged] = useState(false)
    const [drafts, setDrafts] = useState([])
    const [templates, setTemplates] = useState([])
    const [isAdmin, setAdmin] = useState(false)
    const [templatePopupActive, setTemplatePopupActive] = useState(false)
    const [templateLoading, setTemplateLoading] = useState(false)
    const [draftLoading, setDraftLoading] = useState(false)



    // get users data
    const getUserData = async () => {
        try {
            setDraftLoading(true)
            const token = localStorage.getItem('token');
            const decoded = jwtDecode(token)
            const config = { headers: { Authorization: `Bearer ${token}` } };
            const res = await axios.get(constant.mainWebURL + '/user/info', config);

            // update the localstorage
            localStorage.setItem('organizationId', res.data.activeWorkspace._id);
            localStorage.setItem('organizationName', res.data.activeWorkspace.workspace_name);

            // check user can edit or noe
            let userDetails = res.data.activeWorkspace.team.filter(data => data.user_email === res.data.userData.email)
            if (userDetails[0].role !== "view") {
                setcanEdit(true)
            } else {
                setcanEdit(false)
            }

            // update state
            setuserData(res.data)
            setAdmin(decoded.isAdmin)
            getAllDrafts()
            getAllTemplates()
        } catch (error) {
            console.log(error)
        }
    }

    const handlePostJson = (value) => {
        setPostJson(value)
    }

    const handlePostCoverImage = (value) => {
        setPostCoverImage(value)
    }

    const handlePostTitle = (value) => {
        setPostTitle(value)
    }

    const handlePostDescription = (value) => {
        setPostDescription(value)
    }

    const handleSavedPost = (value) => {
        constant.savedPost = value
        setSavedPost(value)
    }

    const handleSavedDataChanged = (value) => {
        setSavedDataChanged(value)
    }

    const handleTemplateSavePopup = (value) => {
        setTemplatePopupActive(value)
    }


    const getAllDrafts = async () => {
        try {
            setDraftLoading(true)
            const token = localStorage.getItem('token');
            const config = { headers: { Authorization: `Bearer ${token}` } };
            const workspaceId = localStorage.getItem('organizationId')
            const response = await axios.get(`${constant.stenoURL}/drafts/${workspaceId}`, config)
            setDrafts(response.data.data)
            setTimeout(() => {
                setDraftLoading(false)
            }, 1000)
        } catch (error) {
            setDraftLoading(false)
            console.log(error)
            toast.error('Failed to load drafts')
        }
    }

    const getAllTemplates = async () => {
        try {
            setTemplateLoading(true)
            const token = localStorage.getItem('token');
            const config = { headers: { Authorization: `Bearer ${token}` } };
            const response = await axios.get(`${constant.stenoURL}/templates`, config)
            setTemplates(response.data.data)
            setTimeout(() => {
                setTemplateLoading(false)
            }, 1000);
        } catch (error) {
            console.log(error)
            setTemplateLoading(false)
            toast.error('Failed to load templates')
        }
    }

    const deleteDraft = async (id) => {
        try {
            const token = localStorage.getItem('token');
            const config = { headers: { Authorization: `Bearer ${token}` } };
            await axios.delete(`${constant.stenoURL}/delete/draft/${id}`, config)
            getAllDrafts()
            toast.success("Draft delete successfully.")
        } catch (error) {
            console.log(error)
            toast.error('Failed to delete draft.')
        }
    }






    const context = {
        userData: userData,
        canEdit: canEdit,
        postJson: postJson,
        postCoverImage: postCoverImage,
        postTitle: postTitle,
        postDescription: postDescription,
        savedPost: savedPost,
        savedDataChanged: savedDataChanged,
        drafts: drafts,
        templates: templates,
        isAdmin: isAdmin,
        templatePopupActive: templatePopupActive,
        templateLoading: templateLoading,
        draftLoading: draftLoading,

        getUserData: getUserData,
        handlePostJson: handlePostJson,
        handlePostCoverImage: handlePostCoverImage,
        handlePostTitle: handlePostTitle,
        handlePostDescription: handlePostDescription,
        handleSavedPost: handleSavedPost,
        handleSavedDataChanged: handleSavedDataChanged,
        getAllDrafts: getAllDrafts,
        getAllTemplates: getAllTemplates,
        deleteDraft: deleteDraft,
        handleTemplateSavePopup: handleTemplateSavePopup
    }

    return (
        <CreateDataContext.Provider value={context}>{props.children}</CreateDataContext.Provider>
    )
}

export default CreateDataContext