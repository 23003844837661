import React, { useState } from 'react'
import DownArrow from '../../assets/SelectDownArrow.svg'
import './InstaCard.css'

const InstaCard = ({ data }) => {
    const [open, setopen] = useState(false)

    return (
        <div className='insta-tags-container'>
            <div className='head'>
                <span>{data.tag}</span>
                <img src={DownArrow} alt="" className={open ? "rotate" : null} onClick={() => setopen((prev) => !prev)} />
            </div>
            {
                open ? <div className='card1'>
                    <div className='graph-container'>
                        <span>Competition</span>
                        <progress value={data.count} max={50} />
                    </div>
                    <div className='graph-container'>
                        <span>Avg. Likes</span>
                        <progress value={data.avgLike} max={data.max_likes} />
                    </div>
                    <div className='graph-container'>
                        <span>Avg. Comments</span>
                        <progress value={data.avgComment} max={data.max_comments} />
                    </div>
                </div> : null
            }
        </div>
    )
}

export default InstaCard