import React from 'react'
import './TemplateLoading.css'

const TemplateLoading = () => {
    return (
        <div className="template-loading-container">
            <div className="left">
                <div className="skeleton name" />
                <div className="skeleton desc" />
            </div>

            <div className="skeleton pic" />
        </div>
    );
}

export default TemplateLoading;