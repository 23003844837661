import React, { useState, useEffect } from 'react';
import './TwitterTrends.css';
import axios from 'axios';
import AppHeadingCard from '../app_heading_card/AppHeadingCard';
import constant from '../../constant';
import { toast } from 'react-toastify';
import HashtagsLoader from '../hashtags_loader/HashtagsLoaders';


const TwitterTrends = ({ changeActiveApp }) => {
    const [hashtags, setHashtags] = useState([]);
    const [woeid, setWoeid] = useState("1");
    const [loading, setLoading] = useState(true);

    // load initial trends
    useEffect(() => {
        getTheTrends();
    }, [woeid]); // eslint-disable-line react-hooks/exhaustive-deps

    const getTheTrends = async () => {
        try {
            setLoading(true);
            const response = await axios.get(constant.url + 'twitter/trends', {
                params: {
                    woeid,
                },
            });
            setHashtags(response.data[0].trends);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.log(error.response);
        }
    };

    const handleWoeid = (event) => {
        const value = event.target.value;
        setWoeid(value);
    };



    const notify = () => {
        toast.success("Hashtag copied successfully.");
    };

    return (
        <div className='rightTwitterTrends'>
            <AppHeadingCard title="Twitter Trends" changeActiveApp={changeActiveApp} />
            <div className='select-container'>
                <select name="id" className="selectCountry" onChange={handleWoeid}>
                    <option value="1">World Wide</option>
                    <option value="23424848">India</option>
                    <option value="23424977">US</option>
                    <option value="23424748">Australia</option>
                    <option value="23424975">UK</option>
                    <option value="23424775">Canada</option>
                </select>
            </div>
            {loading ? <HashtagsLoader /> : (
                <div className='scroll-container' style={loading ? { overflow: 'hidden' } : null}>
                    {hashtags.map((hashtag, index) => {
                        return (
                            <div key={index} className="hashtagDiv">
                                <p
                                    onClick={() => {
                                        navigator.clipboard.writeText(hashtag.name);
                                        notify();
                                    }}
                                >
                                    {hashtag.name}
                                </p>
                            </div>
                        );
                    })}
                </div>
            )}


        </div>
    );
};

export default TwitterTrends;