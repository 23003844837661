import jwt_decode from 'jwt-decode';

const getCookie = (name) => {
    if (process.env.NODE_ENV === 'production') {
        var value = "; " + document.cookie;
        var parts = value.split("; " + name + "=");
        if (parts.length === 2) return parts.pop().split(";").shift();
    } else {
        return "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ3YWl0TGlzdE51bWJlciI6MzIzNTQsImlzQWRtaW4iOmZhbHNlLCJpc0FmZmlsaWF0ZSI6ZmFsc2UsInVzZXJOYW1lIjoic2F1cmFiaHNvbmRlMTExIiwidGltZVpvbmUiOiJBc2lhL0NhbGN1dHRhIiwiaWQiOiI2MTEzNTA0YWQwMmFjMjY4YTJmOWI1OTYiLCJwbGFuSWQiOiI2MjQ3MDg1MTM3YjU1MzA3NGZiNmIyNWEiLCJvbmJvYXJkaW5nX3N0YXR1cyI6dHJ1ZSwiaWF0IjoxNzE4MjEwNDkxLCJleHAiOjE3MTk0MjAwOTF9._QeH45-EGBYwz3aDgQ1Y-U2CkQP0yJD-xZO8B8j6gGE"
    }
}


const distructureCookie = () => {
    let value = getCookie('Xh7ERL0G');
    if (value === undefined) return window.open('https://www.creatosaurus.io/?app=steno', "_self");
    localStorage.setItem('token', value)
};

const ProtectedRoutes = ({ children }) => {
    distructureCookie();
    const token = localStorage.getItem('token');

    // decode the jwt token and check the expiration time
    const decoded = jwt_decode(token);
    const expirationTime = decoded.exp * 1000;

    // if token is not there redirect to login or token expired
    if (token === null || Date.now() >= expirationTime) return window.open('https://www.creatosaurus.io/?app=steno', '_self');
    return children;
};

export default ProtectedRoutes;