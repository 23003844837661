import React, { useState } from 'react'
import './TagsCard.css'
import DownArrow from '../../assets/SelectDownArrow.svg'

const TagsCard = ({ data }) => {

    const converNumber = (labelValue) => {
        return Math.abs(Number(labelValue)) >= 1.0e+9
            ? (Math.abs(Number(labelValue)) / 1.0e+9).toFixed(2) + "B"
            : Math.abs(Number(labelValue)) >= 1.0e+6
                ? (Math.abs(Number(labelValue)) / 1.0e+6).toFixed(2) + "M"
                : Math.abs(Number(labelValue)) >= 1.0e+3
                    ? (Math.abs(Number(labelValue)) / 1.0e+3).toFixed(2) + "K"
                    : Math.abs(Number(labelValue));
    }

    const [toggle, settoggle] = useState(false)
    return (
        <div className='tag-card-container'>
            {
                toggle ? <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }} className="open">
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <span style={{ fontSize: 14, color: '#000' }}>#{data.hashtag}</span>
                        <img className='rotate' src={DownArrow} alt="" onClick={() => settoggle((prev) => !prev)} />
                    </div>
                    <span>Tweets : {data.tweets}</span>
                    <span>Exposure : {data.exposure}</span>
                    <span>Retweets : {data.retweets}</span>
                    <span>Images : {data.images}</span>
                    <span>Links : {data.links}</span>
                    <span>Mentions : {data.mentions}</span>
                    <span>Color : {data.color}</span>
                </div> :
                    <React.Fragment>
                        <span style={{ fontSize: 14 }}>#{data.hashtag}&nbsp;&nbsp;<span style={{ fontSize: 10, color: '#404040' }}>{converNumber(data.exposure)}</span></span>
                        <img src={DownArrow} alt="" onClick={() => settoggle((prev) => !prev)} />
                    </React.Fragment>
            }
        </div>
    )
}

export default TagsCard