import React, { useState, useEffect } from 'react';
import './Quotes.css'
import { toast } from 'react-toastify';
import Axios from 'axios'
import constant from '../../constant';
import AppHeadingCard from '../app_heading_card/AppHeadingCard';

const Quotes = ({ changeActiveApp }) => {
  const [loading, setLoading] = useState(true);
  const [quotes, setQuotes] = useState([]);
  const [popularQuotes, setPopularQuotes] = useState([]);
  const [active, setActive] = useState('popular');
  const [search, setSearch] = useState('');
  const [searchQuotes, setSearchQuotes] = useState([]);
  const [quoteCategory, setquoteCategory] = useState([])
  const [selectedCategory, setselectedCategory] = useState('')
  const [page, setpage] = useState(1)
  const [dataFinished, setdataFinished] = useState(false)

  useEffect(() => {
    getTheUserQuotes();
    getPopularQuotes();
    getUserQuoteCategory()
  }, []);

  const getTheUserQuotes = async () => {
    try {
      setLoading(true);
      const workspaceId = localStorage.getItem('organizationId');
      const response = await Axios.get('https://ug06ceeng4.execute-api.ap-south-1.amazonaws.com/latest/quotes/saved/' + workspaceId);
      setQuotes(response.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  const getPopularQuotes = async () => {
    try {
      setLoading(true);
      const response = await Axios.get('https://ug06ceeng4.execute-api.ap-south-1.amazonaws.com/latest/quotes/search?q=null&page=1&limit=9');
      setPopularQuotes(response.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };


  const getUserQuoteCategory = async () => {
    try {
      setLoading(true);
      const workspaceId = localStorage.getItem('organizationId');
      const response = await Axios.get('https://ug06ceeng4.execute-api.ap-south-1.amazonaws.com/latest/quotes/groups/' + workspaceId);
      if (response.data === "") return setLoading(false)
      setquoteCategory(response.data.groupName)
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  const getSearchQuotes = async (loadMore, value) => {
    try {
      if (!loadMore) {
        setSearchQuotes([])
        setpage(1)
        setdataFinished(false)
      }

      setLoading(true);
      setSearch(value)

      if (constant.searchToken) {
        constant.searchToken.cancel() // Cancel the previous request before making a new request
      }

      // Create a new CancelToken
      constant.searchToken = Axios.CancelToken.source()

      const response = await Axios.get(`https://ug06ceeng4.execute-api.ap-south-1.amazonaws.com/latest/quotes/search?q=${value}&page=${!loadMore ? 1 : page}&limit=30`, {
        cancelToken: constant.searchToken.token
      });

      if (response.data.length < 30) {
        setdataFinished(true)
      }
      setSearchQuotes((prev) => [...prev, ...response.data]);
      setLoading(false);
      setpage((prev) => prev + 1)
    } catch (error) {
      if (Axios.isCancel(error)) {
        // Handle if request was cancelled
      } else {
        setLoading(false);
      }
    }
  }

  const Loading = () => {
    return (
      <div className="loading">
        <h5>Loading...</h5>
      </div>
    );
  };

  const SearchQuotes = () => {
    return (
      <div>
        {
          searchQuotes.length === 0 && loading === false ? <div className='not-saved'>
            Oops, won't find any quotes. Check your spelling or internet connection.
          </div> : <React.Fragment>
            {
              searchQuotes.map((quote, index) => {
                return (
                  <div key={index} className="quotesDiv">
                    <div>
                      <p onClick={() => {
                        navigator.clipboard.writeText(quote.Queote);
                        notify();
                      }}>
                        {quote.Queote}
                        <br />
                        <span>-{quote.Author}</span>
                      </p>
                    </div>
                  </div>
                );
              })
            }
          </React.Fragment>
        }
      </div>
    );
  };


  const PopularQuotes = () => {
    return (
      <div>
        {
          popularQuotes.map((quote, index) => {
            return (
              <div key={index} className="quotesDiv">
                <div>
                  <p
                    onClick={() => {
                      navigator.clipboard.writeText(
                        `${quote.Queote} -${quote.Author}`
                      );
                      notify();
                    }}>
                    {quote.Queote}
                    <br />
                    <span>-{quote.Author}</span>
                  </p>
                </div>
              </div>
            );
          })}
      </div>
    );
  };

  const SavedQuotes = () => {
    return (
      <div>
        {
          quotes.length === 0 ?
            <div className='not-saved'>
              You haven't saved any quotes yet.
              <span onClick={() => window.open('https://www.quotes.creatosaurus.io/')}>Click here</span>
              to save quotes.
            </div> :
            <React.Fragment>
              {
                quotes.map((quote, index) => {
                  if (selectedCategory === quote.groupName) {
                    return (
                      <div key={index} className="quotesDiv">
                        <div>
                          <p
                            onClick={() => {
                              navigator.clipboard.writeText(quote.quote);
                              notify();
                            }}
                          >
                            {quote.quote}
                            <br />
                            <span>-{quote.author}</span>
                          </p>
                        </div>
                      </div>
                    );
                  } else if (selectedCategory === "") {
                    return (
                      <div key={index} className="quotesDiv">
                        <div>
                          <p
                            onClick={() => {
                              navigator.clipboard.writeText(quote.quote);
                              notify();
                            }}
                          >
                            {quote.quote}
                            <br />
                            <span>-{quote.author}</span>
                          </p>
                        </div>
                      </div>
                    );
                  } else {
                    return <></>;
                  }
                })}
            </React.Fragment>
        }
      </div>
    )
  }

  const scrollStarted = (e) => {
    if (e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight - 50) {
      if (loading || dataFinished) return
      if (search !== "") {
        getSearchQuotes(true, search)
      }
    }
  }

  const changeActiveButton = (value) => {
    setActive(value)
  }

  const notify = () => toast.info('Copied!');

  return (
    <div className="right-quotes" >
      <div className="search-container-apps">
        <AppHeadingCard title="Quotes" changeActiveApp={changeActiveApp} />
        <div className='input-container'>
          <input
            type="text"
            className="searchBar"
            placeholder="Search Quotes"
            onChange={(e) => getSearchQuotes(false, e.target.value)}
            value={search}
          />
          {
            search !== '' ?
              <svg className='cross' onClick={() => setSearch("")} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path d="M10.0007 9.99973L14.3695 14.3686M5.63184 14.3686L10.0007 9.99973L5.63184 14.3686ZM14.3695 5.63086L10.0007 9.99973L14.3695 5.63086ZM10.0007 9.99973L5.63184 5.63086L10.0007 9.99973Z" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              </svg> : null
          }
        </div>

        {
          search.trim() === "" ? <div className="btn">
            <span style={active === 'popular' ? { borderBottomStyle: 'solid', borderBottomWidth: 1.5, borderBottomColor: '#0078FF' } : { color: '#C4C4C4' }}
              onClick={() => changeActiveButton('popular')}>Suggested</span>
            <span style={active === 'saved' ? { borderBottomStyle: 'solid', borderBottomWidth: 1.5, borderBottomColor: '#0078FF' } : { color: '#C4C4C4' }}
              onClick={() => changeActiveButton('saved')}>Saved</span>
          </div> : null
        }

        {
          active === "saved" && search.trim() === "" ?
            <div className='filter-category' >
              <select onChange={(e) => setselectedCategory(e.target.value)} >
                <option label='All' value='' />
                {
                  quoteCategory.map((data, index) => {
                    return (
                      <option key={index} label={data} value={data} />
                    )
                  })
                }
              </select>
            </div>
            : null
        }

        <div onScroll={scrollStarted} className={search.trim() !== "" ? "search-scroll" : active === "popular" ? "popular-scroll" : "saved-scroll"}>
          <React.Fragment>
            {search.trim() !== "" ? SearchQuotes() : null}
            {search.trim() !== "" ? loading ? <Loading /> : null : null}
          </React.Fragment>

          <React.Fragment>
            {
              search.trim() === "" ? loading ? <Loading /> :
                active === "popular" ? PopularQuotes() : SavedQuotes() : null
            }
          </React.Fragment>
        </div>
      </div>
    </div >
  )
}

export default Quotes