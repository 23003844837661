import React, { useState } from 'react';
import './AppHeadingCard.css';
import MoreOption from '../more_option_popup/MoreOption';
import apps from '../../appNames';

const AppHeadingCard = ({ title, changeActiveApp, signOut, isUserSignIn, login, authenticationRequired, slackWorkspaceName }) => {
    const [moreOption, setMoreOption] = useState(false);
    const [showAppDetails, setShowAppDetails] = useState(false);
    const [appInfo, setAppInfo] = useState(null);

    const toggleMoreOption = () => {
        setMoreOption(!moreOption);
    };

    const addInfo = (title) => {
        const selectedApp = apps.find((app) => app.title === title);
        if (selectedApp === undefined) {
            setAppInfo({
                title: "App Store",
                url: "https://www.creatosaurus.io/apps",
            });
        } else {
            setAppInfo(selectedApp);
        }

    };

    const toggleAppDetails = () => {
        setShowAppDetails(!showAppDetails);
        changeActiveApp("AppDetails", appInfo);
    };

    return (
        <div className='app-heading-card' onClick={() => {
            if (moreOption) {
                setMoreOption(false);
            }
        }}>
            {
                moreOption && showAppDetails === false ? <MoreOption
                    appInfo={appInfo}
                    close={toggleMoreOption}
                    appUrl={appInfo.url}
                    toggleAppDetails={toggleAppDetails}
                    signOut={signOut}
                    login={login}
                    isUserSignIn={isUserSignIn}
                    authenticationRequired={authenticationRequired}
                    slackWorkspaceName={slackWorkspaceName}
                    changeActiveApp={() => changeActiveApp("AppDetails", appInfo)} /> : null
            }

            <div className='center'>
                {title === "Creatosaurus App Store" ? null : <svg onClick={() => changeActiveApp("allApps")} width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M22.2857 13H6.5M6.5 13L13 6.5M6.5 13L13 19.5" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>}
                <span>{title}</span>
            </div>

            <svg width="21" height="5" viewBox="0 0 21 5" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => {
                setMoreOption(true);
                addInfo(title);
            }}>
                <circle cx="2.5" cy="2.5" r="2.5" transform="rotate(-90 2.5 2.5)" fill="#000000" />
                <circle cx="10.5" cy="2.5" r="2.5" transform="rotate(-90 10.5 2.5)" fill="#000000" />
                <circle cx="18.5" cy="2.5" r="2.5" transform="rotate(-90 18.5 2.5)" fill="#000000" />
            </svg>
        </div>
    );
};

export default AppHeadingCard;