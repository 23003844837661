import jwt_decode from 'jwt-decode';
import { toast } from "react-toastify";
import axios from 'axios';
import './WriteMore.css'
import addsvg from "../../assets/add.svg"
import closesvg from "../../assets/close.svg"

class WriteMore {
    static get isInline() {
        return true;
    }

    constructor({ api }) {
        this.api = api;
        this.button = null;
        this.tag = 'p';
        this.class = 'cdx-ai-write-more';
    }

    render() {
        this.button = document.createElement('button');
        this.button.type = 'button';
        this.button.innerHTML = `
    <svg width="10px" height="10px" viewBox="0 0 24 24" stroke-width="1.5" fill="none" xmlns="http://www.w3.org/2000/svg" color="#000000"><path d="M14.363 5.652l1.48-1.48a2 2 0 012.829 0l1.414 1.414a2 2 0 010 2.828l-1.48 1.48m-4.243-4.242l-9.616 9.615a2 2 0 00-.578 1.238l-.242 2.74a1 1 0 001.084 1.085l2.74-.242a2 2 0 001.24-.578l9.615-9.616m-4.243-4.242l4.243 4.242" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg>`;
        this.button.classList.add(this.api.styles.inlineToolButton);

        return this.button;
    }


    surround(range) {
        const selectedText = range.extractContents();

        // Create the overlay container as a non-editable popup
        const overlayContainer = document.createElement('div');
        overlayContainer.classList.add('overlay-container');
        overlayContainer.contentEditable = 'false';
        overlayContainer.setAttribute('unselectable', 'on');
        overlayContainer.style.userSelect = 'none';

        let textResponse = ""

        // Append the overlay container below the current block
        const currentBlock = range.startContainer.parentNode;
        currentBlock.parentNode.insertBefore(overlayContainer, currentBlock.nextSibling);
        const currentContent = selectedText.textContent;
        currentBlock.innerHTML = currentContent;



        // add button
        const addButton = document.createElement('button');
        addButton.classList.add('add-button');
        addButton.addEventListener('click', () => {
            currentBlock.innerHTML = currentContent + " " + textResponse
            overlayContainer.parentNode.removeChild(overlayContainer);
        });

        // Create an image element
        const image = document.createElement('img');
        image.src = addsvg;
        image.alt = 'Add';
        addButton.appendChild(image);
        overlayContainer.appendChild(addButton);


        // Create a container for the text content
        const textContainer = document.createElement('div');
        textContainer.classList.add('text-container');
        overlayContainer.appendChild(textContainer);

        // Create a loading message element
        const loadingMessage = document.createElement('div');
        overlayContainer.classList.add('ai-loading');
        loadingMessage.textContent = 'Steno is writing...';
        overlayContainer.appendChild(loadingMessage);


        // Create the close button
        const closeButton = document.createElement('button');
        closeButton.classList.add('close-button');
        closeButton.addEventListener('click', () => {
            overlayContainer.parentNode.removeChild(overlayContainer);
        });

        const closeImage = document.createElement('img');
        closeImage.src = closesvg;
        closeImage.alt = 'Close';
        closeButton.appendChild(closeImage);
        overlayContainer.appendChild(closeButton);


        this.getAIResponse(selectedText.textContent)
            .then((responseText) => {
                overlayContainer.removeChild(loadingMessage); // remove loading message

                let i = 0;
                const responseParagraph = document.createElement('p');
                const selection = window.getSelection();
                selection.removeAllRanges();
                if (typeof responseText !== "string") {
                    const error = document.createElement('span');
                    error.classList.add('error-span')
                    // eslint-disable-next-line 
                    error.textContent = 'Error:' + " " + responseText.error
                    addButton.style.display = "none"
                    responseParagraph.appendChild(error)
                    textContainer.appendChild(responseParagraph);
                } else {
                    const intervalId = setInterval(() => {
                        if (i >= responseText.length) {
                            clearInterval(intervalId);
                            return;
                        }
                        responseParagraph.textContent += responseText.charAt(i);
                        textContainer.appendChild(responseParagraph);
                        i++;
                    }, 10);

                    textResponse = responseText
                }

            })
            .catch((error) => {
                console.log(error);
            });
    }






    checkState() {
        const { anchorNode } = this.api.selection;

        if (!anchorNode) {
            return false;
        }

        let currentNode = anchorNode;

        while (currentNode) {
            if (
                currentNode.nodeType === Node.ELEMENT_NODE &&
                currentNode.classList &&
                currentNode.classList.contains(this.class)
            ) {
                return true;
            }

            currentNode = currentNode.parentNode;
        }

        return false;
    }

    async getAIResponse(selectedText) {
        try {
            const token = localStorage.getItem("token");
            const decoded = jwt_decode(token);
            const selectedWords = selectedText;
            if (selectedWords.length > 0) {
                if (selectedWords.length > 600) {
                    return {
                        errorCode: 1,
                        error: "Character length is greater then 600 char."
                    }
                } else if (selectedWords.length < 50) {
                    return {
                        errorCode: 2,
                        error: "Character length is less then 50 char."
                    }
                }
            }

            if (selectedWords.length === 0) {
                return {
                    errorCode: 2,
                    error: "Character length is less then 50 char."
                }
            }

            // const lastSentence = currentBlock.block.data.text.split('.')[1];


            const body = {
                organizationId: localStorage.getItem('organizationId'),
                userId: decoded.id,
                userName: decoded.userName,
                companyName: null,
                companyDescription: selectedWords,
                gender: "male",
                copyType: "Content Expander (Write more)",
                language: "English"
            };


            const response = await axios.post("https://api.captionator.creatosaurus.io/captionator/write/more", body, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });



            return response.data.data[0].text
        } catch (error) {
            if (error.response.data.message === "too many request") {
                toast.error('Your credits are over please upgrade your plan.');
            } else {
                toast.error("Failed to write more.");
            }
        }
    }
}

export default WriteMore;
