import React, { useContext } from 'react'
import "./TemplateCard.css"
import empty from "../../assets/center_dashboard_assets/empty.jpg"
import CreateDataContext from '../../store/CreateDataProvider'
import { useNavigate } from 'react-router-dom'

const TemplateCard = ({ data }) => {
    const context = useContext(CreateDataContext)
    const navigate = useNavigate();

    return (
        <div className="template-card-container" onClick={() => {
            context.handleSavedPost(data)
            context.handlePostTitle(data.metaData.title)
            context.handlePostDescription(data.metaData.description)
            context.handlePostCoverImage(data.metaData.coverImage)
            navigate(`/editor/post/${data._id}`)
        }}>
            <div className="left-side">
                <h3>{data.metaData.title}</h3>
                <p>{data.metaData.description}</p>
            </div>
            <div className="right-side">
                {data.url !== "" ? (
                    <img alt="" src={data.metaData.coverImage} />
                ) : (
                    <img alt="" src={empty} />
                )}
            </div>
        </div>
    );
}

export default TemplateCard;