import React, { useState, useEffect } from 'react';
import "./GooglePhotos.css";
import AppHeadingCard from '../app_heading_card/AppHeadingCard';
import Google from '../../assets/google.svg';
import jwt_decode from 'jwt-decode';
import axios from 'axios';
import Vector from '../../assets/Vector.svg';
import backIcon from "../../assets/leftArrow.svg";
import Masonry from "react-responsive-masonry";
import ImagesLoader from '../images_loader/ImagesLoader';
import constant from "../../constant";


const GooglePhotos = ({ changeActiveApp }) => {
    const [signedInUser, setSignedInUser] = useState(false);
    const [userAlbums, setUserAlbums] = useState([]);
    const [selectedID, setSelectedID] = useState('');
    const [selectedName, setSelectedName] = useState('');
    const [albumPhotos, setAlbumPhotos] = useState([]);
    const [pageToken, setPageToken] = useState('');
    const [loading, setLoading] = useState(false);
    const [usersMedia, setUsersMedia] = useState([]);
    const [imagesLoading, setImagesLoading] = useState(false);
    const [accessToken, setAccessToken] = useState("unauthorized");


    const scrollStarted = (e) => {
        if (e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight - 50) {
            if (selectedID === "") {
                getMoreUsersMedia();
            } else {
                nextAlbumImages();
            }
        }
    };



    useEffect(() => {
    }, [selectedID, pageToken]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        getAccessToken();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    // login google drive
    const login = () => {
        const jwtToken = localStorage.getItem('token');
        const decoded = jwt_decode(jwtToken);
        const user_id = decoded.id;
        window.open(`${constant.url}google/photos/login/${user_id}/steno`, "_self");
    };

    // logout google photos
    const logout = async () => {
        const jwtToken = localStorage.getItem('token');
        const decoded = jwt_decode(jwtToken);
        const user_id = decoded.id;
        const response = await axios.get(`${constant.url}google/photos/logout/${user_id}`);
        if (response.data.data === "User logout successfully") {
            setSignedInUser(false);
            setAccessToken("unauthorized");
        }
    };



    const loadFilesAndFolders = async (access_token) => {
        try {
            setLoading(true);
            // get users albums
            const response = await axios.get("https://photoslibrary.googleapis.com/v1/albums", {
                headers: {
                    Accept: "application/json",
                    Authorization: `Bearer ${accessToken === "unauthorized" ? access_token : accessToken}`
                }
            });
            if (response.data.albums.length > 0) {
                setUserAlbums(response.data.albums);
                setLoading(false);
            }
        } catch (error) {
            console.log(error);
        }
    };

    // get users media 
    const getUsersMedia = async (access_token) => {
        try {
            const response = await axios.get("https://photoslibrary.googleapis.com/v1/mediaItems", {
                headers: {
                    Accept: "application/json",
                    Authorization: `Bearer ${accessToken === "unauthorized" ? access_token : accessToken}`
                }
            });
            if (response.data.mediaItems.length > 0) {
                setUsersMedia(response.data.mediaItems);
                setPageToken(response.data.nextPageToken);
                setLoading(false);
            }
        } catch (error) {
            console.log(error);
        }
    };

    // get more users media
    const getMoreUsersMedia = async () => {
        try {
            setImagesLoading(true);
            const response = await axios.get(`https://photoslibrary.googleapis.com/v1/mediaItems?pageToken=${pageToken}`, {
                headers: {
                    Accept: "application/json",
                    Authorization: `Bearer ${accessToken}`
                }
            });
            if (response.data.mediaItems.length > 0) {
                setUsersMedia([...usersMedia, ...response.data.mediaItems]);
                setPageToken(response.data.nextPageToken);
                setImagesLoading(false);
            }
        } catch (error) {
            console.log((error));
        }
    };

    // get album photos by it's id
    const getPhotosByAlbumId = async (id) => {
        try {
            setLoading(true);
            const body = {
                albumId: id
            };
            const response = await axios.post(`https://photoslibrary.googleapis.com/v1/mediaItems:search`, body, {
                headers: {
                    Accept: "application/json",
                    Authorization: `Bearer ${accessToken}`
                }
            });

            setAlbumPhotos(response.data.mediaItems === undefined ? [] : response.data.mediaItems);
            setPageToken(response.data.nextPageToken);
            setLoading(false);

        } catch (error) {
            console.log(error);
        }
    };

    // get more album photos 
    const nextAlbumImages = async () => {
        try {
            setLoading(true);
            const body = {
                pageSize: 20,
                albumId: selectedID,
                pageToken: pageToken,
            };
            const response = await axios.post('https://photoslibrary.googleapis.com/v1/mediaItems:search', body, {
                headers: {
                    Accept: "application/json",
                    Authorization: `Bearer ${accessToken}`
                }
            });
            if (response.data.mediaItems.length > 0) {
                setAlbumPhotos([...albumPhotos, ...response.data.mediaItems]);
                setPageToken(() => response.data.nextPageToken);
                setLoading(false);
            }
        } catch (error) {
            console.log(error);
        }
    };


    // get photos access tokens for api request 
    const getAccessToken = async () => {
        try {
            const jwtToken = localStorage.getItem('token');
            const decoded = jwt_decode(jwtToken);
            const user_id = decoded.id;
            const response = await axios.get(`${constant.url}google/photos/get/access/token/${user_id}`);
            if (response.data.access_token !== undefined) {
                setAccessToken(response.data.access_token);
                setSignedInUser(true);
                loadFilesAndFolders(response.data.access_token);
                getUsersMedia(response.data.access_token);
            } else {
                setAccessToken("unauthorized");
                setSignedInUser(false);
            }
        } catch (error) {
            console.log(error)
        }
    };





    const usersPhotosGallery = () => {
        return (
            <div className="photos-container">
                {loading ? <ImagesLoader /> : userAlbums.map((data, index) => {
                    return (
                        <div className="folder" onClick={() => {
                            setSelectedID(() => data.id);
                            setSelectedName(data.title);
                            getPhotosByAlbumId(data.id);
                        }}
                            key={index}
                        >
                            <img alt="folder" src={Vector} />
                            <span>{data.title}</span>
                        </div>
                    );
                })}
                <div className='scroll-container-image' style={imagesLoading ? { overflow: 'hidden' } : null} onScroll={scrollStarted}>
                    {imagesLoading ? <ImagesLoader /> : (
                        <Masonry columnsCount={2} gutter={10}>
                            {
                                usersMedia.map((data, index) => {
                                    return (
                                        <div key={index} className='image-container'>
                                            <img
                                                src={data.baseUrl}
                                                alt='photos'
                                            />
                                        </div>
                                    );
                                })
                            }
                        </Masonry>
                    )}
                </div>
            </div>
        );
    };

    const selectedAlbum = () => {
        return (
            <div className="photos-container">
                <div className="active-folder">
                    <img
                        className='arrow-left'
                        src={backIcon}
                        alt='arrowLeft'
                        onClick={() => {
                            setSelectedID('');
                        }}
                    />
                    <span>{selectedName}</span>
                </div>

                <div className='scroll-container-image' style={loading ? { overflow: 'hidden' } : null} onScroll={scrollStarted}>
                    {loading ? <ImagesLoader /> : albumPhotos.length === 0 ? (<div className="no-files">
                        <span>There's nothing here</span>
                    </div>) : (<Masonry columnsCount={2} gutter={10}>
                        {
                            albumPhotos.map((data, index) => {
                                console.log(data)
                                return (
                                    <div key={index} className='image-container'>
                                        <img
                                            src={data.baseUrl}
                                            alt='photos'
                                        />
                                    </div>
                                );
                            })
                        }
                    </Masonry>)}
                </div>
            </div>
        );
    };

    return (
        <div className='rightGooglePhotos'>
            <AppHeadingCard title="Google Photos" changeActiveApp={changeActiveApp} signOut={logout} isUserSignIn={signedInUser} login={login} authenticationRequired={true} />
            {accessToken !== "unauthorized" ? selectedID === "" ? usersPhotosGallery() : selectedAlbum() : (
                <div className="connect-photos-container">
                    <h1 className='heading'>Connect Google account</h1>
                    <div className='photos-connect-button' onClick={() => login()}>
                        <img alt="drive" src={Google} />
                        <p>{"Sign in with Google"}</p>
                    </div>
                </div>
            )}
        </div>
    );
};

export default GooglePhotos;;