import React, { useState, useEffect, useContext } from 'react';
import './Unsplash.css';
import Axios from 'axios';
import AppHeadingCard from '../app_heading_card/AppHeadingCard';
import constant from '../../constant';
import Masonry from "react-responsive-masonry";
import ImagesLoader from '../images_loader/ImagesLoader';
import CreateDataProvider from '../../store/CreateDataProvider';
import { toast } from 'react-toastify';

const Unsplash = ({ changeActiveApp }) => {
    const [images, setImages] = useState([]);
    const [loading, setLoading] = useState(true);
    const [pageNum, setPageNum] = useState(1);
    const [search, setSearch] = useState("");
    const [dataFinished, setDataFinished] = useState(false);
    const [activeImage, setactiveImage] = useState(null);

    const createDataContext = useContext(CreateDataProvider);

    // load initial images
    useEffect(() => {
        getTheImages();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    // search images function
    const getSearchPhotos = async (loadMore, searchData) => {
        try {
            if (!loadMore) {
                setImages([]);
                setPageNum(1);
                setDataFinished(false);
            }

            setLoading(true);

            if (constant.searchToken) {
                constant.searchToken.cancel(); // Cancel the previous request before making a new request
            }

            // Create a new CancelToken
            constant.searchToken = Axios.CancelToken.source();
            let query = loadMore === false ? searchData : search;

            if (query === "") {
                setImages([]);
                setPageNum(1);
                setDataFinished(false);
                return getTheImages(true);
            }

            const response = await Axios.get(
                `https://api.unsplash.com/search/photos?page=${pageNum}&query=${query}&per_page=${20}`,
                {
                    headers: {
                        Authorization:
                            'Client-ID d44fb5cd512707ed2cb87bcf6fed924f773ee43cc579d390cd0a01476d915bc3',
                    },
                    cancelToken: constant.searchToken.token
                }
            );

            // if results is less then 20 setting data finished to true to stop the execution of function 
            if (response.data.results.length < 20) {
                setDataFinished(true);
            }

            if (loadMore === false) {
                setImages(response.data.results);
            } else {
                setImages([...images, ...response.data.results]);
                setPageNum((prev) => prev + 1);
            }

            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };



    // get initial images and load more when scroll
    const getTheImages = async (noSearch) => {
        try {
            let page = noSearch ? 1 : pageNum;
            setLoading(true);
            const response = await Axios.get(
                `https://api.unsplash.com/photos?page=${page}&per_page=${20}`,
                {
                    headers: {
                        Authorization:
                            'Client-ID d44fb5cd512707ed2cb87bcf6fed924f773ee43cc579d390cd0a01476d915bc3',
                    },
                }
            );

            if (noSearch) {
                setImages(response.data);
                setLoading(false);
            } else {
                setImages([...images, ...response.data]);
                setLoading(false);
                setPageNum((prev) => prev + 1);
            }
        } catch (error) {
            setLoading(false);
        }
    };


    const scrollStarted = (e) => {
        if (e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight - 50) {
            if (loading || dataFinished) return;
            if (search !== "") {
                getSearchPhotos(true);
            } else {
                getTheImages();
            }
        }
    };

    const addImage = async (url, index) => {
        try {
            const token = localStorage.getItem('token');
            const config = { headers: { Authorization: `Bearer ${token}` } };

            setactiveImage(index);
            const res = await Axios.post(constant.url + "media/download/generate/url", {
                url: url
            }, config);
            setactiveImage(null);

            let obj = {
                id: res.data.id,
                URL: res.data.URL,
                contentType: res.data.contentType,
                type: res.data.contentType.split('/')[0],
                size: parseInt(res.data.contentLength),
                error: undefined,
            };

            createDataContext.addMediaToSchedule([obj]);
            toast.success("The image has been successfully attached");
        } catch (error) {
            setactiveImage(null);
            toast.error("The image could not be processed. Please try again or refresh the page.");
        }
    };

    return (
        <div className='rightUnsplash'>
            <AppHeadingCard title="Unsplash" changeActiveApp={changeActiveApp} />
            <div className='input-container'>
                <input type="text"
                    value={search}
                    onChange={(e) => {
                        setSearch(e.target.value);
                        getSearchPhotos(false, e.target.value);
                    }}
                    placeholder='Search images' />
            </div>
            <div className='scroll-container-image' style={loading ? { overFlow: 'hidden' } : null} onScroll={scrollStarted}>
                <Masonry columnsCount={2} gutter={10}>
                    {
                        images.map((url, index) => {
                            return <div key={index} className='image-container'>
                                <img
                                    onClick={() => addImage(url.urls.regular, index)}
                                    src={url.urls.thumb}
                                    alt='muse'
                                />
                                {activeImage === index ? <div className='overlay-effect' /> : null}
                                {activeImage === index ? <div className='loader' /> : null}
                            </div>;
                        })
                    }
                </Masonry>

                {
                    loading ? <ImagesLoader /> : null
                }
            </div>
        </div>
    );
};

export default Unsplash;