import React, { useEffect, useState } from "react";
import axios from "axios";
import './Bitmoji.css';
import jwt_decode from 'jwt-decode';
import constant from '../../constant';
import AppHeadingCard from '../app_heading_card/AppHeadingCard';
import snapchatLogo from "../../assets/app_logo_right/snapchat.svg";
import Masonry from "react-responsive-masonry";
import ImagesLoader from '../images_loader/ImagesLoader';




const Bitmoji = ({ changeActiveApp }) => {
    const [stickers, setStickers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [signedInUser, setSignedInUser] = useState();
    const [accessToken, setAccessToken] = useState("unauthorized");
    const [search, setSearch] = useState("");



    useEffect(() => {
        getAccessToken();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // get drive access tokens for api request 
    const getAccessToken = async () => {
        const jwtToken = localStorage.getItem('token');
        const decoded = jwt_decode(jwtToken);
        const user_id = decoded.id;
        const response = await axios.get(`${constant.url}snapchat/get/access/token/${user_id}`);
        if (response.data.access_token !== undefined) {
            setAccessToken(response.data.access_token);
            setSignedInUser(true);
            getBitmojiSticker(response.data.access_token);
        } else {
            setAccessToken("unauthorized");
            setSignedInUser(false);
        }
    };

    // login google drive
    const login = () => {
        const jwtToken = localStorage.getItem('token');
        const decoded = jwt_decode(jwtToken);
        const user_id = decoded.id;
        window.open(`${constant.url}snapchat/login/${user_id}/cache`, "_self");
    };

    // logout google drive
    const logout = async () => {
        const jwtToken = localStorage.getItem('token');
        const decoded = jwt_decode(jwtToken);
        const user_id = decoded.id;
        const response = await axios.get(`${constant.url}snapchat/logout/${user_id}`);
        if (response.data.data === "User logout successfully") {
            setSignedInUser(false);
            setAccessToken("unauthorized");
        }
    };


    const getBitmojiSticker = async (access_token) => {
        try {
            setLoading(true);
            const query =
                "query sticker($req:SearchStickersRequest!) {sticker {searchStickers(req:$req) {stickerResults {items {id}} bitmojiResults {items {pngLargeURL}} }}}";
            const variables = `{"req": {"searchStickersParams": {"searchText": "hello", "numberResults": 50},"stickerUserContext": {}}}`;

            const body = '{"query" : "' + query + '", "variables": ' + variables + "}";
            const response = await axios.post('https://graph.snapchat.com/graphql', body, {
                headers: { 'Authorization': 'Bearer ' + access_token, 'Content-Type': 'application/json' }
            });
            setStickers(response.data.data.sticker.searchStickers.bitmojiResults.items);
            setLoading(false);
        } catch (error) {
            console.log(error);
        }
    };

    // search stickers
    const searchStickers = async (searchValue) => {
        try {
            setLoading(true);
            if (searchValue === "") {
                return getBitmojiSticker(accessToken);
            }
            if (constant.searchToken) {
                constant.searchToken.cancel(); // Cancel the previous request before making a new request
            }
            // Create a new CancelToken
            constant.searchToken = axios.CancelToken.source();
            const query =
                "query sticker($req:SearchStickersRequest!) {sticker {searchStickers(req:$req) {stickerResults {items {id}} bitmojiResults {items {pngLargeURL}} }}}";
            const variables = `{"req": {"searchStickersParams": {"searchText": "${search === "" ? "hello" : search}", "numberResults": 50},"stickerUserContext": {}}}`;

            const body = '{"query" : "' + query + '", "variables": ' + variables + "}";
            const response = await axios.post('https://graph.snapchat.com/graphql', body, {
                headers: { 'Authorization': 'Bearer ' + accessToken, 'Content-Type': 'application/json' },
                cancelToken: constant.searchToken.token
            });
            setStickers(response.data.data.sticker.searchStickers.bitmojiResults.items);
            setLoading(false);
        } catch (error) {
            console.log(error);
        }
    };


    const usersBitmoji = () => {
        return (

            <div className='scroll-container-image' >
                <Masonry columnsCount={2} gutter={10}>
                    {
                        stickers.map((data, index) => {
                            return <div key={index} className='image-container'>
                                <img
                                    src={data.pngLargeURL}
                                    alt='muse'
                                />
                            </div>;
                        })
                    }
                </Masonry>


                {
                    loading ? <ImagesLoader /> : null
                }
            </div>
        );
    };

    const handleOnChange = (e) => {
        setSearch(e.target.value);
        searchStickers(e.target.value);
    };


    return (
        <div className="rightBitmoji">
            <AppHeadingCard title="Bitmoji" changeActiveApp={changeActiveApp} signOut={logout} isUserSignIn={signedInUser} login={login} authenticationRequired={true} />
            {accessToken !== "unauthorized" ? (
                <div className='input-container'>
                    <input type="text" placeholder='Search' onChange={handleOnChange} />
                </div>
            ) : null}


            {accessToken !== "unauthorized" ? usersBitmoji() : (
                <div onClick={() => {
                    login();
                }} className="connect-snapchat-container">
                    <h1 className='heading'>Connect Snapchat Account</h1>
                    <div className='snapchat-connect-button'>
                        <img alt="drive" src={snapchatLogo} />
                        <p>Sign in with Snapchat</p>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Bitmoji;
