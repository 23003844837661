import React, { useState, useContext, useEffect } from 'react';
import './Hashtag.css';
import CreateDataProvider from '../../store/CreateDataProvider';
import ButtonProvider from '../../store/ButtonProvider'
import axios from 'axios';
import { toast } from 'react-toastify';
import TagsCard from '../tags_card/TagsCard';
import InstaCard from '../insta_card/InstaCard';
import AppHeadingCard from '../app_heading_card/AppHeadingCard';
import constant from '../../constant';

const Hashtag = ({ changeActiveApp }) => {
    const createContext = useContext(CreateDataProvider);
    const buttonContext = useContext(ButtonProvider)

    const [activeButton, setactiveButton] = useState(1)

    const [savedLoading, setsavedLoading] = useState(false)
    const [hashtag, sethashtag] = useState([])
    const [selectedCategory, setselectedCategory] = useState("")

    const [twitterTrendsLoading, settwitterTrendsLoading] = useState(false)
    const [twitterTrends, settwitterTrends] = useState([])
    const [selectedTrendCode, setselectedTrendCode] = useState("1")
    const [attemptedSearch, setattemptedSearch] = useState(false)

    const [activeSearchButton, setactiveSearchButton] = useState(1)
    const [searchAccount, setsearchAccount] = useState("")
    const [accountsLoading, setaccountsLoading] = useState(false)
    const [searchQuery, setsearchQuery] = useState("")
    const [instagramAccounts, setinstagramAccounts] = useState([])
    const [instagramTags, setinstagramTags] = useState([])
    const [instagramTagsLoading, setinstagramTagsLoading] = useState(false)

    const [twitterSearch, settwitterSearch] = useState("")
    const [twitterTags, settwitterTags] = useState([])
    const [twitterTagsLoading, settwitterTagsLoading] = useState(false)

    useEffect(() => {
        getTheUserHashtag()
        getInstagramAccounts()
        changeTrendCode("1")
    }, [])

    const getInstagramAccounts = async () => {
        try {
            setaccountsLoading(true)
            const workspaceId = localStorage.getItem("organizationId")
            const token = localStorage.getItem('token');
            const config = { headers: { "Authorization": `Bearer ${token}` } }
            const res = await axios.get(constant.url + `accounts/${workspaceId}`, config)
            let filterInstagramAccounts = res.data.filter(data => data.accountType === "instagram")
            setinstagramAccounts(filterInstagramAccounts)
            setaccountsLoading(false)
        } catch (error) {
            setaccountsLoading(false)
        }
    }

    const getInstagramHashtag = async () => {
        try {
            if (searchQuery.trim() === "") return toast("Please enter keywords to search")

            setinstagramTagsLoading(true)
            const workspaceId = localStorage.getItem("organizationId")
            const token = localStorage.getItem('token');
            const config = { headers: { "Authorization": `Bearer ${token}` } }

            await axios.post(`https://api.app.creatosaurus.io/creatosaurus/userfeaturefactory/hashtag/remove/credit/instagram`, {
                id: workspaceId
            }, config)

            const res = await axios.get(`https://api.cache.creatosaurus.io/cache/hashtag/instagram/hashtag/${searchAccount}/${workspaceId}?query=${searchQuery}`, config)
            setinstagramTags(res.data.hashTags)
            setinstagramTagsLoading(false)
        } catch (error) {
            if (error.response.data.error === "credit over") {
                toast("You don't have any credits to search hashtags, please upgrade to search more.")
            }
            setinstagramTagsLoading(false)
        }
    }

    const getTheUserHashtag = async () => {
        try {
            setsavedLoading(true);
            const workspaceId = localStorage.getItem('organizationId');
            const token = localStorage.getItem('token');
            const config = {
                headers: { Authorization: `Bearer ${token}` },
            };

            const response = await axios.get(
                ' https://6cgnepgh03.execute-api.ap-south-1.amazonaws.com/latest/hashtag/' +
                workspaceId,
                config
            );

            sethashtag(response.data);
            setsavedLoading(false);
        } catch (error) {
            setsavedLoading(false);
        }
    }

    const changeActiveButton = (value) => {
        setactiveButton(value)
    }

    const changeTrendCode = async (value) => {
        try {
            setselectedTrendCode(value)
            settwitterTrendsLoading(true)
            const token = localStorage.getItem('token');
            const config = { headers: { "Authorization": `Bearer ${token}` } }
            let res = await axios.get(`https://api.cache.creatosaurus.io/cache/twitter/trends?woeid=${value}`, config)
            settwitterTrends(res.data[0].trends)
            settwitterTrendsLoading(false)
        } catch (error) {
            settwitterTrendsLoading(false)
        }
    }

    const searchTwitter = async () => {
        try {
            if (createContext.userData.featureFactoryData.planId.planName === "free") return toast("You don't have any credits to search hashtags, please upgrade to search more.")
            if (twitterSearch.trim() === "") return toast("Plese enter keyword to search")
            if (createContext.userData.featureFactoryData.hashtagSearch.toString() === "0") return toast("You don't have any credits to search hashtags, please upgrade to search more.")

            settwitterTagsLoading(true)
            settwitterTags([])
            setattemptedSearch(true)
            const token = localStorage.getItem('token');
            const config = { headers: { "Authorization": `Bearer ${token}` } }

            await axios.post(`https://api.app.creatosaurus.io/creatosaurus/userfeaturefactory/hashtag/remove/credit`, {
                id: localStorage.getItem('organizationId')
            }, config)

            const res = await axios.get(`https://6cgnepgh03.execute-api.ap-south-1.amazonaws.com/latest/hashtag/tags/search?query=${twitterSearch}`, config)
            settwitterTags(res.data)
            settwitterTagsLoading(false)
        } catch (error) {
            console.log(error)
            toast(error.response.data.error)
            settwitterTagsLoading(false)
        }
    }

    const connectInstaAccount = (e) => {
        if (e.target.value === "connect") return callAccounts()
        setsearchAccount(e.target.value)
    }

    const callAccounts = () => {
        buttonContext.changeLeftButton(5)
        buttonContext.changeCenterButton("accounts")
        buttonContext.changeRightButton("rightConnectAccounts")
    }

    return (
        <React.Fragment>
            {
                <div className='hashtag-container'>
                    <AppHeadingCard title="#Tags" changeActiveApp={changeActiveApp} />

                    <div className='button-container'>
                        <button
                            className={activeButton === 1 ? 'active' : null}
                            onClick={() => changeActiveButton(1)}>Search</button>
                        <button
                            className={activeButton === 2 ? 'active' : null}
                            onClick={() => changeActiveButton(2)}>Saved</button>
                        {/*<button
                            className={activeButton === 3 ? 'active' : null}
            onClick={() => changeActiveButton(3)}>Twitter Trends</button>*/}
                    </div>

                    <React.Fragment>
                        {
                            activeButton === 1 ? <div>
                                <div className='sub-button-container'>
                                    <button onClick={() => setactiveSearchButton(1)} className={activeSearchButton === 1 ? "active" : null}>Instagram Tags</button>
                                    {/*<button onClick={() => setactiveSearchButton(2)} className={activeSearchButton === 2 ? "active" : null}>Twitter Tags</button>*/}
                                </div>
                                <React.Fragment>
                                    {
                                        activeSearchButton === 1 ? <React.Fragment>
                                            <select value={searchAccount} className='insta-select' onChange={connectInstaAccount}>
                                                <option value="" disabled>Select Instagram bussiness</option>
                                                {
                                                    instagramAccounts.map((data, index) => {
                                                        return <option key={index} value={data.socialId}>{data.name}</option>
                                                    })
                                                }
                                                <option value="connect">Connect new Instagram bussiness account</option>
                                            </select>
                                            <div className='input-container'>
                                                <input maxLength="30"
                                                    placeholder="Search words & keywords"
                                                    onChange={(e) => setsearchQuery(e.target.value)}
                                                    onKeyDown={(e) => {
                                                        if (e.key === "Enter") {
                                                            getInstagramHashtag()
                                                        }
                                                    }}
                                                    value={searchQuery} />
                                                <button
                                                    onClick={getInstagramHashtag}
                                                    className={searchAccount === "" || searchQuery.trim() === "" ? null : 'active2'}>
                                                    {
                                                        accountsLoading ? <div className='loader' style={{ height: 18, width: 18, borderWidth: 2 }} /> :
                                                            <React.Fragment>
                                                                {
                                                                    searchAccount === "" || searchQuery.trim() === "" ?
                                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M15.5 15.5L19 19" stroke="#808080" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                                            <path d="M5 11C5 14.3137 7.68629 17 11 17C12.6597 17 14.1621 16.3261 15.2483 15.237C16.3308 14.1517 17 12.654 17 11C17 7.68629 14.3137 5 11 5C7.68629 5 5 7.68629 5 11Z" stroke="#808080" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                                        </svg>
                                                                        : <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" strokeWidth="1.5" viewBox="0 0 24 24" fill="none">
                                                                            <path d="M15.5 15.5L19 19" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
                                                                            <path d="M5 11C5 14.3137 7.68629 17 11 17C12.6597 17 14.1621 16.3261 15.2483 15.237C16.3308 14.1517 17 12.654 17 11C17 7.68629 14.3137 5 11 5C7.68629 5 5 7.68629 5 11Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
                                                                        </svg>
                                                                }
                                                            </React.Fragment>
                                                    }
                                                </button>
                                            </div>

                                            <div className='search-scroll-insta'>
                                                {
                                                    instagramTagsLoading ? <div className='loading1'>Loading . . .</div> :
                                                        <React.Fragment>
                                                            {
                                                                instagramTags.map((data, index) => {
                                                                    return <InstaCard key={data.tag + index} data={data} />
                                                                })
                                                            }
                                                        </React.Fragment>
                                                }
                                            </div>
                                        </React.Fragment> : <React.Fragment>
                                            <div className='input-container'>
                                                <input maxLength="30"
                                                    placeholder="Search words & keywords"
                                                    onChange={(e) => settwitterSearch(e.target.value)}
                                                    onKeyDown={(e) => {
                                                        if (e.key === "Enter") {
                                                            searchTwitter()
                                                        }
                                                    }}
                                                    value={twitterSearch} />
                                                <button
                                                    onClick={searchTwitter}
                                                    className={twitterSearch.trim() === "" ? null : 'active2'}>
                                                    {
                                                        accountsLoading ? <div className='loader' style={{ height: 18, width: 18, borderWidth: 2 }} /> :
                                                            <React.Fragment>
                                                                {
                                                                    twitterSearch.trim() === "" ?
                                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M15.5 15.5L19 19" stroke="#808080" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                                            <path d="M5 11C5 14.3137 7.68629 17 11 17C12.6597 17 14.1621 16.3261 15.2483 15.237C16.3308 14.1517 17 12.654 17 11C17 7.68629 14.3137 5 11 5C7.68629 5 5 7.68629 5 11Z" stroke="#808080" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                                        </svg>
                                                                        : <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" strokeWidth="1.5" viewBox="0 0 24 24" fill="none">
                                                                            <path d="M15.5 15.5L19 19" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
                                                                            <path d="M5 11C5 14.3137 7.68629 17 11 17C12.6597 17 14.1621 16.3261 15.2483 15.237C16.3308 14.1517 17 12.654 17 11C17 7.68629 14.3137 5 11 5C7.68629 5 5 7.68629 5 11Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
                                                                        </svg>
                                                                }
                                                            </React.Fragment>
                                                    }
                                                </button>
                                            </div>

                                            <div className='search-scroll-twitter'>
                                                {
                                                    twitterTagsLoading ?
                                                        <div className='loading1'>Loading . . .</div> :
                                                        <React.Fragment>
                                                            {
                                                                twitterTags.length === 0 && attemptedSearch ? <div style={{ fontSize: 14, marginTop: 10 }}>No search results were found. Please check your keyword or spelling and try again.</div> :
                                                                    twitterTags.map((data, index) => {
                                                                        return <TagsCard key={index} data={data} />
                                                                    })
                                                            }
                                                        </React.Fragment>
                                                }
                                            </div>
                                        </React.Fragment>
                                    }
                                </React.Fragment>
                            </div> : null
                        }
                    </React.Fragment>

                    {/* Saved container code */}
                    <React.Fragment>
                        {
                            activeButton === 2 ?
                                <React.Fragment>
                                    <select value={selectedCategory} onChange={(e) => setselectedCategory(e.target.value)}>
                                        <option value="">All</option>
                                        {
                                            hashtag.map(data => {
                                                return <option key={data._id} value={data.groupName}>{data.groupName}</option>
                                            })
                                        }
                                    </select>
                                    <div className='scroll-saved'>
                                        {
                                            savedLoading ?
                                                <div className='loading1'>Loading . . .</div> :
                                                hashtag.length === 0 ?
                                                    <div className='create'>You don't have any hashtags saved yet.</div> :
                                                    <React.Fragment>
                                                        {
                                                            hashtag.map(data => {
                                                                if (selectedCategory === "") {
                                                                    return <div className='card'>
                                                                        <span>{data.groupName}</span>
                                                                        <p>
                                                                            {
                                                                                data.tags.map(tag => {
                                                                                    return tag + " "
                                                                                })
                                                                            }
                                                                        </p>
                                                                    </div>
                                                                } else if (selectedCategory === data.groupName) {
                                                                    return <div className='card'>
                                                                        <span>{data.groupName}</span>
                                                                        <p>
                                                                            {
                                                                                data.tags.map(tag => {
                                                                                    return tag + " "
                                                                                })
                                                                            }
                                                                        </p>
                                                                    </div>
                                                                } else {
                                                                    return null
                                                                }
                                                            })
                                                        }
                                                    </React.Fragment>
                                        }
                                    </div>
                                </React.Fragment> : null
                        }
                    </React.Fragment>

                    {/* Twitter trends code */}
                    <React.Fragment>
                        {
                            activeButton === 3 ? <React.Fragment>
                                <select value={selectedTrendCode} onChange={(e) => changeTrendCode(e.target.value)}>
                                    <option value="1">World Wide</option>
                                    <option value="23424848">India</option>
                                    <option value="23424977">US</option>
                                    <option value="23424748">Australia</option>
                                    <option value="23424975">UK</option>
                                    <option value="23424775">Canada</option>
                                </select>
                                <div className='scroll-saved'>
                                    {
                                        twitterTrendsLoading ?
                                            <div className='loading1'>Loading . . .</div> :
                                            <div>
                                                {
                                                    twitterTrends.map((data, index) => {
                                                        return <div key={index} className='card'>
                                                            <span>{data.name}</span>
                                                        </div>
                                                    })
                                                }
                                            </div>
                                    }
                                </div>
                            </React.Fragment> : null
                        }
                    </React.Fragment>
                </div>
            }
        </React.Fragment>
    )
}

export default Hashtag