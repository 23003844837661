import React from 'react';
import './AppDetail.css';

const AppDetails = (props) => {
    return (
        <div className='appDetails-main-container'>
            <div className='header-container'>
                <div className='center'>
                    <svg onClick={() => props.goBack()} width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M22.2857 13H6.5M6.5 13L13 6.5M6.5 13L13 19.5" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                    <span>App details</span>
                </div>
            </div>
            <div className='app-logo-container'>
                <div className='app-logo' style={props.data.color ? { backgroundColor: props.data.color } : { backgroundColor: '#3D98BB' }} >
                    {
                        props.data.logo ? <img alt="appLogo" src={props.data.logo} /> : <></>
                    }
                </div>
                <div className='app-header-container'>
                    <span>{props.appname}</span>
                    <p>by <span style={{
                        cursor: "pointer"
                    }} onClick={() => {
                        window.open(props.data.url);
                    }}>{props.data.title}</span></p>
                </div>
            </div>
            <p className='app-details-description'>{props.data.desc}</p>
            <p className='app-details-description'>{props.data.longDesc}</p>
            <br />
            <a href="https://www.creatosaurus.io/apps" target="_blank" rel="noreferrer"> <span className='app-store-link'>Creatosaurus App Store Page</span><br /></a>
            <a href={props.data.url} target="_blank" rel="noreferrer">{props.data.title}</a>
            <div className="privacy-policy">
                <a href={props.data.privacy} target="_blank" rel="noreferrer">{props.data.title} Privacy Policy</a><br />
                <a href={props.data.terms} target="_blank" rel="noreferrer">{props.data.title} Terms of Service</a><br />
                <a href="https://www.creatosaurus.io/privacy" target="_blank" rel="noreferrer">Creatosaurus Privacy Policy</a><br />
                <a href="https://creatosaurus.io/terms" target="_blank" rel="noreferrer">Creatosaurus Terms of Service</a>
            </div>
        </div>
    );


};

export default AppDetails;