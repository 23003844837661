import Header from '@editorjs/header';
import List from '@editorjs/list';
import Quote from '@editorjs/quote';
import AlignmentTuneTool from 'editorjs-text-alignment-blocktune';
import Paragraph from '@editorjs/paragraph';
import Checklist from '@editorjs/checklist'
import ImageTool from '@editorjs/image';
import AttachesTool from '@editorjs/attaches';
import Embed from '@editorjs/embed';
import VideoTool from '@weekwood/editorjs-video';
import CodeTool from '@editorjs/code'
import Table from '@editorjs/table';
import AnyButton from 'editorjs-button';
import ColorPlugin from 'editorjs-text-color-plugin'
import Marker from '@editorjs/marker'
import Underline from '@editorjs/underline';
import Strikethrough from '@sotaproject/strikethrough';
import InlineCode from '@editorjs/inline-code'
import WriteMore from '../editor_custom_tools/write_more/WriteMore';
import Outline from '../editor_custom_tools/outline/Outline';
import Intro from '../editor_custom_tools/intro/Intro'
import axios from 'axios';
import constant from '../constant'





const uploadImageToS3 = async (file) => {
    try {
        const token = localStorage.getItem('token');
        const config = { headers: { Authorization: `Bearer ${token}` } };
        const response = await axios.get(`${constant.stenoURL}/image/generate/url`, config);
        const url = await response.data.uploadURL;

        await axios.request({
            method: "PUT",
            headers: {
                "Content-Type": file.type
            },
            url: url,
            data: file,
        })

        const imageURL = response.data.uploadURL.split('?')[0]

        return imageURL
    } catch (error) {
        console.log(error)
    }
}

const getDomainName = (url) => {
    const domain = url.split('/')[2];
    return domain;
};

const downloadAndUploadImageToS3 = async (url) => {
    try {
        let imageUrl = '';

        if (getDomainName(url) === 'images.pexels.com') {
            imageUrl = url + '?auto=compress&h=auto&w=1600';
        } else {
            imageUrl = url;
        }
        const token = localStorage.getItem('token');
        const config = { headers: { Authorization: `Bearer ${token}` } };
        const response = await axios.post(`${constant.stenoURL}/media/download/generate/url`, { url: imageUrl }, config)
        return response.data.URL
    } catch (error) {
        console.log(error)
    }
}



const editorJsTools = {
    underline: Underline,
    list: {
        class: List,
        inlineToolbar: true,
        tunes: ['anyTuneName'],
    },
    quote: {
        class: Quote,
        inlineToolbar: ['link', 'textColor', 'underline', 'bold', 'strikethrough'],
        shortcut: 'CMD+SHIFT+O',
        config: {
            quotePlaceholder: 'Enter a quote',
            captionPlaceholder: 'Quote\'s author',
        },
    },
    header: {
        class: Header,
        inlineToolbar: ['link', 'textColor', 'underline', 'bold', 'strikethrough', 'inlineCode'],
        tunes: ['anyTuneName'],
    },
    paragraph: {
        class: Paragraph,
        inlineToolbar: ['link', 'textColor', 'underline', 'bold', 'strikethrough', 'inlineCode', 'writeMore'],
        tunes: ['anyTuneName'],
    },
    checklist: {
        class: Checklist,
        inlineToolbar: ['link', 'textColor', 'underline', 'bold', 'strikethrough'],
    },
    strikethrough: {
        class: Strikethrough,
        inlineToolbar: ['link', 'textColor', 'underline', 'bold'],
    },
    Marker: {
        class: Marker
    },
    inlineCode: {
        class: InlineCode,
        shortcut: 'CMD+SHIFT+M',
    },
    image: {
        class: ImageTool,
        config: {
            uploader: {
                uploadByFile: async (file) => {
                    const url = await uploadImageToS3(file);
                    return {
                        success: 1,
                        file: {
                            url: url,
                        },
                    };
                },
                uploadByUrl: async (url) => {
                    const imageURL = await downloadAndUploadImageToS3(url)
                    return {
                        success: 1,
                        file: {
                            url: imageURL,
                        },
                    }
                }
            },

        },
    },
    video: {
        class: VideoTool,
        config: {
            uploader: {
                uploadByFile: async (file) => {
                    const url = await uploadImageToS3(file);
                    return {
                        success: 1,
                        file: {
                            url: url,
                        },
                    };
                },

            },
            player: {
                controls: true,
                autoplay: false
            }
        },
    },
    attaches: {
        class: AttachesTool,
        config: {
            uploader: {
                uploadByFile: async (file) => {
                    const url = await uploadImageToS3(file);
                    return {
                        success: 1,
                        file: {
                            url: url,
                        },
                    };
                },
            },
        },
    },
    embed: {
        class: Embed,
        inlineToolbar: true
    },
    table: {
        class: Table,
        inlineToolbar: true,
        config: {
            rows: 2,
            cols: 3,
        },
    },
    code: CodeTool,
    AnyButton: {
        class: AnyButton,
        inlineToolbar: false,
        config: {
            css: {
                "btnColor": "btn--gray",
            }
        }
    },
    textColor: {
        class: ColorPlugin,
    },
    anyTuneName: {
        class: AlignmentTuneTool,
        config: {
            default: "left",
            blocks: {
                header: 'left',
                list: 'left',
                paragraph: 'left'
            }
        },
    },
    // ai custom tools
    writeMore: {
        class: WriteMore
    },
    outline: {
        class: Outline
    },
    intro: {
        class: Intro,
    },
}



export default editorJsTools