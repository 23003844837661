import React from 'react'
import './RightDraftLoading.css'

const RightDraftLoading = () => {
    return (
        <div className="right-draft-loading-container">
            <div className="skeleton top-side"></div>
            <div className="bottom-side">
                <div className="skeleton createdon" />
                <div className="options">
                    <div className="skeleton option" />
                    <div className="skeleton option" />
                </div>
            </div>
        </div>
    );
}

export default RightDraftLoading;