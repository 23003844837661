import React from 'react'
import './CountryPopup.css'

const CountryPopup = ({ handleCountries, selectedCountry, activeTab }) => {
    const countries = [
        { country: "All Global Locations", code: "GB", flag: "🌏" },
        { country: "Afghanistan", code: "af", flag: "🇦🇫", location_code: 2004 },
        { country: "Albania", code: "al", flag: "🇦🇱", location_code: 2008 },
        { country: "Algeria", code: "dz", flag: "🇩🇿", location_code: 2012 },
        { country: "American Samoa", code: "as", flag: "🇦🇸", location_code: 2016 },
        { country: "Andorra", code: "ad", flag: "🇦🇩", location_code: 2020 },
        { country: "Angola", code: "ao", flag: "🇦🇴", location_code: 2024 },
        { country: "Antarctica", code: "aq", flag: "🇦🇶", location_code: 2010 },
        { country: "Antigua and Barbuda", code: "ag", flag: "🇦🇬" },
        { country: "Argentina", code: "ar", flag: "🇦🇷" },
        { country: "Armenia", code: "am", flag: "🇦🇲" },
        { country: "Aruba", code: "aw", flag: "🇦🇼" },
        { country: "Australia", code: "au", flag: "🇦🇺" },
        { country: "Austria", code: "at", flag: "🇦🇹" },
        { country: "Azerbaijan", code: "az", flag: "🇦🇿" },
        { country: "Bahamas", code: "bs", flag: "🇧🇸" },
        { country: "Bahrain", code: "bh", flag: "🇧🇭" },
        { country: "Bangladesh", code: "bd", flag: "🇧🇩" },
        { country: "Barbados", code: "bb", flag: "🇧🇧" },
        { country: "Belarus", code: "by", flag: "🇧🇾" },
        { country: "Belgium", code: "be", flag: "🇧🇪" },
        { country: "Belize", code: "bz", flag: "🇧🇿" },
        { country: "Benin", code: "bj", flag: "🇧🇯" },
        { country: "Bermuda", code: "bm", flag: "🇧🇲" },
        { country: "Bhutan", code: "bt", flag: "🇧🇹" },
        { country: "Bolivia", code: "bo", flag: "🇧🇴" },
        { country: "Bosnia and Herzegovina", code: "ba", flag: "🇧🇦" },
        { country: "Botswana", code: "bw", flag: "🇧🇼" },
        { country: "Bouvet Island", code: "bv", flag: "🇧🇻" },
        { country: "Brazil", code: "br", flag: "🇧🇷" },
        { country: "British Indian Ocean Territory", code: "io", flag: "🇮🇴" },
        { country: "Brunei Darussalam", code: "bn", flag: "🇧🇳" },
        { country: "Bulgaria", code: "bg", flag: "🇧🇬" },
        { country: "Burkina Faso", code: "bf", flag: "🇧🇫" },
        { country: "Burundi", code: "bi", flag: "🇧🇮" },
        { country: "Cambodia", code: "kh", flag: "🇰🇭" },
        { country: "Cameroon", code: "cm", flag: "🇨🇲" },
        { country: "Canada", code: "ca", flag: "🇨🇦" },
        { country: "Cape Verde", code: "cv", flag: "🇨🇻" },
        { country: "Cayman Islands", code: "ky", flag: "🇰🇾" },
        { country: "Central African Republic", code: "cf", flag: "🇨🇫" },
        { country: "Chad", code: "td", flag: "🇹🇩" },
        { country: "Chile", code: "cl", flag: "🇨🇱" },
        { country: "China", code: "cn", flag: "🇨🇳" },
        { country: "Christmas Island", code: "cx", flag: "🇨🇽" },
        { country: "Cocos (Keeling) Islands", code: "cc", flag: "🇨🇨" },
        { country: "Colombia", code: "co", flag: "🇨🇴" },
        { country: "Comoros", code: "km", flag: "🇰🇲" },
        { country: "Congo", code: "cg", flag: "🇨🇬" },
        { country: "Congo, the Democratic Republic of the", code: "cd", flag: "🇨🇩" },
        { country: "Cook Islands", code: "ck", flag: "🇨🇰" },
        { country: "Costa Rica", code: "cr", flag: "🇨🇷" },
        { country: "Cote D'ivoire", code: "ci", flag: "🇨🇮" },
        { country: "Croatia", code: "hr", flag: "🇭🇷" },
        { country: "Cuba", code: "cu", flag: "🇨🇺" },
        { country: "Cyprus", code: "cy", flag: "🇨🇾" },
        { country: "Czech Republic", code: "cz", flag: "🇨🇿" },
        { country: "Denmark", code: "dk", flag: "🇩🇰" },
        { country: "Djibouti", code: "dj", flag: "🇩🇯" },
        { country: "Dominica", code: "dm", flag: "🇩🇲" },
        { country: "Dominican Republic", code: "do", flag: "🇩🇴" },
        { country: "Ecuador", code: "ec", flag: "🇪🇨" },
        { country: "Egypt", code: "eg", flag: "🇪🇬" },
        { country: "El Salvador", code: "sv", flag: "🇸🇻" },
        { country: "Equatorial Guinea", code: "gq", flag: "🇬🇶" },
        { country: "Eritrea", code: "er", flag: "🇪🇷" },
        { country: "Estonia", code: "ee", flag: "🇪🇪" },
        { country: "Ethiopia", code: "et", flag: "🇪🇹" },
        { country: "Falkland Islands (Malvinas)", code: "fk", flag: "🇫🇰" },
        { country: "Faroe Islands", code: "fo", flag: "🇫🇴" },
        { country: "Fiji", code: "fj", flag: "🇫🇯" },
        { country: "Finland", code: "fi", flag: "🇫🇮" },
        { country: "France", code: "fr", flag: "🇫🇷" },
        { country: "French Guiana", code: "gf", flag: "🇬🇫" },
        { country: "French Polynesia", code: "pf", flag: "🇵🇫" },
        { country: "French Southern Territories", code: "tf", flag: "🇹🇫" },
        { country: "Gabon", code: "ga", flag: "🇬🇦" },
        { country: "Gambia", code: "gm", flag: "🇬🇲" },
        { country: "Georgia", code: "ge", flag: "🇬🇪" },
        { country: "Germany", code: "de", flag: "🇩🇪" },
        { country: "Ghana", code: "gh", flag: "🇬🇭" },
        { country: "Gibraltar", code: "gi", flag: "🇬🇮" },
        { country: "Greece", code: "gr", flag: "🇬🇷" },
        { country: "Greenland", code: "gl", flag: "🇬🇱" },
        { country: "Grenada", code: "gd", flag: "🇬🇩" },
        { country: "Guadeloupe", code: "gp", flag: "🇬🇵" },
        { country: "Guam", code: "gu", flag: "🇬🇺" },
        { country: "Guatemala", code: "gt", flag: "🇬🇹" },
        { country: "Guernsey", code: "gg", flag: "🇬🇬" },
        { country: "Guinea", code: "gn", flag: "🇬🇳" },
        { country: "Guinea-bissau", code: "gw", flag: "🇬🇼" },
        { country: "Guyana", code: "gy", flag: "🇬🇾" },
        { country: "Haiti", code: "ht", flag: "🇭🇹" },
        { country: "Heard Island and Mcdonald Islands", code: "hm", flag: "🇭🇲" },
        { country: "Holy See (Vatican City State)", code: "va", flag: "🇻🇦" },
        { country: "Honduras", code: "hn", flag: "🇭🇳" },
        { country: "Hong Kong", code: "hk", flag: "🇭🇰" },
        { country: "Hungary", code: "hu", flag: "🇭🇺" },
        { country: "Iceland", code: "is", flag: "🇮🇸" },
        { country: "India", code: "in", flag: "🇮🇳" },
        { country: "Indonesia", code: "id", flag: "🇮🇩" },
        { country: "Iran, Islamic Republic Of", code: "ir", flag: "🇮🇷" },
        { country: "Iraq", code: "iq", flag: "🇮🇶" },
        { country: "Ireland", code: "ie", flag: "🇮🇪" },
        { country: "Isle of Man", code: "im", flag: "🇮🇲" },
        { country: "Israel", code: "il", flag: "🇮🇱" },
        { country: "Italy", code: "it", flag: "🇮🇹" },
        { country: "Jamaica", code: "jm", flag: "🇯🇲" },
        { country: "Japan", code: "jp", flag: "🇯🇵" },
        { country: "Jersey", code: "je", flag: "🇯🇪" },
        { country: "Jordan", code: "jo", flag: "🇯🇴" },
        { country: "Kazakhstan", code: "kz", flag: "🇰🇿" },
        { country: "Kenya", code: "ke", flag: "🇰🇪" },
        { country: "Kiribati", code: "ki", flag: "🇰🇮" },
        { country: "Korea, Democratic People'S Republic of", code: "kp", flag: "🇰🇵" },
        { country: "Korea, Republic of", code: "kr", flag: "🇰🇷" },
        { country: "Kuwait", code: "kw", flag: "🇰🇼" },
        { country: "Kyrgyzstan", code: "kg", flag: "🇰🇬" },
        { country: "Lao People'S Democratic Republic", code: "la", flag: "🇱🇦" },
        { country: "Latvia", code: "lv", flag: "🇱🇻" },
        { country: "Lebanon", code: "lb", flag: "🇱🇧" },
        { country: "Lesotho", code: "ls", flag: "🇱🇸" },
        { country: "Liberia", code: "lr", flag: "🇱🇷" },
        { country: "Libyan Arab Jamahiriya", code: "ly", flag: "🇱🇾" },
        { country: "Liechtenstein", code: "li", flag: "🇱🇮" },
        { country: "Lithuania", code: "lt", flag: "🇱🇹" },
        { country: "Luxembourg", code: "lu", flag: "🇱🇺" },
        { country: "Macao", code: "mo", flag: "🇲🇴" },
        { country: "Macedonia, The Former Yugoslav Republic of", code: "mk", flag: "🇲🇰" },
        { country: "Madagascar", code: "mg", flag: "🇲🇬" },
        { country: "Malawi", code: "mw", flag: "🇲🇼" },
        { country: "Malaysia", code: "my", flag: "🇲🇾" },
        { country: "Maldives", code: "mv", flag: "🇲🇻" },
        { country: "Mali", code: "ml", flag: "🇲🇱" },
        { country: "Malta", code: "mt", flag: "🇲🇹" },
        { country: "Marshall Islands", code: "mh", flag: "🇲🇭" },
        { country: "Martinique", code: "mq", flag: "🇲🇶" },
        { country: "Mauritania", code: "mr", flag: "🇲🇷" },
        { country: "Mauritius", code: "mu", flag: "🇲🇺" },
        { country: "Mayotte", code: "yt", flag: "🇾🇹" },
        { country: "Mexico", code: "mx", flag: "🇲🇽" },
        { country: "Micronesia, Federated States of", code: "fm", flag: "🇫🇲" },
        { country: "Moldova, Republic of", code: "md", flag: "🇲🇩" },
        { country: "Monaco", code: "mc", flag: "🇲🇨" },
        { country: "Mongolia", code: "mn", flag: "🇲🇳" },
        { country: "Montenegro", code: "me", flag: "🇲🇪" },
        { country: "Montserrat", code: "ms", flag: "🇲🇸" },
        { country: "Morocco", code: "ma", flag: "🇲🇦" },
        { country: "Mozambique", code: "mz", flag: "🇲🇿" },
        { country: "Myanmar", code: "mm", flag: "🇲🇲" },
        { country: "Namibia", code: "na", flag: "🇳🇦" },
        { country: "Nauru", code: "nr", flag: "🇳🇷" },
        { country: "Nepal", code: "np", flag: "🇳🇵" },
        { country: "Netherlands", code: "nl", flag: "🇳🇱" },
        { country: "New Caledonia", code: "nc", flag: "🇳🇨" },
        { country: "New Zealand", code: "nz", flag: "🇳🇿" },
        { country: "Nicaragua", code: "ni", flag: "🇳🇮" },
        { country: "Niger", code: "ne", flag: "🇳🇪" },
        { country: "Nigeria", code: "ng", flag: "🇳🇬" },
        { country: "Niue", code: "nu", flag: "🇳🇺" },
        { country: "Norfolk Island", code: "nf", flag: "🇳🇫" },
        { country: "Northern Mariana Islands", code: "mp", flag: "🇲🇵" },
        { country: "Norway", code: "no", flag: "🇳🇴" },
        { country: "Oman", code: "om", flag: "🇴🇲" },
        { country: "Pakistan", code: "pk", flag: "🇵🇰" },
        { country: "Palau", code: "pw", flag: "🇵🇼" },
        { country: "Palestinian Territory, Occupied", code: "ps", flag: "🇵🇸" },
        { country: "Panama", code: "pa", flag: "🇵🇦" },
        { country: "Papua New Guinea", code: "pg", flag: "🇵🇬" },
        { country: "Paraguay", code: "py", flag: "🇵🇾" },
        { country: "Peru", code: "pe", flag: "🇵🇪" },
        { country: "Philippines", code: "ph", flag: "🇵🇭" },
        { country: "Pitcairn", code: "pn", flag: "🇵🇳" },
        { country: "Poland", code: "pl", flag: "🇵🇱" },
        { country: "Portugal", code: "pt", flag: "🇵🇹" },
        { country: "Puerto Rico", code: "pr", flag: "🇵🇷" },
        { country: "Qatar", code: "qa", flag: "🇶🇦" },
        { country: "Reunion", code: "re", flag: "🇷🇪" },
        { country: "Romania", code: "ro", flag: "🇷🇴" },
        { country: "Russian Federation", code: "ru", flag: "🇷🇺" },
        { country: "Rwanda", code: "rw", flag: "🇷🇼" },
        { country: "Saint Barthelemy", code: "bl", flag: "🇧🇱" },
        { country: "Saint Helena, Ascension and Tristan Da Cunha", code: "sh", flag: "🇸🇭" },
        { country: "Saint Kitts and Nevis", code: "kn", flag: "🇰🇳" },
        { country: "Saint Lucia", code: "lc", flag: "🇱🇨" },
        { country: "Saint Martin", code: "mf", flag: "🇲🇫" },
        { country: "Saint Pierre and Miquelon", code: "pm", flag: "🇵🇲" },
        { country: "Saint Vincent and the Grenadines", code: "vc", flag: "🇻🇨" },
        { country: "Samoa", code: "ws", flag: "🇼🇸" },
        { country: "San Marino", code: "sm", flag: "🇸🇲" },
        { country: "Sao Tome and Principe", code: "st", flag: "🇸🇹" },
        { country: "Saudi Arabia", code: "sa", flag: "🇸🇦" },
        { country: "Senegal", code: "sn", flag: "🇸🇳" },
        { country: "Serbia", code: "rs", flag: "🇷🇸" },
        { country: "Seychelles", code: "sc", flag: "🇸🇨" },
        { country: "Sierra Leone", code: "sl", flag: "🇸🇱" },
        { country: "Singapore", code: "sg", flag: "🇸🇬" },
        { country: "Sint Maarten", code: "sx", flag: "🇸🇽" },
        { country: "Slovakia", code: "sk", flag: "🇸🇰" },
        { country: "Slovenia", code: "si", flag: "🇸🇮" },
        { country: "Solomon Islands", code: "sb", flag: "🇸🇧" },
        { country: "Somalia", code: "so", flag: "🇸🇴" },
        { country: "South Africa", code: "za", flag: "🇿🇦" },
        { country: "South Georgia and the South Sandwich Islands", code: "gs", flag: "🇬🇸" },
        { country: "South Sudan", code: "ss", flag: "🇸🇸" },
        { country: "Spain", code: "es", flag: "🇪🇸" },
        { country: "Sri Lanka", code: "lk", flag: "🇱🇰" },
        { country: "Sudan", code: "sd", flag: "🇸🇩" },
        { country: "Suriname", code: "sr", flag: "🇸🇷" },
        { country: "Svalbard and Jan Mayen", code: "sj", flag: "🇸🇯" },
        { country: "Swaziland", code: "sz", flag: "🇸🇿" },
        { country: "Sweden", code: "se", flag: "🇸🇪" },
        { country: "Switzerland", code: "ch", flag: "🇨🇭" },
        { country: "Syrian Arab Republic", code: "sy", flag: "🇸🇾" },
        { country: "Taiwan, Province of China", code: "tw", flag: "🇹🇼" },
        { country: "Tajikistan", code: "tj", flag: "🇹🇯" },
        { country: "Tanzania, United Republic of", code: "tz", flag: "🇹🇿" },
        { country: "Thailand", code: "th", flag: "🇹🇭" },
        { country: "Timor-leste", code: "tl", flag: "🇹🇱" },
        { country: "Togo", code: "tg", flag: "🇹🇬" },
        { country: "Tokelau", code: "tk", flag: "🇹🇰" },
        { country: "Tonga", code: "to", flag: "🇹🇴" },
        { country: "Trinidad and Tobago", code: "tt", flag: "🇹🇹" },
        { country: "Tunisia", code: "tn", flag: "🇹🇳" },
        { country: "Turkey", code: "tr", flag: "🇹🇷" },
        { country: "Turkmenistan", code: "tm", flag: "🇹🇲" },
        { country: "Turks and Caicos Islands", code: "tc", flag: "🇹🇨" },
        { country: "Tuvalu", code: "tv", flag: "🇹🇻" },
        { country: "Uganda", code: "ug", flag: "🇺🇬" },
        { country: "Ukraine", code: "ua", flag: "🇺🇦" },
        { country: "United Arab Emirates", code: "ae", flag: "🇦🇪" },
        { country: "United Kingdom", code: "gb", flag: "🇬🇧" },
        { country: "United States", code: "us", flag: "🇺🇸" },
        { country: "Uruguay", code: "uy", flag: "🇺🇾" },
        { country: "Uzbekistan", code: "uz", flag: "🇺🇿" },
        { country: "Vanuatu", code: "vu", flag: "🇻🇺" },
        { country: "Venezuela", code: "ve", flag: "🇻🇪" },
        { country: "Vietnam", code: "vn", flag: "🇻🇳" },
        { country: "Western Sahara", code: "eh", flag: "🇪🇭" },
        { country: "Yemen", code: "ye", flag: "🇾🇪" },
        { country: "Zambia", code: "zm", flag: "🇿🇲" },
        { country: "Zimbabwe", code: "zw", flag: "🇿🇼" },
        { country: "Åland Islands", code: "ax", flag: "🇦🇽" },
    ]

    return (
        <div className="country-popup-container" onClick={(e) => {
            e.stopPropagation()
        }}>
            <div className="country-input-container">
                <input type="text" placeholder='Search Country' />
                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                    <path d="M8.5 8.50002L11 11" stroke="#808080" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M1 5.28573C1 7.65266 2.91878 9.57144 5.28571 9.57144C6.47121 9.57144 7.54436 9.09009 8.32021 8.31216C9.09343 7.53694 9.57143 6.46716 9.57143 5.28573C9.57143 2.91879 7.65264 1.00002 5.28571 1.00002C2.91878 1.00002 1 2.91879 1 5.28573Z" stroke="#808080" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
            </div>
            <div className="countries-container">
                {countries.map((data, index) => {
                    return (
                        <div className="country" key={index}>
                            <span>{data.flag} {data.country}</span>
                            <input type="checkbox" onChange={() => {
                                handleCountries(data)
                            }} checked={selectedCountry.code === data.code ? true : false} />
                        </div>
                    )
                })}
            </div>
        </div>
    );
}

export default CountryPopup;
