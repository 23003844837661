import React, { useContext } from 'react'
import "./LeftSidebar.css"
import ButtonContext from '../../../store/ButtonProvider'
import dashboard from '../../../assets/left_sidebar_assets/dashboard.svg'
import saved from '../../../assets/left_sidebar_assets/saved.svg'
import setting from '../../../assets/left_sidebar_assets/setting.svg'
import help from '../../../assets/left_sidebar_assets/help.svg'
import templates from '../../../assets/left_sidebar_assets/templates.svg'

const LeftSidebar = () => {
    const buttonContext = useContext(ButtonContext)
    const changeScreens = (leftButton, centerButton, rightButton) => {
        buttonContext.changeLeftButton(leftButton)
        buttonContext.changeCenterButton(centerButton)
        buttonContext.changeRightButton(rightButton)
    }
    return (
        <div className='left-main-container'>
            <button onClick={() => changeScreens(1, "dashboard", "right-dashboard")}
                className={buttonContext.activeLeftButton === 1 ? "active" : null}>
                <img src={dashboard} alt="" />
                <span>Dashboard</span>
            </button>
            <button onClick={() => changeScreens(2, "saved", "right-dashboard")}
                className={buttonContext.activeLeftButton === 2 ? "active" : null}>
                <img src={saved} alt="" />
                <span>Saved</span>
            </button>
            <button onClick={() => changeScreens(3, "templates", "right-dashboard")}
                className={buttonContext.activeLeftButton === 3 ? "active" : null}>
                <img src={templates} alt="" />
                <span>Templates</span>
            </button>
            <button onClick={() => changeScreens(4, "settings", "right-dashboard")}
                className={buttonContext.activeLeftButton === 4 ? "active" : null}>
                <img src={setting} alt="" />
                <span>Settings</span>
            </button>
            <button onClick={() => changeScreens(5, "help", "right-dashboard")}
                className={buttonContext.activeLeftButton === 5 ? "active" : null}>
                <img src={help} alt="" />
                <span>Help</span>
            </button>
        </div>
    );
}

export default LeftSidebar;