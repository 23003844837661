import React, { useState, useEffect } from 'react';
import './GoogleDrive.css';
import Vector from '../../assets/Vector.svg';
import Google from '../../assets/google.svg';
import constant from '../../constant';
import jwt_decode from 'jwt-decode';
import axios from 'axios';
import AppHeadingCard from '../app_heading_card/AppHeadingCard';
import backIcon from "../../assets/leftArrow.svg";
import HashtagsLoader from "../hashtags_loader/HashtagsLoaders";
import Masonry from "react-responsive-masonry";




const GoogleDrive = ({ changeActiveApp }) => {
    const [signedInUser, setSignedInUser] = useState();
    /* eslint-disable no-unused-vars */
    const [pageToken, setpageToken] = useState('');
    const [selectedID, setselectedID] = useState('');
    const [selectedIDArray] = useState([]);
    const [selectedName, setselectedName] = useState('');
    const [childList, setchildList] = useState([]);
    const [search, setsearch] = useState("");
    const [loading, setLoading] = useState(true);
    const [sharedFolder, setSharedFolder] = useState([]);
    const [foldersAndFiles, setFoldersAndFiles] = useState([]);
    const [accessToken, setAccessToken] = useState("unauthorized");
    const [activeImage, setactiveImage] = useState(null);





    useEffect(() => {
        getAccessToken();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    // login google drive
    const login = () => {
        const jwtToken = localStorage.getItem('token');
        const decoded = jwt_decode(jwtToken);
        const user_id = decoded.id;
        window.open(`${constant.url}google/drive/login/${user_id}/cache`, "_self");
    };

    // logout google drive
    const logout = async () => {
        const jwtToken = localStorage.getItem('token');
        const decoded = jwt_decode(jwtToken);
        const user_id = decoded.id;
        const response = await axios.get(`${constant.url}google/drive/logout/${user_id}`);
        if (response.data.data === "User logout successfully") {
            setSignedInUser(false);
            setAccessToken("unauthorized");
        }
    };


    // list all users folders and files
    const listFiles = async (access_token) => {
        try {
            setFoldersAndFiles([]);
            setLoading(true);
            let requestUrl = "https://www.googleapis.com/drive/v3/files?fields=nextPageToken,files";
            const response = await axios.get(requestUrl, {
                headers: {
                    Accept: "application/json",
                    Authorization: `Bearer ${accessToken === "unauthorized" ? access_token : accessToken}`
                }
            });
            const sharedFilesAndFolders = response.data.files.filter((data) => data.shared === true);
            const notSharedFoldersAndFiles = response.data.files.filter((data) => data.shared === false);
            if (sharedFilesAndFolders.length > 0) {
                setSharedFolder(sharedFilesAndFolders);
                setLoading(false);
            }

            if (sharedFilesAndFolders.length > 0) {
                setFoldersAndFiles(notSharedFoldersAndFiles);
                setLoading(false);
            }

            setpageToken(response.data.nextPageToken);
        } catch (error) {
            console.log(error);
        }

    };

    // search files
    const searchMedia = async () => {
        try {
            setLoading(true);
            if (constant.searchToken) {
                constant.searchToken.cancel(); // Cancel the previous request before making a new request
            }
            constant.searchToken = axios.CancelToken.source();
            const query = `name contains '${search}'`;
            if (search === "") {
                setFoldersAndFiles([]);
                return listFiles();
            } else {
                const response = await axios.get(`https://www.googleapis.com/drive/v3/files?fields=nextPageToken,files&q=${query}`, {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${accessToken}`
                    },
                    cancelToken: constant.searchToken.token
                });

                if (response.data.files.length > 0) {
                    setFoldersAndFiles(response.data.files);
                    setLoading(false);
                }
            }
        } catch (error) {
            // console.log(error);
        }
    };


    // get subfolders of folder
    const getChildrenList = async (id) => {
        try {
            setLoading(true);
            const query = `'${id}' in parents`;
            const response = await axios.get(` https://www.googleapis.com/drive/v3/files?fields=nextPageToken,files&q=${query}`, {
                headers: {
                    Accept: "application/json",
                    Authorization: `Bearer ${accessToken}`
                }
            });

            let filteredFilesAndFolders = [];
            response.data.files.forEach((data) => {
                if (data.mimeType === "application/vnd.google-apps.folder") {
                    filteredFilesAndFolders.push(data);
                } else if ('image/svg+xml') {
                    filteredFilesAndFolders.push(data);
                } else if ('image/png') {
                    filteredFilesAndFolders.push(data);
                } else if ('image/jpeg') {
                    filteredFilesAndFolders.push(data);
                } else if ('image/jpg') {
                    filteredFilesAndFolders.push(data);
                }
            });

            setchildList(filteredFilesAndFolders);
            setLoading(false);
        } catch (error) {
            console.log(error);
        }
    };



    // get drive access tokens for api request 
    const getAccessToken = async () => {
        const jwtToken = localStorage.getItem('token');
        const decoded = jwt_decode(jwtToken);
        const user_id = decoded.id;
        const response = await axios.get(`${constant.url}google/drive/get/access/token/${user_id}`);
        if (response.data.access_token !== undefined) {
            setAccessToken(response.data.access_token);
            setSignedInUser(true);
            listFiles(response.data.access_token);
        } else {
            setAccessToken("unauthorized");
            setSignedInUser(false);
        }
    };




    // show users google drive 
    const usersGoogleDrive = () => {
        return (
            <div className="drive-container">
                {/* drive folders */}
                {loading ? null : (<div className="shared-folder" onClick={() => {
                    setselectedID(() => 1);
                    setselectedName("Shared with me");
                    setchildList(sharedFolder);
                }}
                    style={search !== "" ? { display: "none" } : null}
                >
                    <img alt="folder" src={Vector} />
                    <span>Shared with me</span>
                </div>)}

                {loading ? <HashtagsLoader /> :
                    foldersAndFiles.map((data, index) => {
                        return (
                            <div className="folder" onClick={() => {
                                setselectedID(() => data.id);
                                setselectedName(data.name);
                                getChildrenList(data.id);
                                const obj = {
                                    name: '',
                                    id: '',
                                };
                                obj.id = data.id;
                                obj.name = data.name;
                                selectedIDArray.push(obj);
                            }}
                                key={index}
                                style={data.mimeType === 'application/vnd.google-apps.folder' ? null : { display: "none" }}>
                                <img alt="folder" src={Vector} />
                                <span>{data.name}</span>
                            </div>

                        );
                    })}

                {/* drive images */}
                {loading ? null : (
                    <Masonry columnsCount={2} gutter={10}>
                        {
                            foldersAndFiles.map((data, index) => {
                                return (
                                    <div key={index} className='image-container' style={data.mimeType === 'image/svg+xml' ||
                                        data.mimeType === 'image/png' ||
                                        data.mimeType === 'image/jpeg' ||
                                        data.mimeType === 'image/jpg' ? null : { display: "none" }}>
                                        <img

                                            src={data.webContentLink}
                                            alt='drive'
                                        />
                                        {activeImage === index ? <div className='overlay-effect' /> : null}
                                        {activeImage === index ? <div className='loader' /> : null}
                                    </div>
                                );
                            })
                        }
                    </Masonry>
                )}

            </div>
        );
    };

    // show selected folders
    const selectedFolder = () => {
        return (
            <div className="drive-container">
                {/* drive folders */}
                {/* Current active folder and back button */}
                <div className="active-folder">
                    <img
                        className='arrow-left'
                        src={backIcon}
                        alt='arrowLeft'
                        onClick={() => {
                            if (selectedName === "Shared with me") {
                                setselectedName("");
                            }
                            selectedIDArray.pop();
                            if (selectedIDArray.length) {
                                setselectedID(selectedIDArray[selectedIDArray.length - 1].id);
                                setselectedName(selectedIDArray[selectedIDArray.length - 1].name);

                                getChildrenList(selectedIDArray[selectedIDArray.length - 1].id);
                            } else {
                                setselectedID("");
                                setselectedName("");
                            }
                        }}
                    />
                    <span>{selectedName}</span>
                </div>

                {loading ? <HashtagsLoader /> : childList.length === 0 ? <div className='no-files-and-folder'>
                    <span>There's nothing here</span>
                </div> : childList.map((data, index) => {

                    return (
                        <div className="folder" onClick={() => {
                            setselectedID(() => data.id);
                            setselectedName(data.name);
                            getChildrenList(data.id);
                            const obj = {
                                name: '',
                                id: '',
                            };
                            obj.id = data.id;
                            obj.name = data.name;
                            selectedIDArray.push(obj);
                        }}
                            key={index}
                            style={data.mimeType === 'application/vnd.google-apps.folder' ? null : { display: "none" }}
                        >
                            <img alt="folder" src={Vector} />
                            <span>{data.name}</span>
                        </div>

                    );
                })
                }

                {/* drive images */}
                {loading ? null : (
                    <Masonry columnsCount={2} gutter={10}>
                        {
                            childList.map((data, index) => {
                                return (
                                    <div key={index} className='image-container'
                                        style={data.mimeType === 'image/svg+xml' ||
                                            data.mimeType === 'image/png' ||
                                            data.mimeType === 'image/jpeg' ||
                                            data.mimeType === 'image/jpg' ? null : { display: "none" }}
                                    >
                                        <img
                                            src={data.webContentLink}
                                            alt='drive'
                                        />
                                        {activeImage === index ? <div className='overlay-effect' /> : null}
                                        {activeImage === index ? <div className='loader' /> : null}
                                    </div>
                                );
                            })
                        }
                    </Masonry>
                )}

            </div>
        );
    };




    return (
        <div className='rightGoogleDrive'>
            <AppHeadingCard title="Google Drive" changeActiveApp={changeActiveApp} signOut={logout} isUserSignIn={signedInUser} login={login} authenticationRequired={true} />
            {/* search field */}
            {accessToken !== "unauthorized" ? (
                <div className='input-container'>
                    <input type="text" placeholder='Search' value={search}
                        onChange={(e) => {
                            setsearch(e.target.value); searchMedia();
                        }} />
                </div>
            ) : null}

            {accessToken !== "unauthorized" ? selectedID === "" ? usersGoogleDrive() : selectedFolder() : (
                <div onClick={() => {
                    login();
                }} className="connect-drive-container">
                    <h1 className='heading'>Connect Google account</h1>
                    <div className='drive-connect-button'>
                        <img alt="drive" src={Google} />
                        <p>Sign in with Google</p>
                    </div>
                </div>
            )}
        </div>
    );
};

export default GoogleDrive;
