import React, { useContext } from 'react'
import ButtonContext from '../../../store/ButtonProvider'
import RightDashboard from '../right_dashboard/RightDashboard';

const Right = () => {
    const context = useContext(ButtonContext)
    return (
        <div>
            {context.activeRightButton === "right-dashboard" ? <RightDashboard /> : null}
        </div>
    );
}

export default Right;