import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Vector from '../../assets/Vector.svg';
import './Dropbox.css';
import DropboxIcon from '../../assets/dropbox.png';
import jwt_decode from 'jwt-decode';
import AppHeadingCard from '../app_heading_card/AppHeadingCard';
import HashtagsLoader from "../hashtags_loader/HashtagsLoaders";
import Masonry from "react-responsive-masonry";
import backIcon from "../../assets/leftArrow.svg";
import constant from '../../constant';


const DropboxComponent = ({ changeActiveApp }) => {
    const [folder, setfolder] = useState([]);
    const [selectedID, setselectedID] = useState('');
    let [selectedIDArray] = useState([]);
    const [selectedName, setselectedName] = useState('');
    const [loading, setLoading] = useState(false);
    const [usersMedia, setUsersMedia] = useState([]);
    const [base64Data, setBase64Data] = useState([]);
    const [childFolders, setChildFolders] = useState([]);
    const [accessToken, setAccessToken] = useState('unauthorized');
    const [isUserSignIn, setIsUserSignIn] = useState(false);




    useEffect(() => {
        getUsersAccesTokenAndTeam();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {
        getUsersFilesAndFolders(accessToken);
    }, [selectedID, selectedName]); // eslint-disable-line react-hooks/exhaustive-deps


    // get users access tokens and fetch users files if access token is available
    const getUsersAccesTokenAndTeam = async () => {
        try {
            setLoading(true);
            const jwtToken = localStorage.getItem('token');
            const decoded = jwt_decode(jwtToken);
            const user_id = decoded.id;
            const response = await axios.get(`${constant.url}dropbox/get/access/token/${user_id}`);
            if (response.data.message === undefined) {
                setAccessToken(response.data.data.access_token);
                setIsUserSignIn(true);
                getUsersFilesAndFolders(response.data.data.access_token);
            } else {
                setAccessToken('unauthorized');
                setIsUserSignIn(false);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const getUsersFilesAndFolders = async (token) => {
        try {
            const body = {
                include_deleted: false,
                include_has_explicit_shared_members: false,
                include_media_info: false,
                include_mounted_folders: true,
                include_non_downloadable_files: true,
                path: '',
                recursive: false
            };
            const response = await axios.post('https://api.dropboxapi.com/2/files/list_folder', body, {
                headers: {
                    Authorization: `Bearer ${accessToken === "unauthorized" ? token : accessToken}`
                },
            });
            if (response.data !== undefined) {
                const usersFolders = response.data.entries.filter((data) => data[".tag"] === "folder");
                setfolder(usersFolders);
                const usersMediaArray = response.data.entries.filter((data) => data[".tag"] === "file");
                getBase64OfImage(usersMediaArray, token, false);
                setLoading(false);
            }
        } catch (error) {
            console.log(error);
        }
    };



    const getChildrenList = async (id) => {
        setLoading(true);
        fetch('https://api.dropboxapi.com/2/files/list_folder', {
            method: 'POST',
            headers: new Headers({
                'Authorization': 'Bearer ' + accessToken,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }),
            body: JSON.stringify({
                path: id,
                recursive: false,
                include_media_info: true,
                include_deleted: false,
                include_has_explicit_shared_members: false,
                include_mounted_folders: true,
                include_non_downloadable_files: true
            })
        }).then(res => res.json()).then(val => {
            if (val.entries) {
                val.entries.forEach(data => { data.thumbnail = ""; });
                const usersFolders = val.entries.filter((data) => data[".tag"] === "folder");
                setChildFolders(usersFolders);
                const usersMediaArray = val.entries.filter((data) => data[".tag"] === "file");
                getBase64OfImage(usersMediaArray, accessToken, true);
                setLoading(false);
            }
        });


    };

    const getBase64OfImage = async (usersMediaArray, token, isChild) => {
        setBase64Data([]);
        let base64Array = [];
        for await (const imageData of usersMediaArray) {
            const filename = imageData.name;
            const extension = filename.split('.').pop();
            if (extension === "jpeg"
                || extension === "png" || extension === "jpg") {
                const response = axios('https://content.dropboxapi.com/2/files/get_thumbnail_batch', {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + token,
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    },
                    data: {
                        "entries": [
                            {
                                "format": "jpeg",
                                "mode": "bestfit",
                                "path": imageData.path_lower,
                                "size": "w2048h1536"
                            }
                        ]
                    }
                });

                let image = (await response).data.entries[0].thumbnail;
                base64Array.push(image);
            }

        }
        if (isChild) {
            setUsersMedia(base64Array);
        } else {
            setBase64Data(base64Array);
        }
    };

    // login the user
    const login = () => {
        const jwtToken = localStorage.getItem('token');
        const decoded = jwt_decode(jwtToken);
        const user_id = decoded.id;
        window.open(`${constant.url}dropbox/login/${user_id}/cache`, "_self");
    };

    // logout the user
    const logout = async () => {
        try {
            const jwtToken = localStorage.getItem('token');
            const decoded = jwt_decode(jwtToken);
            const user_id = decoded.id;
            const response = await axios.get(`${constant.url}dropbox/logout/${user_id}`);
            if (response.data.data === "User logout successfully") {
                setIsUserSignIn(false);
                setAccessToken('unauthorized');
            }
        } catch (error) {
            console.log(error);
        }
    };




    const usersDropbox = () => {
        return (
            <div className="dropbox-container">
                {/* dropbox folders */}
                {loading ? <HashtagsLoader /> :
                    folder.map((data, index) => {
                        return (
                            <div className="folder" onClick={() => {
                                setselectedID(() => data.id);
                                setselectedName(data.name);
                                getChildrenList(data.id);

                                const obj = {
                                    name: '',
                                    id: ''
                                };
                                obj.id = data.id;
                                obj.name = data.name;
                                selectedIDArray.push(obj);
                            }}
                                key={index}
                                style={data[".tag"] === 'folder' ? null : { display: "none" }}>
                                <img alt="folder" src={Vector} />
                                <span>{data.name}</span>
                            </div>

                        );
                    })}

                {/* dropbox images */}
                {loading ? null : (
                    <Masonry columnsCount={2} gutter={10}>
                        {
                            base64Data.map((data, index) => {
                                return (
                                    <div key={index} className='image-container' >
                                        <img
                                            src={`data:image/jpeg;base64,${data}`}
                                            alt='dropbox'
                                        />
                                    </div>
                                );
                            })
                        }

                    </Masonry>
                )}

            </div>
        );
    };


    const selectedFolder = () => {
        return (
            <div className="dropbox-container">
                {/* drive folders */}
                {/* Current active folder and back button */}
                <div className="active-folder">
                    <img
                        className='arrow-left'
                        src={backIcon}
                        alt='arrowLeft'
                        onClick={() => {
                            selectedIDArray.pop();
                            if (selectedIDArray.length) {
                                setselectedID(selectedIDArray[selectedIDArray.length - 1].id);
                                setselectedName(selectedIDArray[selectedIDArray.length - 1].name);

                                getChildrenList(selectedIDArray[selectedIDArray.length - 1].id);
                            } else {
                                setselectedID("");
                                setselectedName("");
                            }
                        }}
                    />
                    <span>{selectedName}</span>
                </div>

                {loading ? null : childFolders.length === 0 && usersMedia.length === 0 ? <div className='no-files-and-folder'>
                    <span>There's nothing here</span>
                </div> : childFolders.map((data, index) => {
                    return (
                        <div className="folder" onClick={() => {
                            setselectedID(() => data.id);
                            setselectedName(data.name);
                            getChildrenList(data.id);
                            const obj = {
                                name: '',
                                id: '',
                            };
                            obj.id = data.id;
                            obj.name = data.name;
                            selectedIDArray.push(obj);
                        }}
                            key={index}
                        >
                            <img alt="folder" src={Vector} />
                            <span>{data.name}</span>
                        </div>

                    );
                })
                }

                {/* dropbox images */}
                {loading ? <HashtagsLoader /> : (
                    <Masonry columnsCount={2} gutter={10}>
                        {
                            usersMedia.map((data, index) => {
                                return (
                                    <div key={index} className='image-container'
                                    >
                                        <img
                                            src={`data:image/jpeg;base64,${data}`}
                                            alt='dropbox'
                                        />
                                    </div>
                                );
                            })
                        }
                    </Masonry>
                )}

            </div>
        );
    };

    // search files and folders
    // const searchContentFolder = () => {
    //     fetch('https://api.dropboxapi.com/2/files/search_v2', {
    //         method: 'POST',
    //         headers: new Headers({
    //             'Authorization': 'Bearer ' + accessToken,
    //             'Content-Type': 'application/json',
    //             'Accept': 'application/json'
    //         }),
    //         body: JSON.stringify({

    //             query: searchQuery,
    //             options: {
    //                 path: path,
    //                 max_results: 20,
    //                 file_status: "active",
    //                 filename_only: false
    //             },
    //             match_field_options: {
    //                 include_highlights: false
    //             }

    //         })
    //     }).then(res => res.json()).then(val => {
    //         let obj = val.matches.map((data) => {
    //             return data.metadata.metadata;
    //         });
    //         setphotosList(obj);

    //     });



    return (
        <div className="rightDropbox">
            <AppHeadingCard title="Dropbox" changeActiveApp={changeActiveApp} login={login} authenticationRequired={true} signOut={logout} isUserSignIn={isUserSignIn} />
            {accessToken !== "unauthorized" && isUserSignIn === true ? selectedID === "" ? usersDropbox() : selectedFolder() : (<div className="connect-dropbox-container">
                <h1 className='heading'>Connect Google account</h1>
                <div className='dropbox-connect-button' onClick={() => {
                    login();
                }}>
                    <img alt="dropbox" src={DropboxIcon} />
                    <p>Sign in with Dropbox</p>
                </div>
            </div>)}
        </div>
    );
};

export default DropboxComponent;