import React, { useContext, useEffect, useState } from 'react'
import './Steno.css'
import AppHeadingCard from '../app_heading_card/AppHeadingCard';
import CreateDataProvider from '../../store/CreateDataProvider';
import empty from '../../assets/center_dashboard_assets/empty.jpg'



const Steno = ({ changeActiveApp }) => {
    const context = useContext(CreateDataProvider)
    const [userPosts, setUsersPost] = useState([])


    const [searchQuery, setsearchQuery] = useState("")
    const [activePost, setActivePost] = useState(null)

    useEffect(() => {
        setUsersPost(context.drafts)
        document.querySelectorAll('a').forEach(link => {
            link.setAttribute('target', '_blank');
        });
    }, [context.drafts.length])



    const blockTypeToCssClass = {
        paragraph: 'cdx-block ce-paragraph',
        header: 'cdx-block ce-header',
        list: 'cdx-block ce-list',
        quote: 'cdx-block ce-quote',
        image: 'cdx-block ce-image',
        checklist: 'cdx-block ce-checklist',
        attaches: 'cdx-block ce-attaches',
        table: 'cdx-block ce-table',
        code: 'cdx-block ce-code',
        outline: 'cdx-block ce-outline',
        intro: 'cdx-block ce-intro',
    };


    const convertToHtml = (jsonData) => {
        return jsonData.reduce((html, block) => {
            const { type, id, data } = block;
            let alignment = 'left';

            if (block.tunes && block.tunes.anyTuneName && block.tunes.anyTuneName.alignment) {
                alignment = block.tunes.anyTuneName.alignment;
            }

            let cssClass = blockTypeToCssClass[type] || '';

            if (type === 'paragraph') {
                html += `<p key="${id}" class="${cssClass}" style="text-align: ${alignment}">${data.text.replace(/<font/g, '<span').replace(/<\/font>/g, '</span>')}</p>`;
            } else if (type === 'header') {
                const HeaderTag = `h${data.level}`;
                html += `<${HeaderTag} key="${id}" class="${cssClass}" style="text-align: ${alignment}">${data.text}</${HeaderTag}>`;
            } else if (type === 'list') {
                const listStyle = data.style === 'ordered' ? 'ol' : 'ul';
                html += `<${listStyle} class="${cssClass} cdx-list" contenteditable="false" spellcheck="false">${data.items.map(item => `<li class="cdx-list__item">${item}</li>`).join('')}</${listStyle}>`;
            } else if (type === 'quote') {
                html += `<blockquote class="${cssClass}" style="text-align: ${alignment}">
                            <div class="cdx-input cdx-quote__text" contenteditable="true" data-placeholder="Enter a quote" spellcheck="false">${data.text}</div>
                            <div class="cdx-input cdx-quote__caption" contenteditable="false" data-placeholder="Quote's author" spellcheck="false">${data.caption}</div>
                        </blockquote>`;
            } else if (type === 'image') {
                let imageHtml = `<div class="cdx-block image-tool ${cssClass}">`;

                if (data.stretched) {
                    imageHtml += ' image-tool--filled';
                }

                if (data.withBackground) {
                    imageHtml += ' image-tool--withBackground';
                }

                if (data.withBorder) {
                    imageHtml += ' image-tool--withBorder';
                }

                imageHtml += `<div class="image-tool__image"><img src="${data.file.url}" class="image-tool__image-picture" alt="${data.alt}" key="${id}" style="text-align: ${alignment}" /></div>`;

                if (data.caption) {
                    imageHtml += `<figcaption>${data.caption}</figcaption>`;
                }

                imageHtml += '</div>';

                html += `<div class="editor-js-block ${cssClass}" key="${id}">${imageHtml}</div>`;
            } else if (type === 'checklist') {
                html += `<div class="cdx-block cdx-checklist">${data.items.map(item => {
                    const checkedClass = item.checked ? 'cdx-checklist__item--checked' : '';
                    return `<div class="cdx-checklist__item ${checkedClass}">
                                <div class="cdx-checklist__item-checkbox">
                                    <span class="cdx-checklist__item-checkbox-check">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                            <path stroke="currentColor" stroke-linecap="round" stroke-width="2" d="M7 12L10.4884 15.8372C10.5677 15.9245 10.705 15.9245 10.7844 15.8372L17 9"></path>
                                        </svg>
                                    </span>
                                </div>
                                <div class="cdx-checklist__item-text" contenteditable="true">${item.text}</div>
                            </div>`;
                }).join('')}</div>`;
            } else if (type === 'attaches') {
                const hasFile = data.file && data.file.url;
                const attachesClass = `cdx-attaches ${hasFile ? 'cdx-attaches--with-file' : ''}`;
                html += `<div class="cdx-block ${attachesClass}">`;

                if (hasFile) {
                    html += `<div class="cdx-attaches__file-icon">
                                <div class="cdx-attaches__file-icon-background">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13.3236 8.43554L9.49533 12.1908C9.13119 12.5505 8.93118 13.043 8.9393 13.5598C8.94741 14.0767 9.163 14.5757 9.53862 14.947C9.91424 15.3182 10.4191 15.5314 10.9422 15.5397C11.4653 15.5479 11.9637 15.3504 12.3279 14.9908L16.1562 11.2355C16.8845 10.5161 17.2845 9.53123 17.2682 8.4975C17.252 7.46376 16.8208 6.46583 16.0696 5.72324C15.3184 4.98066 14.3086 4.55425 13.2624 4.53782C12.2162 4.52138 11.2193 4.91627 10.4911 5.63562L6.66277 9.39093C5.57035 10.4699 4.97032 11.9473 4.99467 13.4979C5.01903 15.0485 5.66578 16.5454 6.79264 17.6592C7.9195 18.7731 9.43417 19.4127 11.0034 19.4374C12.5727 19.462 14.068 18.8697 15.1604 17.7907L18.9887 14.0354"></path>
                                    </svg>
                                </div>
                            </div>
                            <div class="cdx-attaches__file-info">
                                <div class="cdx-attaches__title" contenteditable="true" data-placeholder="File title">${data.title}</div>
                            </div>
                            <a class="cdx-attaches__download-button" href="${data.file.url}" target="_blank" rel="nofollow noindex noreferrer">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-width="2" d="M7 10L11.8586 14.8586C11.9367 14.9367 12.0633 14.9367 12.1414 14.8586L17 10"></path>
                                </svg>
                            </a>`;
                }

                html += `</div>`;
            } else if (type === 'table') {
                const { content } = data;
                const tableClass = `tc-table ${content.length > 0 ? '' : 'tc-table--empty'}`;
                const cellStyle = `style="border-right: 1px solid #e8e8eb; border-bottom: 1px solid #e8e8eb;"`;

                html += `<div class="cdx-block">
                <div class="table-container"> <!-- Add a container div -->
                  <div class="${tableClass}" style="border-top: 1px solid #e8e8eb; border-left: 1px solid #e8e8eb">
                    ${content.map(row => `<div class="tc-row">${row.map(cell => `<div class="tc-cell" contenteditable="false" ${cellStyle}>${cell}</div>`).join('')}</div>`).join('')}
                  </div>
                </div>
              </div>`;
            } else if (type === 'code') {
                html += `<div class="ce-code" style="width: 100%;">
                            <textarea class="ce-code__textarea" placeholder="Enter a code" spellcheck="false" style="width: 100%; padding: 10px;" disabled>${data.code}</textarea>
                        </div>`;
            } else if (type === 'outline') {
                if (data.data.style === 'ordered') {
                    html += `<ol class="${cssClass}" style="text-align: ${alignment}">${data.data.items.map(item => `<li>${item.data.text}</li>`).join('')}</ol>`;
                }
            } else if (type === 'intro') {
                html += `<p key="${id}" class="${cssClass}" style="text-align: ${alignment}">${data?.data.text.replace(/<font/g, '<span').replace(/<\/font>/g, '</span>')}</p>`;
            } else if (type === 'AnyButton') {
                html += `<div class="anyButtonContainer__anyButtonHolder"><a class="btn btn--gray" target="_blank" rel="nofollow noindex noreferrer" href="${data.link}">${data.text}</a></div>`;
            }

            return html;
        }, '');
    };


    const searchPost = (value) => {
        const searchValue = value.trim().toLowerCase();
        let filteredResults;

        if (searchValue === '') {
            filteredResults = context.templates;
        } else {
            filteredResults = context.templates.filter((item) =>
                item.metaData.title.toLowerCase().includes(searchValue) ||
                item.metaData.description.toLowerCase().includes(searchValue)
            );
        }

        setUsersPost(filteredResults);
    };


    const blogViewHeading = () => {
        return <div className='app-heading-card'>
            <div className='center'>
                <svg onClick={() => setActivePost(null)} width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M22.2857 13H6.5M6.5 13L13 6.5M6.5 13L13 19.5" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                <span>{activePost.metaData.title.length > 20 ? `${activePost.metaData.title.slice(0, 25)}...` : activePost.metaData.title}</span>
            </div>
        </div>
    }

    return (
        <React.Fragment>
            <div className="steno-container">
                {activePost === null ? <React.Fragment>
                    <AppHeadingCard title="Steno" changeActiveApp={changeActiveApp} />
                    <React.Fragment>
                        <div>
                            <React.Fragment>
                                <div className='input-container'>
                                    <input maxLength="30"
                                        placeholder="Search title & description of post"
                                        onChange={(e) => {
                                            searchPost(e.target.value)
                                            setsearchQuery(e.target.value)
                                        }}
                                        value={searchQuery} />
                                </div>
                            </React.Fragment>

                            {userPosts.length === 0 ? <div style={{
                                marginTop: "20px",
                                display: "flex",
                                justifyContent: "center",
                            }}>
                                <span>You have not saved anything.</span>
                            </div> : userPosts.map((data, index) => {
                                return <div className='saved-container' key={index} onClick={() => {
                                    setActivePost(data)
                                }}>
                                    <div className="left-side">
                                        <h3>{data.metaData.title}</h3>
                                        <p>{data.metaData.description}</p>
                                    </div>
                                    <div className="right-side">
                                        {data.url !== "" ? (
                                            <img alt="" src={data.metaData.coverImage} />
                                        ) : (
                                            <img alt="" src={empty} />
                                        )}
                                    </div>
                                </div>
                            })}
                        </div>


                    </React.Fragment>
                </React.Fragment> : <React.Fragment>
                    {blogViewHeading()}
                    <React.Fragment>
                        <div className='post'>
                            <img src={activePost.metaData.coverImage} alt="cover" />
                            <div dangerouslySetInnerHTML={{ __html: convertToHtml(activePost.blocks) }} />
                        </div>
                    </React.Fragment>
                </React.Fragment>}
            </div>

        </React.Fragment>
    );
}

export default Steno;