import React from 'react';
import "./ChatLoader.css";

const ChatLoader = () => {
    const teamId = 1;
    const chatArray = [{
        team: 2,
        text: '',
        ts: '',
        type: '',
        user: '',
    }, {
        team: 2,
        text: '',
        ts: '',
        type: '',
        user: '',
    },
    {
        team: 1,
        text: '',
        ts: '',
        type: '',
        user: '',
    },
    {
        team: 1,
        text: '',
        ts: '',
        type: '',
        user: '',
    },
    {
        team: 2,
        text: '',
        ts: '',
        type: '',
        user: '',
    },
    {
        team: 1,
        text: '',
        ts: '',
        type: '',
        user: '',
    },
    {
        team: 2,
        text: '',
        ts: '',
        type: '',
        user: '',
    },
    ];
    return (
        <div className="chat-loader-container">
            {chatArray.map((message, index) => {
                return teamId !== message.team ? (
                    <div className='message-container'>
                        <div className="user-message" key={index} >
                            <p>{message.text}</p>
                        </div>
                        <div className="sender">
                            <span></span>
                            <span></span>
                        </div>
                    </div>

                ) : (
                    <div className='my-message-container'>
                        <div className="my-message" key={index} >
                            <p>{message.text}</p>
                        </div>
                        <div className="sender">
                            <span></span>
                            <span></span>
                        </div>

                    </div>
                );

            }).reverse()}
        </div>
    );
};

export default ChatLoader;