import React, { useState, useContext } from 'react'
import './AiBlogGeneratePopup.css'
import axios from 'axios'
import jwtDecode from 'jwt-decode'
import constant from '../../constant'
import CreateDataContext from '../../store/CreateDataProvider'


const AiBlogGeneratePopup = ({ handleAiResults, handleAiPopup, handleAiBlogResults }) => {
    const context = useContext(CreateDataContext)
    const [brief, setBrief] = useState('')
    const [primaryKeyword, setPrimaryKeyword] = useState('')
    const [loading, setLoading] = useState(false)
    const [type, setType] = useState('blog')

    const extractBlogDetails = (blogString) => {
        // Regular expression to match the blog title and description
        const titleMatch = blogString.match(/Blog Title:\s*"([^"]+)"/);
        const descriptionMatch = blogString.match(/Blog Description:\s*(.*)/);

        // Extract the title and description from the matches
        const title = titleMatch ? titleMatch[1] : null;
        const description = descriptionMatch ? descriptionMatch[1].trim() : null;

        return {
            title: title,
            description: description
        };
    }

    const extractMetaDetails = (metaString) => {
        // Regular expression to match the different parts of the meta details
        const topicMatch = metaString.match(/Topic:\s*(.*)/);
        const secondaryKeywordsMatch = metaString.match(/Secondary Keywords:\s*(.*)/);
        const audienceStageMatch = metaString.match(/Audience Stage & Search Intent:\s*(.*)/);
        const metaTitleMatch = metaString.match(/Meta Title:\s*(.*)/);
        const metaDescriptionMatch = metaString.match(/Meta Description:\s*(.*)/);
        const primaryKeywordMatch = metaString.match(/Primary Keyword:\s*(.*)/);

        // Extract the parts from the matches
        const topic = topicMatch ? topicMatch[1].trim() : null;
        const secondaryKeywords = secondaryKeywordsMatch ? secondaryKeywordsMatch[1].trim() : null;
        const audienceStage = audienceStageMatch ? audienceStageMatch[1].trim() : null;
        const metaTitle = metaTitleMatch ? metaTitleMatch[1].replace(/"/g, '').trim() : null;
        const metaDescription = metaDescriptionMatch ? metaDescriptionMatch[1].replace(/"/g, '').trim() : null;
        const primaryKeyword = primaryKeywordMatch ? primaryKeywordMatch[1].replace(/"/g, '').trim() : null;

        return {
            topic,
            primaryKeyword,
            secondaryKeywords,
            audienceStage,
            metaTitle,
            metaDescription
        };
    }


    const generateBlog = async () => {
        try {
            setLoading(true)
            const token = localStorage.getItem("token");
            const decoded = jwtDecode(token);
            const workspaceOwnerUserId = context.userData.workspaceOwnerFeatureFactoryData.userId
            if (constant.savedPost?.isTemplate) {
                const organizationId = localStorage.getItem('organizationId');
                const { id: userId, userName } = decoded;
                const tokenHeader = { headers: { Authorization: `Bearer ${token}` } };

                const createBody = (description) => ({
                    organizationId,
                    workspaceOwnerUserId,
                    userId,
                    userName,
                    companyName: null,
                    companyDescription: description,
                    gender: "male",
                    copyType: "Content Expander (Write more)",
                    language: "English"
                });

                const fetchMetaData = async (description) => {
                    const body = createBody(description);
                    const response = await axios.post(`${constant.captionatorUrl}/write/more`, body, tokenHeader);
                    return response.data.data[0].text;
                };

                const fetchBlogData = async (description) => {
                    const body = createBody(description);
                    const response = await axios.post(`${constant.captionatorUrl}/write/more/blog`, body, tokenHeader);
                    return response.data.data[0].text;
                };


                const blogMetaInfoText = await fetchMetaData(`Write a topic,Secondary keywords,Audience Stage & Search Intent,Meta Title,Meta Description,Primary keyword for the following brief ${brief}`);
                const blogMetaInfo = extractMetaDetails(blogMetaInfoText);

                const generatedBlog = {
                    title: blogMetaInfo.metaTitle,
                    desc: blogMetaInfo.metaDescription,
                    topic: blogMetaInfo.topic,
                    primaryKeyword: blogMetaInfo.primaryKeyword,
                    secondaryKeyword: blogMetaInfo.secondaryKeywords,
                    audienceStage: blogMetaInfo.audienceStage,
                    metaTitle: blogMetaInfo.metaTitle,
                    metaDesc: blogMetaInfo.metaDescription
                };

                const tableOfContentText = await fetchMetaData(`Write a table of content for following information title: ${generatedBlog.title}, description: ${generatedBlog.desc}, topic: ${generatedBlog.topic}, primary keyword: ${generatedBlog.primaryKeyword}, secondary keywords: ${generatedBlog.secondaryKeyword}, Audience Stage & Search Intent: ${generatedBlog.audienceStage}, meta title: ${generatedBlog.metaTitle}, meta description: ${generatedBlog.metaDesc}`);

                const blogText = await fetchBlogData(`Write a brief blog for following table of content ${tableOfContentText} more than 4000 words`);

                handleAiBlogResults({
                    ...generatedBlog,
                    tableOfContent: tableOfContentText,
                    blog: blogText
                })
            } else {

                const body = {
                    organizationId: localStorage.getItem('organizationId'),
                    workspaceOwnerUserId: workspaceOwnerUserId,
                    userId: decoded.id,
                    userName: decoded.userName,
                    companyName: null,
                    companyDescription: `Write a topic,Secondary keywords,Audience Stage & Search Intent,Meta Title,Meta Description,Primary keyword for the following brief ${brief} & ${primaryKeyword}`,
                    gender: "male",
                    copyType: "Content Expander (Write more)",
                    language: "English"
                };

                const metaResponse = await axios.post(`${constant.captionatorUrl}/write/more`, body, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })

                body.companyDescription = `Write a brief blog  post with following brief ${brief} and include following keyword ${primaryKeyword} more than 4000 words`
                const blogMetaInfo = extractMetaDetails(metaResponse.data.data[0].text);

                const response = await axios.post(`${constant.captionatorUrl}/write/more/blog`, body, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })

                handleAiResults({
                    title: blogMetaInfo.metaTitle,
                    description: blogMetaInfo.metaDescription,
                    blog: response.data.data[0].text
                })
            }
            handleAiPopup()
            setLoading(false)
        } catch (error) {
            handleAiPopup()
            setLoading(false)
            console.log(error)
        }
    }



    return (
        <div className='ai-blog-main-container' onClick={handleAiPopup}>
            <div className='ai-info-container' onClick={(e) => e.stopPropagation()}>
                <div className='header-container'>
                    <h2>Generate Using Steno Ai</h2>

                    {/* Corss SVG Icon */}
                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 36 36" data-testid="close-icon" onClick={handleAiPopup}>
                        <path d="M28.5 9.62L26.38 7.5 18 15.88 9.62 7.5 7.5 9.62 15.88 18 7.5 26.38l2.12 2.12L18 20.12l8.38 8.38 2.12-2.12L20.12 18z" />
                    </svg>
                </div>

                <div className="input-container">
                    <label>Type *</label>
                    <select onChange={(e) => {
                        setType(e.target.value)
                    }} defaultValue={type}>
                        <option value="blog">Blog</option>
                    </select>
                </div>

                <div className="input-container">
                    <label>Brief *</label>
                    <input type="text" placeholder='Enter brief for blog' onChange={(e) => {
                        setBrief(e.target.value)
                    }} value={brief} />
                </div>

                <div className="input-container">
                    <label>Primary Keyword *</label>
                    <input type="text" placeholder='Enter a primary keyword for blog' onChange={(e) => {
                        setPrimaryKeyword(e.target.value)
                    }} value={primaryKeyword} />
                </div>

                <div className="bottom-container">
                    <button style={loading ? { backgroundColor: "#0078ff" } : null} onClick={generateBlog}>{loading ? <span className="loader" /> : "Generate"}</button>
                </div>
            </div>
        </div>
    )
}

export default AiBlogGeneratePopup