import React, { useContext } from 'react';
import ButtonProvider from "../../../store/ButtonProvider"
import Dashboard from "../dashboard/Dashboard"
import Saved from '../saved/Saved';
import Settings from '../settings/Settings';
import Help from '../help/Help';
import Templates from '../templates/Template';

const Center = () => {
    const context = useContext(ButtonProvider);
    return (
        <div>
            {context.activeCenterButton === 'dashboard' ? <Dashboard /> : null}
            {context.activeCenterButton === 'saved' ? <Saved /> : null}
            {context.activeCenterButton === 'templates' ? <Templates /> : null}
            {context.activeCenterButton === 'settings' ? <Settings /> : null}
            {context.activeCenterButton === 'help' ? <Help /> : null}
        </div>
    );
}

export default Center;