import axios from 'axios';
import jwt_decode from "jwt-decode"


class Intro {
    static get toolbox() {
        return {
            title: 'Intro',
            icon: `<svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.1615 11.2772L8.28282 13.3985L12.8739 10.9935C13.519 10.6557 14.0725 10.1665 14.4868 9.56772C14.9012 8.96896 15.1641 8.2786 15.2529 7.55588L15.6374 4.42428C15.6458 4.35639 15.6386 4.28749 15.6163 4.22281C15.5941 4.15813 15.5574 4.09937 15.509 4.05101C15.4606 4.00264 15.4019 3.96593 15.3372 3.94368C15.2725 3.92143 15.2036 3.91421 15.1357 3.92259L12.0041 4.30708C11.2814 4.39591 10.5911 4.6588 9.9923 5.07318C9.39355 5.48756 8.9043 6.04105 8.56654 6.68614L6.1615 11.2772ZM6.1615 11.2772L3.50984 10.7469C3.50984 10.7469 4.57051 7.56489 7.75249 8.09522M4.57051 12.8682C3.50984 13.9289 4.04017 15.5198 4.04017 15.5198C4.04017 15.5198 5.63116 16.0502 6.69183 14.9895M11.6934 12.0361C12.2237 15.2181 9.04172 16.2787 9.04172 16.2787L8.51139 13.6271" stroke="#404040" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M10.4042 9.15464C10.1229 8.87333 9.96484 8.4918 9.96484 8.09398C9.96484 7.69615 10.1229 7.31462 10.4042 7.03332C10.6855 6.75201 11.067 6.59398 11.4648 6.59398C11.8627 6.59398 12.2442 6.75201 12.5255 7.03332C12.8068 7.31462 12.9648 7.69615 12.9648 8.09398C12.9648 8.4918 12.8068 8.87333 12.5255 9.15464C12.2442 9.43594 11.8627 9.59398 11.4648 9.59398C11.067 9.59398 10.6855 9.43594 10.4042 9.15464Z" stroke="#404040" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          </svg>`
        };
    }

    render() {
        this.node = document.createElement('p');
        this.node.contentEditable = true;
        this.node.classList.add('cdx-block', 'cdx-paragraph');

        const title = document.getElementById('post-title');

        if (!title || title.textContent.trim() === '') {
            this.error = 'Title cannot be empty';
            this.node.textContent = this.error;
            this.node.style.color = 'red';
            return this.node;
        }

        this.isLoading = true;

        const loader = document.createElement('div');
        loader.style.width = 0;
        loader.classList.add('cdx-loader');
        this.node.appendChild(loader);

        this.getAIResponse(title.textContent)
            .then((responseText) => {
                const paragraph = document.createElement('p');
                paragraph.textContent = responseText;
                this.node.appendChild(paragraph);
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                this.isLoading = false;
                this.node.removeChild(loader);
            });

        return this.node;
    }



    async getAIResponse(title) {
        try {
            const token = localStorage.getItem("token");
            const decoded = jwt_decode(token);

            const body = {
                organizationId: localStorage.getItem('organizationId'),
                userId: decoded.id,
                userName: decoded.userName,
                companyName: null,
                companyDescription: title,
                gender: "male",
                copyType: "Blog Intro",
                language: "English"
            };


            const response = await axios.post("https://api.captionator.creatosaurus.io/captionator/blog/intro", body, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            return response.data.data[0].text
        } catch (error) {

        }
    }

    save() {
        if (this.error || this.isLoading) {
            return null;
        }

        const paragraphText = this.node.textContent.trim();

        return {
            type: 'paragraph',
            data: {
                text: paragraphText,
            },
        };
    }


}


export default Intro