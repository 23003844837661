import React, { useState, useEffect } from 'react';
import './Pexels.css';
import Axios from 'axios';
import AppHeadingCard from '../app_heading_card/AppHeadingCard';
import constant from '../../constant';
import Masonry from "react-responsive-masonry";
import ImagesLoader from '../images_loader/ImagesLoader';


const Pexels = ({ changeActiveApp }) => {
    const [images, setImages] = useState([]);
    const [loading, setLoading] = useState(true);
    const [pageNum, setPageNum] = useState(1);
    const [search, setSearch] = useState("");
    const [dataFinished, setDataFinished] = useState(false);




    // load initial images
    useEffect(() => {
        getTheImages();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    // search images function
    const getSearchPhotos = async (loadMore, searchData) => {
        try {
            if (!loadMore) {
                setImages([]);
                setPageNum(1);
                setDataFinished(false);
            }

            let query = loadMore === false ? searchData : search;
            if (query === "") {
                setImages([]);
                setPageNum(1);
                setDataFinished(false);
                return getTheImages(true);
            }

            setLoading(true);

            if (constant.searchToken) {
                constant.searchToken.cancel(); // Cancel the previous request before making a new request
            }

            // Create a new CancelToken
            constant.searchToken = Axios.CancelToken.source();

            const response = await Axios.get(
                `https://api.pexels.com/v1/search?query=${query}&page=${pageNum}&per_page=${20}`,
                {
                    headers: {
                        Authorization:
                            '563492ad6f91700001000001c8495ed795a0477fb491bb09364ae89f',
                    },
                    cancelToken: constant.searchToken.token
                }
            );


            // if results is less then 20 setting data finished to true to stop the execution of function 

            if (response.data.photos.length < 20) {
                setDataFinished(true);
            }

            if (loadMore === false) {
                setImages(response.data.photos);
            } else {
                setImages([...images, ...response.data.photos]);
                setPageNum((prev) => prev + 1);
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };



    // get initial images and load more when scroll
    const getTheImages = async (noSearch) => {
        try {
            let page = noSearch ? 1 : pageNum
            setLoading(true);
            const response = await Axios.get(`https://api.pexels.com/v1/curated?page=${page}&per_page=${20}`,
                {
                    headers: {
                        Authorization:
                            '563492ad6f91700001000001c8495ed795a0477fb491bb09364ae89f',
                    },
                })

            if (noSearch) {
                setImages(response.data.photos);
                setLoading(false);
            } else {
                setImages([...images, ...response.data.photos]);
                setLoading(false);
                setPageNum((prev) => prev + 1);
            }
        } catch (error) {
            setLoading(false);
        }
    };


    const scrollStarted = (e) => {
        if (e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight - 50) {
            if (loading || dataFinished) return;
            if (search !== "") {
                getSearchPhotos(true);
            } else {
                getTheImages();
            }
        }
    };

    const imageDragStart = (e, url) => {

        e.dataTransfer.setData('text/plain', url + "?auto=compress&h=auto&w=1600");

    }

    return (
        <div className='rightPexels'>
            <AppHeadingCard title="Pexels" changeActiveApp={changeActiveApp} />
            <div className='input-container'>
                <input type="text"
                    value={search}
                    onChange={(e) => {
                        setSearch(e.target.value);
                        getSearchPhotos(false, e.target.value);
                    }}
                    placeholder='Search images' />
            </div>
            <div className='scroll-container-image' style={loading ? { overFlow: 'hidden' } : null} onScroll={scrollStarted}>
                <Masonry columnsCount={2} gutter={10}>
                    {
                        images.map((url, index) => {
                            // console.log(url)
                            return <div key={index} className='image-container'>
                                <img
                                    src={url.src.medium}
                                    alt='muse'
                                    onDragStart={(e) => imageDragStart(e, url.src.original)}
                                />

                            </div>;
                        })
                    }
                </Masonry>

                {
                    loading ? <ImagesLoader /> : null
                }
            </div>
        </div>
    );
};

export default Pexels;