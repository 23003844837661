import React, { useState, useEffect } from 'react';
import "./Slack.css";
import AppHeadingCard from '../app_heading_card/AppHeadingCard';
import axios from 'axios';
import slackLogo from "../../assets/slack-logo.svg";
import sendArrow from "../../assets/sendArrow.svg";
import jwt_decode from 'jwt-decode';
import constant from '../../constant';
import ChatLoader from '../chat_loader/ChatLoader';


const Slack = ({ changeActiveApp }) => {
    const [accessToken, setAccessToken] = useState('');
    // eslint-disable-next-line
    const [teamId, setTeamId] = useState('');
    const [teamName, setTeamName] = useState('');
    const [isUserSignIn, setIsUserSignIn] = useState(false);
    const [channels, setChannels] = useState([]);
    const [selectedChannel, setSelectedChannel] = useState('');
    const [conversationsHistory, setConversationsHistory] = useState([]);
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [slackId, setSlackId] = useState('');
    const [cursor, setCursor] = useState('');
    const [hasMore, setHasMore] = useState(false);
    const [timestamp, setTimestamp] = useState('');
    const [userInfo, setUserInfo] = useState(null);
    const [sendersInfo, setSendersInfo] = useState([]);


    useEffect(() => {
        getUsersAccesTokenAndTeam();
    }, [conversationsHistory.length]);  // eslint-disable-line react-hooks/exhaustive-deps


    // get users access tokens and fetch channels if access token is available
    const getUsersAccesTokenAndTeam = async () => {
        try {
            if (isUserSignIn === false) {
                setLoading(true);
                const jwtToken = localStorage.getItem('token');
                const decoded = jwt_decode(jwtToken);
                const user_id = decoded.id;
                const response = await axios.get(`${constant.url}slack/get/access/token/${user_id}`);
                if (response.data.message === undefined) {
                    setAccessToken(response.data.access_token);
                    setTeamId(response.data.team_id);
                    setSlackId(response.data.slack_id);
                    setTeamName(response.data.team_name);
                    setIsUserSignIn(true);
                    getUsersChannels(response.data.access_token);
                    getUsersInfo(response.data.access_token);
                    setLoading(false);
                } else {
                    setIsUserSignIn(false);
                }
            }
        } catch (error) {
            console.log(error);
        }
    };

    // get users info
    const getUsersInfo = async (access_token) => {
        try {
            let formData = new FormData();
            formData.append('token', access_token);
            const response = await axios.post('https://slack.com/api/users.identity', formData, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            }
            );
            if (response.data !== undefined) {
                setUserInfo(response.data.user);
            }
        } catch (error) {
            console.log(error);
        }
    };

    // get users channels
    const getUsersChannels = async (access_token) => {
        try {
            setLoading(true);
            let formData = new FormData();
            formData.append('token', access_token);
            const response = await axios.post('https://slack.com/api/conversations.list', formData, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            }
            );
            if (response.data.channels.length > 0) {
                setChannels(response.data.channels);
                setSelectedChannel(response.data.channels[0].id);
                getConversationHistory(access_token, response.data.channels[0].id, false);
                setLoading(false);
            }
        } catch (error) {
            console.log(error);
        }
    };


    const sendMessageInSelectedChannel = async () => {
        let formData = new FormData();
        formData.append('token', accessToken);
        formData.append('channel', selectedChannel);
        formData.append('text', message);
        const response = await axios.post('https://slack.com/api/chat.postMessage', formData, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        }
        );

        if (response.data !== undefined) {
            await getConversationHistory(undefined, selectedChannel, false, true);
            setMessage('');
        }
    };

    // handle channels select input and get conversation history of that channel
    const handleChannelSelect = async (e) => {
        setLoading(true);
        setSelectedChannel(e.target.value);
        await getConversationHistory(undefined, e.target.value, false, Date.now());
    };

    // get users conversation history
    const getConversationHistory = async (access_token, channel, loadMore, isMsgSend) => {
        try {
            let formData = new FormData();
            formData.append('token', accessToken === '' ? access_token : accessToken);
            formData.append('channel', channel);
            if (loadMore) {
                formData.append('cursor', cursor !== '' ? cursor : '');
            }
            formData.append('limit', 20);
            if (!isMsgSend) {
                formData.append('latest', timestamp);
            }
            const response = await axios.post('https://slack.com/api/conversations.history', formData, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            }
            );


            if (response.data.messages.length > 0) {
                if (loadMore === false) {
                    setConversationsHistory(response.data.messages);
                    setCursor(response.data.response_metadata === undefined ? '' : response.data.response_metadata.next_cursor);
                    setHasMore(response.data.has_more);
                    setTimestamp(response.data.messages.at(-1).ts);
                    setLoading(false);
                } else {
                    setConversationsHistory([...conversationsHistory, ...response.data.messages]);
                    setHasMore(response.data.has_more);
                    setCursor(response.data.response_metadata === undefined ? '' : response.data.response_metadata.next_cursor);
                    setTimestamp(response.data.messages.at(-1).ts);
                    setLoading(false);
                }
                getSendersInfo(response.data.messages, access_token);
            }
        } catch (error) {
            console.log(error);
        }
    };

    // login the user
    const login = () => {
        const jwtToken = localStorage.getItem('token');
        const decoded = jwt_decode(jwtToken);
        const user_id = decoded.id;
        window.open(`${constant.url}slack/login/${user_id}/cache`, "_self");
    };

    // logout the user
    const logout = async () => {
        try {
            const jwtToken = localStorage.getItem('token');
            const decoded = jwt_decode(jwtToken);
            const user_id = decoded.id;
            const response = await axios.get(`${constant.url}slack/logout/${user_id}`);
            if (response.data.data === "User logout successfully") {
                setIsUserSignIn(false);
                setAccessToken('');
                setTeamId('');
                setTeamName('');
            }
        } catch (error) {
            console.log(error);
        }
    };

    // convert time-stamp to original date
    const convertTimestamp = (timestamp) => {
        var d = new Date(timestamp * 1000), // Convert the passed timestamp to milliseconds
            yyyy = d.getFullYear(),
            mm = ('0' + (d.getMonth() + 1)).slice(-2),  // Months are zero based. Add leading 0.
            dd = ('0' + d.getDate()).slice(-2),         // Add leading 0.
            hh = d.getHours(),
            h = hh,
            min = ('0' + d.getMinutes()).slice(-2),     // Add leading 0.
            ampm = 'AM',
            time;

        if (hh > 12) {
            h = hh - 12;
            ampm = 'PM';
        } else if (hh === 12) {
            h = 12;
            ampm = 'PM';
        } else if (hh === 0) {
            h = 12;
        }

        // ie: 2014-03-24, 3:00 PM
        time = yyyy + '-' + mm + '-' + dd + ', ' + h + ':' + min + ' ' + ampm;
        return time;
    };


    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            if (message !== '') {
                sendMessageInSelectedChannel();
            }
        }
    };

    // get sender user info
    const getSendersInfo = async (history, access_token) => {
        try {
            let sender = [];
            for await (const data of history) {
                let formData = new FormData();
                formData.append('token', access_token === undefined ? accessToken : access_token);
                formData.append('user', data.user);
                const response = await axios.post('https://slack.com/api/users.info', formData, {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                }
                );
                if (response.data.user !== undefined) {
                    sender.push(response.data.user);
                }
            }
            setSendersInfo(sender);
        } catch (error) {
            console.log(error);
        }
    };


    // the user interface
    const slackTeamAndChannel = () => {
        return (
            <div className="slack-container">
                <div className="select-channels">
                    <div className="active-workspace">
                        <span>{teamName} - Workspace</span>
                    </div>
                    <select onChange={handleChannelSelect}>
                        {channels.map((channel, index) => {
                            return (
                                <option value={channel.id} key={index}>{channel.name}</option>
                            );
                        })}
                    </select>
                </div>
                {/* chat container */}
                {loading ? <ChatLoader /> : (
                    <div className="chat-container">
                        {hasMore ? (
                            <div className="load-more-button">
                                <button type="button" onClick={
                                    () => {
                                        getConversationHistory(undefined, selectedChannel, true);
                                    }
                                }>Load More</button>
                            </div>
                        ) : null}
                        {conversationsHistory.map((message, index) => {
                            const sender = sendersInfo.find((data) => data.id === message.user);
                            const timestamp = convertTimestamp(message.ts);
                            return slackId !== message.user ? (
                                <div className='message-container' key={index}>
                                    <div className="user-message"  >
                                        <p>{message.text}</p>
                                    </div>
                                    <div className="sender">
                                        <span>{sender !== undefined ? sender.name : 'him/her'}</span>
                                        <span> - {timestamp}</span>
                                    </div>
                                </div>

                            ) : (
                                <div className='my-message-container' key={index}>
                                    <div className="my-message">
                                        <p>{message.text}</p>
                                    </div>
                                    <div className="sender">
                                        <span>{userInfo !== null ? userInfo.name : 'me'}</span>
                                        <span> - {timestamp}</span>
                                    </div>
                                </div>
                            );

                        }).reverse()}
                    </div>
                )}

                {/* send message input feild */}
                <div className="send-message-container">
                    <img src={sendArrow} alt="send-button" onClick={sendMessageInSelectedChannel} />
                    <textarea type="text" placeholder='Type a message here...' value={message} onChange={(e) => {
                        setMessage(e.target.value);
                    }} onKeyDown={handleKeyDown} />
                </div>
            </div>
        );
    };



    return (
        <div className="rightSlack">
            <AppHeadingCard title="Slack Team" changeActiveApp={changeActiveApp} authenticationRequired={true} login={login} signOut={logout} isUserSignIn={isUserSignIn} slackWorkspaceName={teamName} />
            {isUserSignIn ? slackTeamAndChannel() : (
                <div className="connect-slack-container">
                    <h1 className='heading'>Connect Slack Account</h1>
                    <div className='slack-connect-button' onClick={login}>
                        <img alt="drive" src={slackLogo} />
                        <p>Sign in with Slack</p>
                    </div>
                </div>
            )}
        </div >
    );
};

export default Slack;