import React, { useContext } from 'react'
import "./Dashboard.css"
import opennewwindow from "../../../assets/center_dashboard_assets/opennewwindow.svg"
import TemplateCard from '../../../card_components/template_card/TemplateCard'
import { useNavigate } from "react-router-dom";
import CreateDataContext from "../../../store/CreateDataProvider"
import TemplateLoading from '../../../card_components/template_loading/TemplateLoading';



const Dashboard = () => {
    const context = useContext(CreateDataContext)
    const navigate = useNavigate();

    const loadingArray = [1, 2, 3, 4, 5, 6]

    return (
        <div className='dashboard-container'>

            {/* Quote Card */}
            <div className="quote-container">
                <div className='quote'>
                    <p>I write because I cannot NOT write.</p>
                    <span>- Charlotte Brontë</span>
                    <a href="https://www.quotes.creatosaurus.io/">
                        <img src={opennewwindow} alt="" />
                    </a>
                </div>
                <button onClick={() => {
                    navigate("/editor");
                }}>
                    Create
                </button>
            </div>

            {/* Statistics Card */}
            <div className="statistics-container">
                <div className="saved">
                    <span>{context.drafts.length} Saved</span>
                </div>
                <div className="divider" />
                <div className="drafts">
                    <span>{context.drafts.length} Drafts</span>
                </div>
                <div className="divider" />
                <div className="min-saved">
                    <span>10 min saved</span>
                </div>
            </div>

            {/* Templates Box */}
            <div className="templates-container">
                <div className="header">
                    <span>Templates</span>
                    <span>See all</span>
                </div>

                <div className={context.templateLoading === false && context.templates.length === 0 ? null : 'template-card-grid'}>
                    {context.templateLoading ? loadingArray.map(() => {
                        return <TemplateLoading />
                    }) : context.templates.length > 0 ? context.templates.map((data, index) => {
                        return <TemplateCard key={index} data={data} />
                    }) : <p className='no-account'>No templates avaliable</p>}
                </div>

            </div>
        </div>
    );
}

export default Dashboard;