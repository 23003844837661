import { createContext, useState } from 'react';


const ButtonContext = createContext({
    activeLeftButton: 1,
    activeCenterButton: 'dashboard',
    activeRightButton: 'right-dashboard',
    activeAppStoreApp: "",


    changeLeftButton: () => { },
    changeCenterButton: () => { },
    changeRightButton: () => { },
    activateAppStoreApp: () => { },
});


export const ButtonContextProvider = (props) => {
    const [activeLeftButton, setactiveLeftButton] = useState(1);
    const [activeCenterButton, setactiveCenterButton] = useState("dashboard");
    const [activeRightButton, setactiveRightButton] = useState("right-dashboard");
    const [activeAppStoreApp, setActiveAppStoreApp] = useState("");


    const changeLeftButton = (value) => {
        setactiveLeftButton(value);
    };

    const changeCenterButton = (value) => {
        setactiveCenterButton(value);
    };

    const changeRightButton = (value) => {
        setactiveRightButton(value);
    };


    const activateAppStoreApp = (value) => {
        setActiveAppStoreApp(value);
    };

    const context = {
        activeLeftButton: activeLeftButton,
        activeCenterButton: activeCenterButton,
        activeRightButton: activeRightButton,
        activeAppStoreApp: activeAppStoreApp,


        changeLeftButton: changeLeftButton,
        changeCenterButton: changeCenterButton,
        changeRightButton: changeRightButton,
        activateAppStoreApp: activateAppStoreApp,

    };

    return (
        <ButtonContext.Provider value={context}>{props.children}</ButtonContext.Provider>
    );
};

export default ButtonContext;