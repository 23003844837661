import React, { useRef, useState, useContext } from "react";
import './CoverImage.css'
import axios from 'axios';
import CreateDataContext from '../../../store/CreateDataProvider';
import constant from '../../../constant';



const CoverImage = () => {
    const context = useContext(CreateDataContext)
    const [progress, setProgress] = useState("");
    const [loading, setLoading] = useState(false);
    const fileInputRef = useRef();

    const uploadImageToS3 = async (fileInputRef) => {
        try {
            setLoading(true)
            const file = fileInputRef.target.files[0]
            const token = localStorage.getItem('token');
            const config = { headers: { Authorization: `Bearer ${token}` } };
            const response = await axios.get(`${constant.stenoURL}/image/generate/url`, config);
            const url = await response.data.uploadURL;

            await axios.request({
                method: "PUT",
                headers: {
                    "Content-Type": file.type
                },
                url: url,
                data: file,
                onUploadProgress: (progressEvent) => {
                    const { loaded, total } = progressEvent;
                    const percentage = Math.floor((loaded * 100) / total);
                    if (percentage < 100) {
                        setProgress(percentage);
                    }
                },
            })

            const imageURL = response.data.uploadURL.split('?')[0]
            context.handlePostCoverImage(imageURL)
            if (context.savedPost !== null) {
                if (context.savedPost.metaData.coverImage !== imageURL) {
                    context.handleSavedDataChanged(true)
                }
            }
            setLoading(false)
        } catch (error) {
            console.log(error)
            setLoading(false)
        }
    }

    return (
        <div className="cover-image-container">
            <input type="file"
                onChange={(e) => {
                    uploadImageToS3(e)
                }}
                ref={fileInputRef}
                id="inputRef"
                hidden
            />
            {!context.postCoverImage && (<label htmlFor='inputRef'>
                <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M11.6663 7.58398V11.084C11.6663 11.7283 11.144 12.2507 10.4997 12.2507H3.49967C2.85534 12.2507 2.33301 11.7283 2.33301 11.084V7.58398"
                        stroke="#808080"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                    <path
                        d="M6.99967 1.75L9.04134 3.79167M6.99967 8.75V1.75V8.75ZM6.99967 1.75L4.95801 3.79167L6.99967 1.75Z"
                        stroke="#808080"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                </svg>
                <span>Select cover image{loading ? `...${progress}` : null}</span>
            </label>)}

            {context.postCoverImage && (<div className="cover-image">
                <img src={context.postCoverImage} alt="cover" />
                <label className="choose-another" htmlFor='inputRef'>
                    <svg
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M11.6663 7.58398V11.084C11.6663 11.7283 11.144 12.2507 10.4997 12.2507H3.49967C2.85534 12.2507 2.33301 11.7283 2.33301 11.084V7.58398"
                            stroke="#808080"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            d="M6.99967 1.75L9.04134 3.79167M6.99967 8.75V1.75V8.75ZM6.99967 1.75L4.95801 3.79167L6.99967 1.75Z"
                            stroke="#808080"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                    <span>Choose Another{loading ? `...${progress}` : null}</span>
                </label>
                <div
                    className="delete-cover-image"
                    onClick={() => {
                        context.handlePostCoverImage('')
                    }}
                >
                    <svg
                        width="24"
                        height="24"
                        stroke-width="1.5"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M6.75827 17.2426L12.0009 12M17.2435 6.75736L12.0009 12M12.0009 12L6.75827 6.75736M12.0009 12L17.2435 17.2426"
                            stroke="currentColor"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                </div>
            </div>)}
        </div>
    );
}

export default CoverImage;
