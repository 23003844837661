import React from 'react';
import './HashtagsLoader.css';

const HashtagsLoader = () => {
    const loading = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    return (
        <div className='hashtag-loader-container'>
            {
                loading.map(data => {
                    return <div className='grid' key={data}>
                        <div className='skeleton box' />
                        <div className='skeleton box' />
                    </div>;
                })
            }
        </div>
    );
};

export default HashtagsLoader;