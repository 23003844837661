import React from 'react';
import './Help.css';

const Help = () => {
    return (
        <div className='center-help'>
            <button onClick={() => window.open('https://tawk.to/chat/615fde58157d100a41ab5e5d/1fhf7p2ht', '_blank')}>
                <span>Live Chat Support</span>
                <span>{'>'}</span>
            </button>

            <button onClick={() => window.open('https://wa.me/919665047289', '_blank')}>
                <span>WhatsApp Support +91 9665047289</span>
                <span>{'>'}</span>
            </button>

            <button onClick={() => window.open('https://www.facebook.com/groups/creatosaurus/', '_blank')}>
                <span>Join Our Facebook Group</span>
                <span>{'>'}</span>
            </button>

            <button onClick={() => window.open('https://youtu.be/eS5tpAUEuzA')}>
                <span>Video Tutorial</span>
                <span>{'>'}</span>
            </button>

            <button onClick={() => window.open('https://creatosaurus.canny.io/feature-requests')}>
                <span>Request Feature</span>
                <span>{'>'}</span>
            </button>

            <button onClick={() => window.open('https://creatosaurus.canny.io/bug-board')}>
                <span>Product Feedback</span>
                <span>{'>'}</span>
            </button>

            <button onClick={() => window.open('https://www.creatosaurus.io/pricing', '_blank')}>
                <span>Pricing & Payments</span>
                <span>{'>'}</span>
            </button>

            <button onClick={() => window.open('https://www.creatosaurus.io/contact', '_blank')}>
                <span>Email Contact</span>
                <span>{'>'}</span>
            </button>

            <button onClick={() => window.open('https://calendly.com/malavwarke/creatosaurus', '_blank')}>
                <span>Book Demo</span>
                <span>{'>'}</span>
            </button>
        </div>
    );
};

export default Help;