import React, { useState } from 'react';
import apps from '../../appNames';
import './AllApps.css';
import cancelLogo from "../../assets/cross.svg";
import AppDetail from "../app_detail/AppDetail";
import AppHeadingCard from '../app_heading_card/AppHeadingCard';

const AllApps = ({ changeActiveApp }) => {
    const [searchApps, setsearchApps] = useState(apps);
    const [search, setsearch] = useState("");
    const [moreOptionActiveApp, setMoreOptionsActiveApp] = useState(null);
    const [showAppDetail, setShowAppDetail] = useState(false);
    const [appInfo, setAppInfo] = useState(null);

    const filterApps = (e) => {
        let filteredApps = apps.filter((app) => app.title.toLowerCase().includes(e.target.value.trim().toLowerCase()));
        setsearch(e.target.value);
        setsearchApps(filteredApps);
    };

    const handleMoreOption = (app, index, event) => {
        event.preventDefault();
        event.stopPropagation();
        setMoreOptionsActiveApp(index);
    };

    const toggleAppDetailsState = () => {
        setShowAppDetail(!showAppDetail);
        setMoreOptionsActiveApp(null);
    };

    const closePopup = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setMoreOptionsActiveApp(null);
    };

    const openApp = (e, title) => {
        e.preventDefault();
        e.stopPropagation();
        changeActiveApp(title);
    };

    const showAppInfo = (e, data) => {
        e.preventDefault();
        e.stopPropagation();
        setShowAppDetail(true);
        setAppInfo(data);
    };


    return (
        showAppDetail ? <AppDetail goBack={() => toggleAppDetailsState()} data={appInfo} /> : <div className='all-app-container'>
            <AppHeadingCard title="Creatosaurus App Store" changeActiveApp={changeActiveApp} appU />
            <div className='input-container'>
                <input type="text"
                    value={search}
                    onChange={filterApps}
                    placeholder='Search Creatosaurus App Store' />
            </div>
            <div style={{ height: 10 }} />
            <div className='app-scroll'>
                {
                    searchApps.map((data, index) => {
                        return (
                            <div className='card' key={index} onClick={() => changeActiveApp(data.title)}>
                                <div className='more-options' onClick={(e) => {
                                    handleMoreOption(data.title, index, e);
                                }}>
                                    <svg width="12" height="4" viewBox="0 0 12 4" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1.91634 1.99935H1.92217H1.91634ZM5.99967 1.99935H6.00551H5.99967ZM10.083 1.99935H10.0888H10.083ZM2.49967 1.99935C2.49967 2.15406 2.43822 2.30243 2.32882 2.41183C2.21942 2.52122 2.07105 2.58268 1.91634 2.58268C1.76163 2.58268 1.61326 2.52122 1.50386 2.41183C1.39447 2.30243 1.33301 2.15406 1.33301 1.99935C1.33301 1.84464 1.39447 1.69627 1.50386 1.58687C1.61326 1.47747 1.76163 1.41602 1.91634 1.41602C2.07105 1.41602 2.21942 1.47747 2.32882 1.58687C2.43822 1.69627 2.49967 1.84464 2.49967 1.99935V1.99935ZM6.58301 1.99935C6.58301 2.15406 6.52155 2.30243 6.41215 2.41183C6.30276 2.52122 6.15438 2.58268 5.99967 2.58268C5.84496 2.58268 5.69659 2.52122 5.5872 2.41183C5.4778 2.30243 5.41634 2.15406 5.41634 1.99935C5.41634 1.84464 5.4778 1.69627 5.5872 1.58687C5.69659 1.47747 5.84496 1.41602 5.99967 1.41602C6.15438 1.41602 6.30276 1.47747 6.41215 1.58687C6.52155 1.69627 6.58301 1.84464 6.58301 1.99935V1.99935ZM10.6663 1.99935C10.6663 2.15406 10.6049 2.30243 10.4955 2.41183C10.3861 2.52122 10.2377 2.58268 10.083 2.58268C9.9283 2.58268 9.77992 2.52122 9.67053 2.41183C9.56113 2.30243 9.49967 2.15406 9.49967 1.99935C9.49967 1.84464 9.56113 1.69627 9.67053 1.58687C9.77992 1.47747 9.9283 1.41602 10.083 1.41602C10.2377 1.41602 10.3861 1.47747 10.4955 1.58687C10.6049 1.69627 10.6663 1.84464 10.6663 1.99935V1.99935Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </div>
                                {
                                    moreOptionActiveApp === index ? <div className='more-sub-card' style={index % 2 !== 0 ? { left: '-50px' } : { left: '0px' }} >
                                        <div className='head' >
                                            <span onClick={(e) => {
                                                e.stopPropagation();
                                            }}>{data.title}</span>
                                            <img src={cancelLogo} alt="cancellogo" onClick={closePopup} />
                                        </div>
                                        <button onClick={(e) => openApp(e, data.title)}>Open</button>
                                        <button onClick={(e) => showAppInfo(e, data)}>App details</button>
                                    </div> : null
                                }
                                <div className='image-container' style={{ backgroundColor: data.color }}>
                                    <img src={data.logo} alt="" />
                                </div>
                                <span>{data.title}</span>
                                <p>{data.desc}</p>
                            </div>
                        );
                    })
                }
            </div>
        </div>
    );
};

export default AllApps;