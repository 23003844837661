import React, { useRef, useContext, useEffect, useLayoutEffect } from 'react';
import './Description.css';
import CreateDataContext from '../../../store/CreateDataProvider';

const Description = () => {
    const context = useContext(CreateDataContext);
    const dataRef = useRef();

    const setCaretToEnd = () => {
        const { current: element } = dataRef;
        const range = document.createRange();
        const selection = window.getSelection();
        range.selectNodeContents(element);
        range.collapse(false);
        selection.removeAllRanges();
        selection.addRange(range);
        element.focus();
    };

    const inputHandler = () => {
        context.handlePostDescription(dataRef.current.innerHTML);
        if (context.savedPost !== null) {
            if (context.savedPost.metaData.description !== dataRef.current.innerHTML) {
                context.handleSavedDataChanged(true);
            }
        }
    };

    useLayoutEffect(() => {
        dataRef.current.innerHTML = context.postDescription;
        setCaretToEnd();
    }, [context.postDescription]);

    return (
        <div className="description-container">
            <p
                ref={dataRef}
                style={{ marginTop: "5px" }}
                onInput={inputHandler}
                onDrop={(e) => e.preventDefault()}
                contentEditable="true"
                suppressContentEditableWarning
                className="head"
                placeholder="New post description here..."
            ></p>
        </div>
    );
};

export default Description;
