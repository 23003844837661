import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css'
import { ButtonContextProvider } from './store/ButtonProvider';
import { CreateDataProvider } from './store/CreateDataProvider';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.Fragment>
    <React.StrictMode>
      <ButtonContextProvider>
        <CreateDataProvider>
          <App />
          <ToastContainer />
        </CreateDataProvider>
      </ButtonContextProvider >
    </React.StrictMode>
  </React.Fragment>
);


