import React, { useState, useEffect } from 'react'
import './Captionator.css'
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import { toast } from 'react-toastify'
import captionTypes from '../../captionTypesCaptionator';
import AppHeadingCard from '../app_heading_card/AppHeadingCard';

const Captionator = ({ changeActiveApp }) => {
    const [activeButton, setactiveButton] = useState(true)
    const [loading, setLoading] = useState(true);
    const [captions, setcaptions] = useState([])

    const [captionType, setcaptionType] = useState("google/ad/description")
    const [productName, setproductName] = useState("")
    const [productDescription, setproductDescription] = useState("")

    const [captionLoading, setcaptionLoading] = useState(false)
    const [captionsGenerated, setcaptionsGenerated] = useState([])
    const [favourite, setfavourite] = useState([])
    const [gender, setgender] = useState("male")
    const [loadingText, setloadingText] = useState(null)

    const [filter, setfilter] = useState("all")


    useEffect(() => {
        getTheCaptions()
    }, [])

    const getTheCaptions = async () => {
        try {
            setLoading(true);
            const workspaceId = localStorage.getItem('organizationId');
            const token = localStorage.getItem('token');
            const config = {
                headers: { Authorization: `Bearer ${token}` },
            };

            const response = await axios.get('https://xm6vavamsh.execute-api.ap-south-1.amazonaws.com/latest/captionator/saved/' + workspaceId, config);

            setcaptions(response.data);
            setLoading(false)
        } catch (error) {
            setLoading(false);
        }
    }

    const getTheAppropriateTitleForDescription = () => {
        const filter = captionTypes.filter(data => data.url === captionType)
        return filter[0]
    }

    const generateTheCaptions = async () => {
        try {
            setcaptionLoading(true)
            setcaptionsGenerated([])
            setfavourite([])

            // get the information of copy type
            let data = captionTypes.filter(data => data.url === captionType)

            const token = localStorage.getItem('token')
            const organizationId = localStorage.getItem('organizationId')
            const decoded = jwt_decode(token);

            const config = { headers: { "Authorization": `Bearer ${token}` } }
            let url = "https://xm6vavamsh.execute-api.ap-south-1.amazonaws.com/latest/captionator/" + captionType

            const response = await axios.post(url, {
                organizationId: organizationId,
                userId: decoded.id,
                userName: decoded.userName,
                companyName: data[0].textInputTitle === false ? null : productName,
                companyDescription: productDescription,
                gender: gender,
                copyType: data[0].title,
                language: "english",
                message: productDescription
            }, config)

            setcaptionLoading(false)
            if (captionType === "chat") {
                setcaptionsGenerated([{
                    text: response.data.data
                }])
            } else {
                setcaptionsGenerated(response.data.data)
            }
        } catch (error) {
            setcaptionLoading(false)
            if (error.response.status === 429) return toast("Credit's over please upgrade to generate more copies")
        }
    }

    const generateCaption = () => {
        const textAreaTitle = getTheAppropriateTitleForDescription().textAreaTitle
        const textInputTitle = getTheAppropriateTitleForDescription().textInputTitle

        if (textInputTitle === false) {
            if (productDescription.trim() === "") return toast(`Hay please add ${textAreaTitle}`, {
                autoClose: 2000,
                hideProgressBar: false,
            });
        } else {
            if (productDescription.trim() === "") return toast(`Hay please add ${textInputTitle}`, {
                autoClose: 2000,
                hideProgressBar: false,
            })
            if (productDescription.trim() === "") return toast(`Hay please add ${textAreaTitle}`, {
                autoClose: 2000,
                hideProgressBar: false,
            });
        }

        generateTheCaptions()
    }

    const toggleButtonState = (value) => {
        setactiveButton(value)
    }

    const copyText = (data) => {
        navigator.clipboard.writeText(data);
        toast('Copied!');
    }

    const addCaptionToFavorite = async (text) => {
        try {
            if (favourite.includes(text)) return toast("Already favorite", {
                autoClose: 1000,
                hideProgressBar: false,
            });

            let copyType = captionTypes.filter(data => data.url === captionType)
            const token = localStorage.getItem('token')
            const organizationId = localStorage.getItem('organizationId')
            const decoded = jwt_decode(token);
            const config = { headers: { "Authorization": `Bearer ${token}` } }

            setloadingText(text)
            await axios.post("https://xm6vavamsh.execute-api.ap-south-1.amazonaws.com/latest/captionator/saved", {
                organizationId: organizationId,
                userId: decoded.id,
                userName: decoded.userName,
                queryId: undefined,
                copyType: copyType[0].title,
                language: "english",
                answer: text,
            }, config)

            setloadingText(null)
            setfavourite((prev) => [...prev, text])
            getTheCaptions()
        } catch (error) {
            setloadingText(null)
        }
    }

    return (
        <div className='captionator'>
            <AppHeadingCard title="Captionator" changeActiveApp={changeActiveApp} />

            <div className='button-container'>
                <button
                    onClick={() => toggleButtonState(true)}
                    className={activeButton ? 'active' : null}>Generate</button>
                <button
                    onClick={() => toggleButtonState(false)}
                    className={activeButton ? null : 'active'}>Saved</button>
            </div>

            <React.Fragment>
                {
                    activeButton === false ? <React.Fragment>
                        <label>Filter</label>
                        <select
                            value={filter}
                            id="copy-type"
                            name="copy-type"
                            onChange={(e) => setfilter(e.target.value)}>
                            <option value="all">All</option>
                            {
                                captionTypes.map(data => {
                                    return <option key={data.url} value={data.title}>{data.title}</option>
                                })
                            }
                        </select>
                    </React.Fragment> : null
                }
            </React.Fragment>

            <div className='main-container'>
                {
                    activeButton === true ? <div className='generate-container'>
                        <label>Copy Type</label>
                        <select
                            value={captionType}
                            id="copy-type"
                            name="copy-type"
                            onChange={(e) => setcaptionType(e.target.value)}>
                            {
                                captionTypes.map(data => {
                                    return <option key={data.url} value={data.url}>{data.title}</option>
                                })
                            }
                        </select>

                        {
                            captionType === "third/person" ?
                                <React.Fragment>
                                    <label>Gender</label>
                                    <select
                                        value={gender}
                                        id="copy-type"
                                        name="copy-type"
                                        onChange={(e) => setgender(e.target.value)}>
                                        <option value="male">Male</option>
                                        <option value="female">Female</option>
                                    </select>
                                </React.Fragment> : null
                        }

                        <label style={getTheAppropriateTitleForDescription().textInputTitle === false ? { display: 'none' } : null}>{getTheAppropriateTitleForDescription().textInputTitle}</label>
                        <input
                            style={getTheAppropriateTitleForDescription().textInputTitle === false ? { display: 'none' } : null}
                            value={productName}
                            onChange={(e) => setproductName(e.target.value)}
                            placeholder="eg. Creatosaurus" />

                        <div className="row">
                            <span>{getTheAppropriateTitleForDescription().textAreaTitle}</span>
                            <span>{productDescription.length}/400</span>
                        </div>

                        <textarea
                            value={productDescription}
                            maxLength="400"
                            placeholder="eg. It helps creators and startups with its sass products"
                            onChange={(e) => setproductDescription(e.target.value)} />

                        <button onClick={captionLoading ? null : generateCaption}>
                            {captionLoading ? "Generating . . ." : "Generate"}
                        </button>

                        {
                            captionsGenerated.map((data, index) => {
                                return <div className='card' key={index}>
                                    {
                                        data.text.split("\n").map((data, index) => {
                                            if (data.trim() === "") return null
                                            return <div key={index}>{data.trim()}</div>
                                        })
                                    }

                                    <div className='copy-container'>
                                        <div className='wrapper' onClick={() => copyText(data.text.trim())}>
                                            <span>Copy</span>
                                            <svg className='copy' xmlns="http://www.w3.org/2000/svg" width="24" height="24" strokeWidth="1.5" viewBox="0 0 24 24" fill="none">
                                                <path d="M19.4 20H9.6C9.26863 20 9 19.7314 9 19.4V9.6C9 9.26863 9.26863 9 9.6 9H19.4C19.7314 9 20 9.26863 20 9.6V19.4C20 19.7314 19.7314 20 19.4 20Z" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M15 9V4.6C15 4.26863 14.7314 4 14.4 4H4.6C4.26863 4 4 4.26863 4 4.6V14.4C4 14.7314 4.26863 15 4.6 15H9" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                        </div>

                                        <div className='wrapper' onClick={() => addCaptionToFavorite(data.text.trim())}>
                                            {
                                                loadingText === data.text.trim() ? <div className='loader' /> : <React.Fragment>
                                                    <span>{favourite.includes(data.text.trim()) ? "Saved" : 'Save'}</span>
                                                    <svg className='saved' xmlns="http://www.w3.org/2000/svg" width="24" height="24" strokeWidth="1.5" viewBox="0 0 24 24" fill={favourite.includes(data.text.trim()) ? "red" : 'none'}>
                                                        <path d="M22 8.86222C22 10.4087 21.4062 11.8941 20.3458 12.9929C17.9049 15.523 15.5374 18.1613 13.0053 20.5997C12.4249 21.1505 11.5042 21.1304 10.9488 20.5547L3.65376 12.9929C1.44875 10.7072 1.44875 7.01723 3.65376 4.73157C5.88044 2.42345 9.50794 2.42345 11.7346 4.73157L11.9998 5.00642L12.2648 4.73173C13.3324 3.6245 14.7864 3 16.3053 3C17.8242 3 19.2781 3.62444 20.3458 4.73157C21.4063 5.83045 22 7.31577 22 8.86222Z" stroke={favourite.includes(data.text.trim()) ? "red" : "#000"} strokeLinejoin="round" />
                                                    </svg>
                                                </React.Fragment>
                                            }
                                        </div>
                                    </div>
                                </div>
                            })
                        }

                    </div> :
                        <div className='saved-container'>
                            {
                                captions.map(data => {
                                    return filter === "all" ? <p key={data._id} onClick={() => copyText(data.answer)}>{data.answer}</p> :
                                        filter === data.copyType ? <p key={data._id} onClick={() => copyText(data.answer)}>{data.answer}</p> : null
                                })
                            }

                            {loading ? <span className='loading'>Loading your captions . . .</span> : null}
                        </div>
                }
            </div>
        </div>
    )
}

export default Captionator