import React, { useState, useEffect } from 'react';
import './Noice.css';
import Axios from 'axios';
import AppHeadingCard from '../app_heading_card/AppHeadingCard';
import constant from '../../constant';
import Masonry from "react-responsive-masonry";
import NoiceLoader from '../noice_loader/NoiceLoader';

const Noice = ({ changeActiveApp }) => {
    const [images, setImages] = useState([]);
    const [loading, setLoading] = useState(true);
    const [pageNum, setPageNum] = useState(1);
    const [search, setSearch] = useState("");
    const [dataFinished, setDataFinished] = useState(false);



    // load initial images
    useEffect(() => {
        getTheImages();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    // search images function
    const getSearchPhotos = async (loadMore, searchData) => {
        try {
            const token = localStorage.getItem('token');
            const config = { headers: { "Authorization": `Bearer ${token}` } }
            if (!loadMore) {
                setImages([]);
                setPageNum(1);
                setDataFinished(false);
            }

            if (searchData.trim() === "") {
                setImages([]);
                setPageNum(1);
                setDataFinished(false);
                return getTheImages(true);
            }

            setLoading(true);

            if (constant.searchToken) {
                constant.searchToken.cancel(); // Cancel the previous request before making a new request
            }

            // Create a new CancelToken
            constant.searchToken = Axios.CancelToken.source();
            let query = loadMore === false ? searchData : search;

            if (query === "") {
                setPageNum(1);
                return getTheImages(true);
            }

            const response = await Axios.get(
                `https://api.noice.creatosaurus.io/api/v1/icon/search?query=${query}&page=${pageNum}&per_page=20`, config,
                {
                    cancelToken: constant.searchToken.token
                }
            );

            // if results is less then 20 setting data finished to true to stop the execution of function 
            if (response.data.length < 20) {
                setDataFinished(true);
            }

            if (loadMore === false) {
                setImages(response.data);
                setLoading(false);
            } else {
                setImages([...images, ...response.data]);
                if (!dataFinished) {
                    setPageNum((prev) => prev + 1);
                }
                setLoading(false);
            }

        } catch (error) {
            setLoading(false);
        }
    };



    // get initial images and load more when scroll
    const getTheImages = async (calledFromSearch) => {
        try {
            const token = localStorage.getItem('token');
            const config = { headers: { "Authorization": `Bearer ${token}` } }
            setLoading(true);
            let page = calledFromSearch ? 1 : pageNum;

            const response = await Axios.get(
                `https://api.noice.creatosaurus.io/api/v1/icons?page=${page}&per_page=20`, config
            );

            if (calledFromSearch) {
                setImages(response.data.icons);
                setLoading(false);
            } else {
                setImages([...images, ...response.data.icons]);
                setLoading(false);
                setPageNum((prev) => prev + 1);
            }
        } catch (error) {
            setLoading(false);
            console.log(error)
        }
    };


    const scrollStarted = (e) => {
        if (e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight - 50) {
            if (loading || dataFinished) return;
            if (search !== "") {
                getSearchPhotos(true);
            } else {
                getTheImages();
            }
        }
    };

    return (
        <div className='rightNoice'>
            <AppHeadingCard title="Noice" changeActiveApp={changeActiveApp} />
            <div className='input-container'>
                <input type="text"
                    value={search}
                    onChange={(e) => {
                        setSearch(e.target.value);
                        getSearchPhotos(false, e.target.value);
                    }}
                    placeholder='Search images' />
            </div>
            <div className='scroll-container-image' style={loading ? { overFlow: 'hidden' } : null} onScroll={scrollStarted}>
                <Masonry columnsCount={3} gutter={10}>
                    {
                        images.map((url, index) => {
                            return <div key={index} className='image-container'>
                                <img
                                    src={url.URL + '?date=' + new Date()}
                                    alt='muse'
                                />
                            </div>;
                        })
                    }
                </Masonry>

                {
                    loading ? <NoiceLoader /> : null
                }
            </div>
        </div>
    );
};

export default Noice;