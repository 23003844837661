import React, { useState, useEffect } from 'react';
import './Muse.css'
import Axios from 'axios';
import AppHeadingCard from '../app_heading_card/AppHeadingCard';
import ImagesLoader from '../images_loader/ImagesLoader';
import Masonry from "react-responsive-masonry";

const Muse = ({ changeActiveApp }) => {
    const [templates, setTemplates] = useState([]);
    const [loading, setLoading] = useState(true);
    const [multipleTemplates, setMultipleTemplates] = useState(null)
    //const [allLoading, setAllLoading] = useState(false)

    useEffect(() => {
        getTheUserTemplates();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const getTheUserTemplates = async () => {
        try {
            const workspaceId = localStorage.getItem('organizationId');
            const token = localStorage.getItem('token');
            const config = {
                headers: { Authorization: `Bearer ${token}` },
            };

            const res = await Axios.get(
                'https://vlz6y4dxq7.execute-api.ap-south-1.amazonaws.com/latest/muse/templates/' +
                workspaceId,
                config
            );

            const filterDataToNormal = res.data.map((data) => {
                data.template = JSON.parse(data.template);
                return data;
            });


            setTemplates(filterDataToNormal);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };


    const close = () => {
        setMultipleTemplates(null)
    }

    return (
        <div className='rightMuse'>
            <AppHeadingCard title="Muse" changeActiveApp={multipleTemplates === null ? changeActiveApp : close} />
            <div className='scroll-container-image'>
                {loading ? <ImagesLoader /> :
                    <div>
                        {
                            multipleTemplates !== null ? null : templates.length === 0 ? <span className="no-data-message">There are no saved designs in Muse. Please <a href="http://muse.creatosaurus.io/">click here</a>  to create a new design</span> :
                                <Masonry columnsCount={2} gutter={10}>
                                    {
                                        templates.map((data, index) => {
                                            return <div className='image-container' key={index}>
                                                <img
                                                    src={data.template[0].image}
                                                    alt='muse'
                                                    onClick={() => {
                                                        if (data.template.length > 1) {
                                                            setMultipleTemplates(data)
                                                        }
                                                    }}
                                                />
                                                {data.template.length > 1 ? <span>1 of {data.template.length}</span> : null}
                                            </div>
                                        })
                                    }
                                </Masonry>
                        }


                        {
                            <>
                                <Masonry columnsCount={2} gutter={10}>
                                    {
                                        multipleTemplates?.template?.map((data, index) => {
                                            return <div className='image-container' key={index + "small"} >
                                                <img src={data.image} alt='muse' />
                                            </div>
                                        })
                                    }
                                </Masonry>

                                {
                                    /*multipleTemplates !== null ? <button className='add-all'>Add All</button> : null*/
                                }
                            </>
                        }
                    </div>
                }
            </div>
        </div>
    )
}

export default Muse