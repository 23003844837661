const constant = {
    url: 'https://api.cache.creatosaurus.io/cache/',
    stenoURL: "https://m1f8u47vyf.execute-api.ap-south-1.amazonaws.com/latest/steno",
    mainWebURL: 'https://api.app.creatosaurus.io/creatosaurus/',
    captionatorUrl: 'https://api.captionator.creatosaurus.io/captionator',
    // stenoURL: "http://localhost:4000/steno",
    savedPost: null,
    allKeywords: [],
};

export default constant;