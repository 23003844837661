import React, { useContext } from 'react'
import './Saved.css'
import CreateDataContext from '../../../store/CreateDataProvider'
import { useNavigate } from 'react-router-dom'




const Saved = () => {
    const context = useContext(CreateDataContext)
    const navigate = useNavigate();

    return (
        <div className={context.draftLoading === false && context.drafts.length === 0 ? null : 'center-saved-container'}>
            {context.drafts.length === 0 ? <div className="not-saved">
                <span>You haven't saved anything.</span>
            </div> : null}
            <div className="center-draft-board">
                {context.drafts.length === 0 ? null : context.drafts.map((data, index) => {
                    return <div className="draft draft-element" key={index}>
                        <h3>{data.metaData.title}</h3>
                        <div className="bottom first">
                            <p>Created by: {data.info.createdBy}</p>
                        </div>
                        <div className="bottom first">
                            <p>Last updated: {data.info.lastEditedOn}</p>
                        </div>
                        <div className="bottom">
                            <span className="edit" onClick={() => {
                                context.handleSavedPost(data)
                                context.handlePostTitle(data.metaData.title)
                                context.handlePostDescription(data.metaData.description)
                                context.handlePostCoverImage(data.metaData.coverImage)
                                navigate('/editor')
                            }}>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    strokeWidth="1.5"
                                    viewBox="0 0 24 24"
                                    fill="none">
                                    <path
                                        d="M13.0207 5.82839L15.8491 2.99996L20.7988 7.94971L17.9704 10.7781M13.0207 5.82839L3.41405 15.435C3.22652 15.6225 3.12116 15.8769 3.12116 16.1421V20.6776H7.65669C7.92191 20.6776 8.17626 20.5723 8.3638 20.3847L17.9704 10.7781M13.0207 5.82839L17.9704 10.7781"
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    ></path>
                                </svg>
                            </span>
                            <span className="remove" onClick={() => {
                                context.deleteDraft(data._id)
                            }} >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    stroke-width="1.5"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                >
                                    <path
                                        d="M19 11V20.4C19 20.7314 18.7314 21 18.4 21H5.6C5.26863 21 5 20.7314 5 20.4V11"
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    ></path>
                                    <path
                                        d="M10 17V11"
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    ></path>
                                    <path
                                        d="M14 17V11"
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    ></path>
                                    <path
                                        d="M21 7L16 7M3 7L8 7M8 7V3.6C8 3.26863 8.26863 3 8.6 3L15.4 3C15.7314 3 16 3.26863 16 3.6V7M8 7L16 7"
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    ></path>
                                </svg>
                            </span>
                        </div>
                    </div>
                })}
            </div>
        </div>
    );
}

export default Saved;