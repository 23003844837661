import React, { useState } from 'react';
import './Clearbit.css';
import axios from 'axios';
import AppHeadingCard from '../app_heading_card/AppHeadingCard';
import { toast } from "react-toastify";
import fileDownload from 'js-file-download';

const Clearbit = ({ changeActiveApp }) => {
    const [domainName, setDomainName] = useState('');
    const [imageUrl, setimageUrl] = useState('');
    const [loading, setLoading] = useState(false);

    const getIcomFromClearBit = async () => {
        try {
            if (domainName.includes(".")) {
                setLoading(true);
                let domain;
                const domainWithoutwww = domainName.split('https://')[1];
                const domainWithwww = domainName.split('https://www.')[1];
                if (domainWithoutwww !== undefined) {
                    domain = domainWithoutwww;
                } else if (domainWithwww !== undefined) {
                    domain = domainWithwww;
                } else {
                    domain = domainName;
                }

                const res = await axios.get(`https://logo.clearbit.com/${domain}?size=500&format=jpg`);
                setTimeout(() => {
                    setLoading(false);
                    setimageUrl(res.request.responseURL);
                }, 2000);
            } else {
                setLoading(false);
                toast.error("Not a valid domain");
            }
        } catch (error) {
            setLoading(false);
            toast.error("Failed to get logo");
        }
    };

    const downloadFile = () => {
        axios.get(imageUrl, {
            responseType: 'blob',
        })
            .then((res) => {
                fileDownload(res.data, `${domainName.split('.').join("")}.jpg`);
            });
    };


    return (
        <div className="clear-bit-container">
            <AppHeadingCard title="Clearbit" changeActiveApp={changeActiveApp} />
            <div className="clear-bit-app-container">
                <label>Search compony logo</label>
                <input placeholder="Enter a company domain name" onChange={(e) => setDomainName(e.target.value)} />
                <button style={domainName !== "" ? { backgroundColor: '#0078ff' } : { backgroundColor: '#c4c4c4' }}
                    className={loading ? "button--loading" : ""}
                    onClick={() => getIcomFromClearBit()}>
                    <span className="button__text">
                        Get Logo
                    </span></button>
                {
                    imageUrl !== '' ?
                        <div className='company-logo-container' >
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => {
                                downloadFile();
                            }}>
                                <path d="M5 16.6665H15" stroke="#404040" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M9.99967 13.3335L7.08301 10.4168M9.99967 3.3335V13.3335V3.3335ZM9.99967 13.3335L12.9163 10.4168L9.99967 13.3335Z" stroke="#404040" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                            {imageUrl !== '' ? <img src={imageUrl} alt="domain" /> : <span></span>}
                        </div> : null
                }

            </div>
        </div >
    );
};

export default Clearbit;;;;;;
